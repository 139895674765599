import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import PlaceIcon from '@mui/icons-material/Place';
import { Box, Chip, Divider, Stack, Typography, colors, Link } from '@mui/material'
import { display } from '@mui/system';
import React from 'react'
import { useSelector } from 'react-redux';
import { OFFERS } from '../../router';
import { diffForHumanDate } from '../../utilities';

const JobberOffreItem = ({ service, amount, address, expired, id }) => {
  const { isLogged, user } = useSelector(state => state.auth)

  return (
    <Box sx={{ width: '100%', my: 2 }} >
      <Stack direction={{ xs: 'column', sm: 'row' }} mb={2} justifyContent={{ sm: 'space-between', md: 'space-between' }} >
        <Typography sx={{ fontSize: 16, fontWeight: '600', color: colors.common.black }} >
          {service}
        </Typography>
        <Typography sx={{ fontSize: 17, fontWeight: '600', color: '#00000080' }} >
          {amount} XAF
          {isLogged && user?.roles.includes("ROLE_JOBBER") && <Link href={`${OFFERS}/views/${id}`} underline="hover">
            {'Voir details'}
          </Link>}
        </Typography>
      </Stack>
      <Stack mb={2} direction={'row'} spacing={1}>
        <Chip icon={<AlarmOnIcon />} label={`Exp: ${diffForHumanDate(expired)}`} />
        <Chip icon={<PlaceIcon />} label={address} />
      </Stack>
      <Divider />
    </Box>
  )
}

export default JobberOffreItem