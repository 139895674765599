import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { handleDate } from "../../../app/features/authSlice";
import { handleExpiredDateOffer } from "../../../app/features/offerSlice";
import "./styles.css";

const DateInput = () => {
    const dispatch = useDispatch()
    const { emitOffer } = useSelector(state => state.offers)
    const date = (value) =>{ 
        dispatch(handleExpiredDateOffer(new Date(value).getTime()));
        dispatch(handleDate(new Date(value).getTime()))
    }

    
    return (
        <DatePicker className='custom-input' selected={emitOffer?.expiredAt} onChange={date} />
    );
};

export default DateInput;