import { Box, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { motion } from 'framer-motion';

const Counter = ({ border, Count, setCount }) => {
    const IncrementCount = () => setCount("add")
    const DecrementCount = () => setCount("dec")

    return (
        <Box sx={[{ width: '80%', borderRadius: 15 }, border && { border: '1px solid #e6e6e6', my: 2, py: 1.2, }]} >
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-around'} >
                <motion.div whileTap={{ scale: 0.97 }} onClick={DecrementCount} style={{cursor: "pointer"}} >
                    <RemoveCircleOutlineIcon fontSize='large' />
                </motion.div>
                <Typography>{Count}</Typography>
                <motion.div whileTap={{ scale: 0.999 }} onClick={IncrementCount} style={{cursor: "pointer"}} >
                    <AddCircleOutlineIcon fontSize='large' />
                </motion.div>
            </Stack>
        </Box>
    )
}

export default Counter