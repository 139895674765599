import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFindJobberProfileQuery, useFindUsersProfileQuery } from '../../app/services/userApi'
import EditItem from '../common/EditItem'
import CompetenceUpdateList from '../competenceUpdateList'
import SwitchItemList from '../SwitchItemList'
import './styles.css'
import TransitionsModal from '../Modal'
import AddCompetence from '../AddCompetence'

const EditForm = () => {
    const { profile, isLogged, jobberId } = useSelector(state => state.auth)
    const [openDialog, setOpenDialog] = React.useState(false);
    useFindJobberProfileQuery(null, { skip: !(isLogged === true && jobberId != null) })
    const { data } = useFindUsersProfileQuery(undefined, { refetchOnMountOrArgChange: true })

    return (
        <Box>
            <Box spacing={2} >
                <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '700', fontSize: 40 }} >
                    Informations personnelles
                </Typography>
                <EditItem title={"Prénom"} value={data?.user?.lastname} />
                <EditItem title={"Nom"} value={data?.user?.firstname} />
                <EditItem title={"Adresse e-mail"} value={data?.user?.email} />
                <EditItem title={"Mot de passe"} value={"******"} />
                <EditItem title={"Séxe"} value={data?.user?.sex} />
                <EditItem title={"Date de naissance"} value={data && new Date(data?.user?.birthDate).toLocaleDateString()} />
                {jobberId != null && <>
                    <EditItem title={"Numéro de téléphone"} value={profile?.jobber?.address} />
                    <EditItem title={"Adresse de facturation"} value={profile?.jobber?.address} />
                    <Stack direction={"row"} width='100%' alignItems={"center"} >
                        <div style={{ flexGrow: 1 }} >
                            <Typography sx={{ color: '#00000070', fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '540' }} >
                                Compétences
                            </Typography>
                        </div>
                        <Button onClick={() => setOpenDialog(true)} sx={{ textTransform: 'none', fontSize: 18, height: 45 }} >
                            Modifier
                        </Button>
                    </Stack>
                    <div className='bordered-box' >
                        <CompetenceUpdateList />
                    </div>
                    <Stack direction={"row"} width='100%' alignItems={"center"} >
                        <div style={{ flexGrow: 1 }} >
                            <Typography sx={{ color: '#00000070', fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '540' }} >
                                Jours de prestations
                            </Typography>
                        </div>
                        <Button sx={{ textTransform: 'none', fontSize: 18, height: 45 }} >
                            Modifier
                        </Button>
                    </Stack>
                    <div className='bordered-box' >
                        <SwitchItemList />
                    </div>
                </>
                }
            </Box>
            <TransitionsModal open={openDialog} setOpen={setOpenDialog} >
                <AddCompetence setClose={setOpenDialog} />
            </TransitionsModal>
        </Box>
    )
}

export default EditForm