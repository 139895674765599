import React from 'react'
import { Grid } from '@mui/material';

import user from '../../assets/images/bxs-user-check.svg'
import money from '../../assets/images/money.svg'
import ProfileItem from '../common/ProfileItem';
import { useSelector } from 'react-redux';
import { EDIT_PROFILE, PROFILE, SETTINGS } from '../../router';

const ProfileItemList = () => {
    const { jobberId, profile } = useSelector(state => state.auth)

    return (
        <Grid container flexDirection="row" alignItems={"center"} my={3} spacing={2.5} flexWrap="wrap" >
            <Grid container key={1} item xs={12} sm={12} md={6} lg={3} >
                <ProfileItem
                    title={"Informations personnelles"}
                    to={`${SETTINGS}${PROFILE}${EDIT_PROFILE}`}
                    icon={<img src={user} style={{ height: 40, width: 40 }} />}
                    subTitle={"Complétez et mettez à jour votre identité pour faciliter les échanges avec vos prestataires."}

                />
            </Grid>
            {jobberId !== null &&
                <Grid container key={2} item xs={12} sm={12} md={6} lg={3} >
                    <ProfileItem
                        title={`Solde : ${profile?.account?.solde} xaf`} to={`${SETTINGS}/transactions`}
                        icon={<img src={money} style={{ height: 40, width: 40 }} />}
                        subTitle={"Consultez les paiements et remboursements effectués sur Jejobtout."}
                    />
                </Grid>}
        </Grid>
    )
}

export default ProfileItemList