import { Grid } from '@mui/material'
import React from 'react'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InfoBarItem from '../InfoBarItem';

const bar_data = [
    { icon: <HowToRegIcon fontSize='medium' color='primary' />, title: "Prestataires qualifiées" },
    { icon: <VerifiedUserIcon fontSize='medium' color='primary' />, title: "Prestations assurées" },
    { icon: <BackupTableIcon fontSize='medium' color='primary' />, title: "Budget respecté" },
    { icon: <LocalPhoneIcon fontSize='medium' color='primary' />, title: "Service encadré" },
]

const renderList = bar_data.map(({ title, icon }, i) => (
    <Grid container width={"100%"} key={i} item xs={12} sm={12} md={6} lg={3} >
        <InfoBarItem title={title} icon={icon} />
    </Grid>
))

const InfoBar = () => {
    return (
        <Grid container my={3} spacing={2} >
            {
                renderList
            }
        </Grid>
    )
}

export default InfoBar