import { Box, colors } from '@mui/material'
import React from 'react'
import './styles.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { dispatch } from '../../../app';
import { handleZoneInterventionRayon } from '../../../app/features/authSlice';


const DistanceBtn = ({ onClick, setRayon }) => {
    const handleRayon = (e) => {
        setRayon(e.target.value)
        dispatch(handleZoneInterventionRayon(e.target.value))
    }

    return (
        <div onClick={() => onClick} >
            <Box className='row-flex wrapperL-box' >
                <CircleOutlinedIcon color='primary' />
                <div className='row-flex-space flexL-box' >
                    <input onChange={handleRayon} className='dist-input' type={"number"} placeholder={"Entrer le rayon (en Km)"} />
                    <ArrowForwardIosIcon fontSize='small' color={colors.grey[500]} />
                </div>
            </Box>
        </div>
    )
}

export default DistanceBtn