import { Box, Container } from '@mui/material'
import React from 'react'
import SignInForm from '../../components/SignInForm'

function Loggin() {
  return (
    <Container maxWidth="sm" sx={{ marginY: 5}}>
      <SignInForm />
    </Container>
  )
}

export default Loggin
