import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { baseUrl, ENDPOINTS, maxRetries, METHODS } from '.';
import { getToken, refreshToken } from '../../utilities';

const mutex = new Mutex();

export const baseQueryInterpolatorPublic = retry(fetchBaseQuery({
    baseUrl,
    // prepareHeaders: (headers) => {
    //     // headers.set("Accept", 'application/json')
    //     // headers.set("Content-Type", 'application/json')
    //     return headers
    // }
}), { maxRetries }
)

const baseQuery = retry(fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = getState()?.auth?.authorization
        if (token) headers.set("Authorization", token)
        // headers.set("Accept", 'application/json')
        // headers.set("Content-Type", 'application/json')
        return headers
    },
}), { maxRetries })

export const baseQueryInterpolatorAuth = async (args, api, extraOptions) => {
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    console.log("refresh result", result)
    // if (result?.error && result?.error?.status === 400 && result?.error?.data?.error==='Could not retrieve user'){
    //     api.dispatch(loggedOut())
    // }
    // else 
    // if (result?.error && result?.error?.status === 401 ) {
    //     if (!mutex.isLocked()) {
    //         const release = await mutex.acquire()
    //         try {
    //             const refreshResult = await baseQuery({
    //                 url: `${ENDPOINTS?.REFRESH_TOKEN}/${getToken()}/${refreshToken()}`,
    //                 method: `${METHODS?.POST}`,
    //             }, api, extraOptions)

    //             console.log(refreshResult)
    //             // if (refreshResult?.data) {
    //             //     api.dispatch(setAuthorization(refreshResult.data?.accessToken))
    //             //     result = await baseQuery(args, api, extraOptions)
    //             // } else {
    //             //     api.dispatch(loggedOut())
    //             // }
    //         } finally {
    //             release()
    //         }
    //     } else {
    //         await mutex.waitForUnlock()
    //         result = await baseQuery(args, api, extraOptions)
    //     }
    // }
    return result
}