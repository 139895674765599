import { Box, Breadcrumbs, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import ServiceListItem from '../common/ServiceListItem'
import { useSelector } from 'react-redux'
import { dispatch } from '../../app'
import { handleBreadCrumbServices, handleDemandService, handleGoMainService } from '../../app/features/authSlice'

const ServiceItemList = ({ onItemClick }) => {
    const { filterServices, breadcrumbServices, allServices, searchService } = useSelector(state => state.auth)
    const mainServiceBack = () => dispatch(handleGoMainService())
    const handleBreadCrumbService = (service) => {
        if(searchService !== ""){
            const serviceM = allServices.find(s => s.id === service.parent_id)
            dispatch(handleDemandService(serviceM))
        }
        dispatch(handleBreadCrumbServices(service))
        onItemClick()
    }

    const breadcrumbs = [
        <span key="1" color="inherit" onClick={mainServiceBack}>
            Services
        </span>,
        <Stack> {breadcrumbServices?.map((breadcrumb, index) => (
            <>
                {breadcrumbServices?.length === index + 1 ?
                    <Typography key={index} color="text.primary">
                        {breadcrumb?.title}
                    </Typography> :
                    <span key={index}>{breadcrumb?.title} </span>
                }
            </>
        ))}
        </Stack>
    ]
    return (
        <Box my={2} >
            <Box>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>
            <Grid container spacing={2} flexWrap={"wrap"} >
                {
                    filterServices?.map((service, i) => {
                        return (
                            <Grid item xs={12} key={i + "index"}>
                                <div onClick={() => handleBreadCrumbService(service)} >
                                    <ServiceListItem
                                        icon={service?.url}
                                        title={service?.title}
                                    />
                                </div>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}

export default ServiceItemList