import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import './styles.css'

import { useFindUsersProfileQuery, useUpdateCNIPhotoMutation } from '../../app/services/userApi'

const UploadNIC = ({ setOpen, hide, Img, Img2, setImg, setImg2 }) => {

    const [updateCNIPhoto, { isLoading }] = useUpdateCNIPhotoMutation()
    const { data } = useFindUsersProfileQuery(undefined, { refetchOnMountOrArgChange: true })

    const handleChangeImage = e => { setImg(e.target.files[0])}
    const handleChangeImage2 = e => {  setImg2(e.target.files[0]) }
    const upload = () => { document.getElementById("img").click()}
    const upload2 = () => { document.getElementById("img2").click()  }
    const uploadCni = () => {
        const data = new FormData()
        data.append("recto", Img)
        data.append("verso", Img2)
        updateCNIPhoto(data)
    }


    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
            <Stack direction={"row"} width='100%' alignItems={"center"} >
                {
                    hide ? null :
                        <React.Fragment>
                            <Button onClick={() => setOpen(true)} sx={{ textTransform: 'none', fontSize: 18, height: 45 }} >
                                Modifier
                            </Button>
                            <Button loading={isLoading} onClick={() => uploadCni()} sx={{ textTransform: 'none', fontSize: 18, height: 45 }} >
                                save
                            </Button>
                        </React.Fragment>
                }
            </Stack>
            <Typography sx={{ color: '#00000070', fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '540' }} >
                Recto de la carte d’identité
            </Typography>
            {
                data && data?.user?.photoCni?.rectoUrl != null ?
                    <img className='img-box' src={data?.user?.photoCni?.rectoUrl} /> : Img === null ?
                        <div onClick={upload} className='dashed-box_2' >
                            <Typography sx={{ color: '#00000050', fontFamily: 'Futura-Medium', fontSize: 15, fontWeight: '540' }} >
                                Verification d’identité
                            </Typography>
                            <Typography sx={{ color: '#00000030', fontFamily: 'Futura-Medium', fontSize: 15, fontWeight: '540' }} >
                                Appuyer pour télécharger ou prendre une photo
                            </Typography>
                        </div> :
                        <img className='img-box' src={URL.createObjectURL(Img)} alt="upload logo" />
            }
            <input type="file" id="img" name="img" accept="image/*" onChange={handleChangeImage} />
            <Typography sx={{ color: '#00000070', fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '540' }} >
                Verso de la carte d’identité
            </Typography>
            {
                data && data?.user?.photoCni?.versoUrl != null ?
                    <img className='img-box' src={data?.user?.photoCni?.versoUrl} /> : Img2 === null ?
                        <div onClick={upload2} className='dashed-box_2' >
                            <Typography sx={{ color: '#00000050', fontFamily: 'Futura-Medium', fontSize: 15, fontWeight: '540' }} >
                                Verification d’identité
                            </Typography>
                            <Typography sx={{ color: '#00000030', fontFamily: 'Futura-Medium', fontSize: 15, fontWeight: '540' }} >
                                Appuyer pour télécharger ou prendre une photo
                            </Typography>
                        </div> :
                        <img className='img-box' src={URL.createObjectURL(Img2)} alt="upload logo" />
            }
            <input type="file" id="img2" name="img" accept="image/*" onChange={handleChangeImage2} />

        </Box>
    )
}

export default UploadNIC