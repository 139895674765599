import React from 'react'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InfoBarItem from '../InfoBarItem';
import ServiceMainItem from '../common/ServiecMainItem';
import { Grid } from '@mui/material';
import TipsRound from '../common/TipsRound';

import form from '../../assets/images/form-line.svg'
import users from '../../assets/images/users-solid-alerted.svg'
import calendar from '../../assets/images/calender.svg'

const bar_data = [
    { icon: <img src={form} style={{ height: 90, width: 90 }} />, title: "Prestataires qualifiées", sub_title: "Entrez les details de votre service" },
    { icon: <img src={users} style={{ height: 90, width: 90 }} />, title: "Prestations assurées", sub_title: "Des jobblers compétitifs et proches de chez vous vous proposent leurs services." },
    { icon: <img src={calendar} style={{ height: 90, width: 90 }} />, title: "Budget respecté", sub_title: "Votre jobbler vous rend service au prix et à la date convenue" },
]

const renderList = bar_data.map(({ title, icon, sub_title }, i) => (
    <Grid key={i} item xs={12} sm={12} md={6} lg={4} >
        <TipsRound title={title} icon={icon} subTitle={sub_title} index={i + 1} />
    </Grid>
))

const TipsList = () => {
    return (
        <Grid container justifyContent={"center"} flexDirection="row" alignItems={"center"} my={3} spacing={2} flexWrap="wrap" >
            {
                renderList
            }
        </Grid>
    )
}

export default TipsList