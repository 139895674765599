import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDemandServicesQuery } from '../../app/services/demandServiceApi'
import DemandesItem from '../../components/common/DemandesItem'
import { useSelector } from 'react-redux';
import PaymentConfirmationModal from '../../components/common/PaymentConfiredModal';
import PaymentErrorModal from '../../components/common/PaymentErrorModal';

const ListDemandes = () => {
    const { isLoading } = useDemandServicesQuery(undefined, {refetchOnMountOrArgChange: true, refetchOnReconnect: true});
    const { myDemands } = useSelector(state => state.auth)
    // const [Confirm, setConfirm] = useState(false)
    // const [Error, setError] = useState(false)

    return (
        <Box>
            <Container maxWidth='md'>
                <Typography sx={{ fontSize: 26, fontWeight: '700', fontFamily: 'Futura-Medium', color: '#000', my: 5 }} >
                    Mes Demandes
                </Typography>
                <Grid container spacing={1} >
                    {
                        isLoading ?
                            <Grid item xs={12} >
                                <Typography sx={{ color: 'grey', fontSize: 18, fontWeight: 'bold' }}>
                                    Loading...
                                </Typography>
                            </Grid>
                            : <>
                                {myDemands.length <= 0 ? <Grid item xs={12} >
                                    <Typography sx={{ color: 'grey', fontSize: 18, fontWeight: 'bold' }}>
                                        Aucune demandes enregistre.
                                    </Typography>
                                </Grid> :
                                    myDemands?.map((demand, index) => (
                                        <Grid xs container item width={'100%'} key={index} >
                                            <DemandesItem demand={demand} />
                                        </Grid>
                                    ))
                                }
                            </>
                    }
                </Grid>
            </Container>
            {/* <PaymentConfirmationModal openModal={Confirm} setOpenModal={setConfirm} />
            <PaymentErrorModal openModal={Error} setOpenModal={setError} /> */}
        </Box>
    )
}

export default ListDemandes