import { Box, Container } from '@mui/material'
import React from 'react'
import OffreSteps from '../../components/ofrreSteps'


const OffrePage = () => {

    return (
        <Box className='container' >
            <Container maxWidth={"sm"} >
                <OffreSteps />
            </Container>
        </Box>
    )
}

export default OffrePage