import React from "react";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import './styles.css'
import { Typography } from "@mui/material";

const CardComponent = (props) => {
  const { children, title, onClick } = props
  return (
    <div className="card-wrapper" >
      <div className='card-header' >
        <Typography sx={{ color: 'white', fontFamily: 'Futura-Medium', fontWeight: '600' }} variant="h4" > {title} </Typography>
        <CancelOutlinedIcon onClick={onClick} className="card-icon" sx={{ fontSize: 35, }} />
      </div>
      <div className="card-content" >
        {children}
      </div>
    </div>
  )
    ;
};
export default CardComponent;