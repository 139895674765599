import { Box, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import './styles.css'
import TransitionsModal from '../../components/Modal';
import SignInForm from '../../components/SignInForm';
import { motion } from 'framer-motion';

const Inscription = () => {
    const [OpenModal, setOpenModal] = useState(false)
    return (
        <div className='container bg-white' >
            <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '600', mb: 4, mt: 4, color: '#25282c', textAlign: 'center', fontSize: 25 }} >
                Bienvenue chez JeJobTout
            </Typography>
            <Stack direction={{ sm: "column", md: "row" }} width={"100%"} spacing={{ sm: 5, md: 5 }} marginY={4} alignItems={"center"} justifyContent={"center"} >
                <motion.div whileHover={{ scale: 1.01 }} className='box-outline' >
                    <SupportAgentOutlinedIcon fontSize='inherit' sx={{ fontSize: 120 }} color={"primary"} />
                    <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '600', mb: 2, color: '#25282c', fontSize: 'calc(1.252rem + 0.024vw);' }} >
                        J'ai besoin d'un service
                    </Typography>
                    <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '400', color: '#5d636a', fontSize: 18, my: 5 }} >
                        Trouvez le prestataire idéal pour vos services du quotidien
                    </Typography>
                </motion.div>
                <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '600', color: '#25282c', textAlign: 'center', fontSize: 15 }} >
                    Ou
                </Typography>
                <motion.div whileHover={{ scale: 1.01 }} className='box-outline' >
                    <WorkOutlineOutlinedIcon fontSize='inherit' sx={{ fontSize: 120 }} color={"primary"} />
                    <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '600', mb: 2, color: '#25282c', fontSize: 'calc(1.252rem + 0.024vw);' }} >
                        Devenir jobber
                    </Typography>
                    <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '400', color: '#5d636a', fontSize: 18 }} >
                        Augmentez vos revenus en rendant service près de chez vous
                    </Typography>
                </motion.div>
            </Stack>
            <Stack
                direction={'row'}
                justifyContent='center'
                spacing={{ xs: 1, sm: 2, md: 4 }}
            >
                <Typography variant='inherit' >
                    Vous avez déjà un compte ?
                </Typography>
                <div onClick={() => setOpenModal(true)} >
                    <Typography variant='inherit' className='link' color={"primary"} >
                        Connectez-vous
                    </Typography>
                </div>
            </Stack>
            <TransitionsModal open={OpenModal} setOpen={setOpenModal} >
                <SignInForm />
            </TransitionsModal>
        </div>
    )
}

export default Inscription