import React from 'react';

import { Box, List, ListItemButton, ListItemText, Slider, Typography } from "@mui/material";
import { useSelector } from 'react-redux';
import { dispatch } from '../../../app';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../components/common/Accordion';
import { handlePriceOffers, handleServiceId } from '../../../app/features/offerSlice';


function SideFilterOffer(props) {

  const { mainServices } = useSelector(state => state.auth)
  const [expanded, setExpanded] = React.useState('panel1');
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [value, setValue] = React.useState([1, 200])
  const handleValue = (event, newValue) => {
    setValue(newValue);
    dispatch(handlePriceOffers({rPrice: newValue[1]*1000, lPrice: newValue[0]*1000}))
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function valuetext(value) {
    return `${value * 1000} xaf`;
  }

  const handleListItemClick = (id, index) => {
    setSelectedIndex(index)
    dispatch(handleServiceId(id))
  }

  return (
    <div style={{ margin: `0 auto`, padding: `3px 10px`, width: '100%', }} >
      <Accordion
        expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header">
          <Typography sx={{ fontSize: 16, fontWeight: '600', fontFamily: 'Futura-Medium', color: '#000' }} >Types de services</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="nav" aria-label="secondary mailbox folder">
            {
              mainServices?.map((service, index) => (
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={() => handleListItemClick(service.id, index)}
                >
                  <ListItemText primary={service?.title} />
                </ListItemButton>
              ))
            }
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ fontSize: 16, fontWeight: '600', fontFamily: 'Futura-Medium', color: '#000' }} >Prix</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ width: '90%' }}>
            <Slider
              size='small'
              getAriaLabel={(index) => "price" +index}
              step={1}
              marks={true}
              min={1}
              max={200}
              value={value}
              onChange={handleValue}
            />
            <Typography sx={{ fontSize: 16, fontWeight: '600', fontFamily: 'Futura-Medium', color: '#000' }} >
              <span style={{ fontSize: 15, fontWeight: '500', fontFamily: 'Futura-Medium', color: '#00000045' }} >Price</span>
              {`${valuetext(value[0])} - ${valuetext(value[1])}` } 
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default SideFilterOffer;