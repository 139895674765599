import { Avatar, Box, Chip, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import JButton from '../../components/common/Button'
import './styles.css'
import { Navigate } from 'react-router'
import apple_icon from '../../assets/images/apple-ion.svg'
import play_store from '../../assets/images/play-store.svg'
import AvTimerIcon from '@mui/icons-material/AvTimer';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';

const data_1 = [
    {
        icon: <AvTimerIcon fontSize='inherit' color='primary' sx = {{ fontSize: 28, fontWeight: '500', mb: 3 }} />,
        title: 'Gagnez du temps !',
        desc: `Nous générons factures, reçus, fiche de paie, et suivi de vos revenus à votre place. Vous n’avez rien à faire, tout est dans votre application.`
    },
    {
        icon: <StarBorderPurple500Icon fontSize='inherit' color='primary' sx = {{ fontSize: 28, fontWeight: '500', mb: 3 }} />,
        title: 'Mettez votre profil en avant !',
        desc: `Sur votre fiche personnelle, nos clients retrouvent vos compétences, vos diplômes, notes, avis, compliments reçus pour votre excellent travail et le nombre de jobs réalisés. Mettre votre savoir-faire en avant n’a jamais été aussi facile !`
    },
    {
        icon: <BeachAccessIcon fontSize='inherit' color='primary' sx = {{ fontSize: 28, fontWeight: '500', mb: 3 }} />,
        title: 'Yoojo vous assure',
        desc: `Assurance corporelle : chaque job payé sur Yoojo est assuré contre les dommages corporels. Vous n’avez pas de franchise à payer, on s’occupe de tout dès le 1er €.
        Assurance matérielle : vous avez cassé quelque chose chez le client ? Pas de soucis, notre assurance matérielle est incluse dans votre prestation.`
    },
]

const Prestataire = () => {
  return (
    <Box>
        <Container maxWidth = 'lg' >
        <Grid container display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} spacing={1.2} mb = {2} >
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-center"} justifyContent={"center"} sx={{ flex: 1 }} >
                        <Typography color={"#000000"} sx={{ fontFamily: 'Futura-Medium', fontSize: 54, my: 1, fontWeight: '600', lineHeight: '65px'}} >
                            Devenez prestataire de services à domicile et
                        </Typography>
                        <Typography color={"primary"} sx={{ fontFamily: 'Futura-Medium', fontSize: 54, my: 1, fontWeight: '600', lineHeight: '65px'}} >
                            développez votre activité
                        </Typography>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 16, lineHeight: '25px', my: 2 }} >
                            Commencez à augmenter vos revenus en téléchargeant l'app
                        </Typography>
                        <Stack direction={'row'} justifyContent={'space-between'} >
                        <JButton title={"App Store"} icon = { <img alt='ios icon' style={{ height: 30, marginRight: 10 }} src={apple_icon} /> } style={{ backgroundColor: '#a6cc1f', width: 240 }} />
                        <JButton title={"Play Store"} icon = { <img alt='play store icon' style={{ height: 30, marginRight: 10 }} src={play_store} /> } style={{ backgroundColor: '#a6cc1f', width: 240 }} />
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                    <div className='side-img3' />
                </Grid>
            </Grid>

            <Box sx={{ border: `1px solid lighgray`, padding: 4, width: '100%', my: 2.5 }} >
                <Stack direction={'row'} justifyContent={'space-between'} >
                    <Box>
                        <Typography color={"#000000"} sx={{ fontFamily: 'Futura-Medium', fontSize: 28, mb: 1.3, fontWeight: '600', }} >
                            1 385 000
                        </Typography>
                        <Typography color={"#333"} sx={{ fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '500', }} >
                            Services rendus depuis la création
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color={"#000000"} sx={{ fontFamily: 'Futura-Medium', fontSize: 28, mb: 1.3, fontWeight: '600', }} >
                            1 385 000
                        </Typography>
                        <Typography color={"#333"} sx={{ fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '500', }} >
                            Services rendus depuis la création
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color={"#000000"} sx={{ fontFamily: 'Futura-Medium', fontSize: 28, mb: 1.3, fontWeight: '600', }} >
                            1 385 000
                        </Typography>
                        <Typography color={"#333"} sx={{ fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '500', }} >
                            Services rendus depuis la création
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color={"#000000"} sx={{ fontFamily: 'Futura-Medium', fontSize: 28, mb: 1.3, fontWeight: '600', }} >
                            1 385 000
                        </Typography>
                        <Typography color={"#333"} sx={{ fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '500', }} >
                            Services rendus depuis la création
                        </Typography>
                    </Box>
                </Stack>
            </Box>

            <Typography textAlign={'center'} color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 52, mt: 7, }} >
                Ne pensez plus à l’administratif, on s’occupe de tout !
            </Typography>

            <Grid container width= "100%" display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"} flexDirection={"row"} spacing={3} mb = {4} mt = {8} >
            {
                data_1?.map(({ icon, title, desc }, i)=> (
                    <Grid container flexDirection={'column'} alignItems={'center'} item xs={12} sm={12} md={4} lg={4} >
                        <Stack direction={'row'} justifyContent={'flex-start'} width = {'100%'} pl={6} >{icon} </Stack>
                        <Box width={'85%'} >
                            <Typography width={'100%'} color={'primary'} textAlign={'left'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 26, lineHeight: '25px', mb: 2 }} >
                                {title}
                            </Typography>
                            <Typography width={'100%'} color={'#333'} textAlign={'left'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', mb: 1 }} >
                                {desc}
                            </Typography>
                    </Box>
                </Grid>
                ))
            }    
            </Grid>

            <Typography textAlign={'center'} color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 52, mt: 7, mb: 4}} >
                Vous pouvez enfin tout organiser depuis une seule application
            </Typography>

            <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} mb = {4} >
                <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                    <div className='phone-img' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-center"} justifyContent={"center"} sx={{ flex: 1 }} >
                        <Typography color={'#333'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', my: 1 }} >   
                            Définissez vos conditions
                        </Typography>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px',  }} >
                            Choisissez votre taux horaire, votre zone d’intervention et vos disponibilités pour optimiser votre activité et vos revenus.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} mb = {4} >
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-center"} justifyContent={"center"} sx={{ flex: 1 }} >
                        <Typography color={'#333'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', my: 1 }} >   
                            Développez votre activité
                        </Typography>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px',  }} >
                            Choisissez parmi plus de 60 000 missions ponctuelles ou récurrentes chaque mois. Recevez des alertes pour les nouvelles missions selon vos critères.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                    <div className='phone-img1' />
                </Grid>
            </Grid>

            <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} mb = {4} >
                <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                    <div className='phone-img2' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-center"} justifyContent={"center"} sx={{ flex: 1 }} >
                        <Typography color={'#333'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', my: 1 }} >   
                            Travaillez sereinement
                        </Typography>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px',  }} >
                            Bénéficiez d’outils dédiés pour optimiser votre activité et la gestion de la relation client : messagerie, agenda, paiement en ligne, etc. Vous êtes assuré en cas de casse dès le premier euro.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Typography textAlign={'left'} color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 52, mt: 7, mb: 2}} >
                Rencontrez les prestataires qui cartonnent sur Yoojo
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px',  }} >
                Découvrez pourquoi ils adorent utiliser Yoojo et comment notre application a changé leur façon de travailler
            </Typography>

            <Stack spacing={4} direction ={{ md: 'row', sm: 'column', xs: 'column' }} alignItems={'center'} my ={2} >
                <Avatar sx = {{ height: 100, width: 100 }} src='https://d1b747rczec55w.cloudfront.net/assets/seo/people/moussa-c8bcd98d844642d41462c03517de94a4828591c28970b20137d83b7cfba051cd.jpg'  />
                <Stack direction={'column'} justifyContent = 'center' alignItems={{ md: 'flex-start', sm: 'center' }} >
                    <Typography textAlign={{ md: 'start', sm: 'center' }} color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', mb: 1 }} >
                        <q>Pour vous</q>
                    </Typography>
                    <Typography textAlign={{ md: 'start', sm: 'center' }}  color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '700', fontSize: 38, lineHeight: '25px', lineHeight: 1}} >
                    5 % du montant de toutes les prestations payées par vos amis  <span style={{ fontFamily: 'Futura-Medium', color: '#000', textAlign: 'center', fontWeight: '500', fontSize: 38, lineHeight: '25px', }} >
                    sur JeJobTout, directement dans votre cagnotte.
                    </span>
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    </Box>
  )
}

export default Prestataire