import * as React from 'react';
import InputUnstyled from '@mui/base/InputUnstyled';
import { StyledInputElement } from './styles';
import { Box, InputAdornment, InputBase, OutlinedInput, styled, TextField } from '@mui/material';
import { blue, grey, purple } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';

const CustomInput = React.forwardRef(function CustomInput({ ref, props }) {
    return (
        <InputUnstyled slots={{ input: StyledInputElement }} {...props} ref={ref} />
    );
});

const CustomInput2 = styled(OutlinedInput)(({ theme }) => ({
    fontFamily: 'catamaran',
    fontSize: '0.900rem',
    fontWeight: '500',
    height: 42,
    lineHeight: 1.5,
    padding: '12px',
    overflow: 'clip',
    borderRadius: '12px',
    color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : grey[100],
    border: `none`,
    boxShadow: `0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]}`,

    '&:hover': {
        borderWidth: 1,
        borderColor: theme.palette.primary.main,

    },

    '&:focus': {
        borderWidth: 1,
        borderColor: 'primary',
        outline: `3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]}`
    }
}));

export default function Input({ placeholder, leftIcon, rightIcon, onChange, type, style, defaultValue }) {
    return <CustomInput2
        endAdornment={<InputAdornment position='end' >{rightIcon}</InputAdornment>}
        type={type}
        defaultValue={defaultValue}
        startAdornment={<InputAdornment position='start' >{leftIcon}</InputAdornment>}
        aria-label="Demo input"
        sx={style}
        onChange={onChange}
        placeholder={placeholder} />

}