import { Grid } from '@mui/material'
import React from 'react'
import ServiceItem from '../common/ServiceItem'

import { useSelector } from 'react-redux'
import { dispatch } from '../../app'
import { handleDemandService } from '../../app/features/authSlice'

const ServiceList = (props) => {
    const { mainServices } = useSelector(state => state.auth)
    const handleMainService = (service) => {
        dispatch(handleDemandService(service))
    }
    return (
        <Grid container spacing={2} flexWrap={"wrap"} >
            {mainServices?.map((service, i) => {
                return (
                    <Grid item xs={12} sm={12} md={6} key={i} >
                        <ServiceItem onClick={() => handleMainService(service)} title={service?.title} icon={service?.url} />
                    </Grid>
                )
            })
            }
        </Grid>
    )
}

export default ServiceList