import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import MapView from '../../components/common/MapView'

const ZoneInterventions = () => {
    return (

        <Box className='container' >
            <Container maxWidth='sm' >
                <Typography sx={{ fontSize: 22, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
                    Définissez votre zone d'intervention
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: '600', fontFamily: 'catamaran', color: '#333' }} >
                    Seuls les jobs présents dans cette zone seront dans votre joblist.
                </Typography>
                <Box sx={{ height: 400, width: '100%' }} >
                    <MapView />
                </Box>
            </Container>
        </Box>
    )
}

export default ZoneInterventions