import { Box, colors, Typography } from '@mui/material'
import React from 'react'
import './styles.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { motion } from 'framer-motion';


const ServiceListItem = ({ onClick, icon, title }) => {
    return (
        <motion.div className='pointer' whileTap={{ scale: 0.995 }} onClick={() => onClick} >
            <Box className='row-flex wrapperL-box' >
                <div className='servL-img' style={{ backgroundImage: `url(${icon})` }} ></div>
                <div className='row-flex-space flexL-box' >
                    <Typography sx={{ fontFamily: 'catamaran', fontWeight: '500', color: '#25282c', }} >
                        {title ? title : "service name here"}
                    </Typography>
                    <ArrowForwardIosIcon fontSize='small' color={colors.grey[500]} />
                </div>
            </Box>
        </motion.div>
    )
}

export default ServiceListItem