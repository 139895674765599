import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PaidIcon from '@mui/icons-material/Paid';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useSelector } from 'react-redux';
import { Badge } from '@mui/material';
import { DEMANDES, EDIT_PROFILE, PROFILE, SETTINGS } from '../router';
import { Navigate, useNavigate } from "react-router-dom";
import { Settings } from '@mui/icons-material';
import { dispatch } from '../app';
import { handleSelected } from '../app/features/authSlice';
import { getStorage } from '../utilities';


export const DrawerListItems = () => {
    const { dsbSelected } = useSelector(state => state.auth)
    if (getStorage("dsbcheck") === null) dispatch(handleSelected(0))
    else dispatch(handleSelected(getStorage("dsbcheck")))
    
    const getBoard = (url, index) => {
      dispatch(handleSelected(index))
      window.location.href = url
    }

    React.useEffect(() => {
        const pathname = window.location.pathname.split('/')
        const pathname2 = "https://jejobtout.ekosmartafrica.com/settings/profile".split('/')
        let lastPath = undefined

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
           lastPath = pathname[2]
        } else {
            // production build code
           lastPath = pathname[4]
        }

         
      console.log('window.location.pathname :>> ', lastPath);
      switch (lastPath) {
        case 'profile':
            dispatch(handleSelected(0))
            break;
        case 'mes-demandes':
            dispatch(handleSelected(1))
            break;
        case 'chat':
            dispatch(handleSelected(2))
            break;
        case 'transactions':
            dispatch(handleSelected(3))
            break;
      
        default:
            dispatch(handleSelected(0))
            break;
      }
      return () => {}
    }, [window.location.pathname])
    

    return (
        <React.Fragment>
            <ListItemButton selected={dsbSelected === 0} onClick={() => getBoard(`${SETTINGS}${PROFILE}`, 0)} >
                <ListItemIcon>
                    <PersonIcon fontSize={'inherit'} color='inherit' sx={{ fontSize: dsbSelected === 0 ? 28 : 25, color: dsbSelected === 0 ? '#0f4d61' : null }} />
                </ListItemIcon>
                <ListItemText primary="Compte" sx={{ color: dsbSelected === 0 ? '#0f4d61' : null, fontSize: dsbSelected === 0 ? 17 : 16, fontWeight: dsbSelected === 0 ? '600' : '500' }} />
            </ListItemButton>

            <ListItemButton selected={dsbSelected === 1} onClick={() => getBoard(`${SETTINGS}${DEMANDES}`, 1)} >
                <ListItemIcon>
                    <BookmarkIcon fontSize={'inherit'} color='inherit' sx={{ fontSize: dsbSelected === 1 ? 28 : 25, color: dsbSelected === 1 ? '#0f4d61' : null }} />
                </ListItemIcon>
                <ListItemText primary="Mes demandes" sx={{ color: dsbSelected === 1 ? '#0f4d61' : null, fontSize: dsbSelected === 1 ? 17 : 16, fontWeight: dsbSelected === 1 ? '600' : '500' }} />
            </ListItemButton>

            <ListItemButton selected={dsbSelected === 2} onClick={() => getBoard(`${SETTINGS}/chat`, 2)}>
                <ListItemIcon>
                    <SmsOutlinedIcon fontSize={'inherit'} color='inherit' sx={{ fontSize: dsbSelected === 2 ? 28 : 25, color: dsbSelected === 2 ? '#0f4d61' : null }} />
                </ListItemIcon>
                <ListItemText primary="Chats" sx={{ color: dsbSelected === 2 ? '#0f4d61' : null, fontSize: dsbSelected === 2 ? 17 : 16, fontWeight: dsbSelected === 2 ? '600' : '500' }} />
            </ListItemButton>

            <ListItemButton selected={dsbSelected === 3} onClick={() => getBoard(`${SETTINGS}/transactions`, 3)}>
                <ListItemIcon>
                    <PaidIcon fontSize={'inherit'} color='inherit' sx={{ fontSize: dsbSelected === 3 ? 28 : 25, color: dsbSelected === 3 ? '#0f4d61' : null }} />
                </ListItemIcon>
                <ListItemText sx={{ color: dsbSelected === 3 ? '#0f4d61' : null, fontSize: dsbSelected === 3 ? 17 : 16, fontWeight: dsbSelected === 3 ? '600' : '500' }} primary="Transactions" />
            </ListItemButton>

            <ListItemButton selected={dsbSelected === 4} onClick={() => getBoard(`${SETTINGS}${PROFILE}${EDIT_PROFILE}`, 4)}>
                <ListItemIcon>
                    <Settings fontSize={'inherit'} color='inherit' sx={{ fontSize: dsbSelected === 3 ? 28 : 25, color: dsbSelected === 3 ? '#0f4d61' : null }} />
                </ListItemIcon>
                <ListItemText sx={{ color: dsbSelected === 3 ? '#0f4d61' : null, fontSize: dsbSelected === 3 ? 17 : 16, fontWeight: dsbSelected === 3 ? '600' : '500' }} primary="Settings" />
            </ListItemButton>

        </React.Fragment>
    )
};

export const secondaryListItems = (
    <React.Fragment>
        <ListSubheader component="div" inset>
            Saved reports
        </ListSubheader>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Current month" />
        </ListItemButton>
    </React.Fragment>
);