export const HOST = process.env.REACT_APP_API_URL
export const baseUrl = `${HOST}api/`

export const METHODS = {
    POST: 'POST',
    GET: 'GET',
    HEAD: 'HEAD',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH',
    OPTIONS: 'OPTIONS',
};

export const ENDPOINTS = {
    SING_IN: 'auth/users/login',
    SING_OUT: 'auth/users/logout',
    REFRESH_TOKEN: 'auth/users/refresh',
    signUp: 'auth/users',
    resetPassword: 'auth/users/reset-password',


    USERS: 'users',
    JOBBERS: 'users/jobbers',
    USER_PHOTO: 'users/user-photo',
    USER_CNI_PHOTO: 'users/user-cni-photo',
    OFFRES: '/users/offres',

    DISPONIBILITIES: 'disponibilities/jobbers',
    ZONE_INTERVENTIONS: 'zone-intervention/jobbers',
    CATEGORIES: 'categories',
    COUNTRIES: 'countries',
    SERVICES: 'services',
    JOBBER_SERVICE: 'jobber-services',
    DEMAND_SERVICE: 'user-demand-services',

    CHAT: "chats/users/rooms",
    OFFERS_PROPOSITIONS: "jobber-offers-propositions",


};

export const maxRetries = 5
export const EXPIRED_TOKEN = 30 * 60000 //30 minutes
export const EXPIRED_REFRESH_TOKEN = 60000 * 60 * 24 * 30 // 30 days
export const EXPIRED_DATA = 2048
export const BAD_REQUEST = 400