import React from 'react'
import './styles.css'
import { Avatar, AvatarGroup, Divider, Stack, Typography } from '@mui/material'
import { Box } from '@mui/material'
import JButton from '../Button'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { PAYMENT } from '../../../router'
import { getId, setStorage, toString } from '../../../utilities'
import { useDemandServiceByUserMutation } from '../../../app/services/demandServiceApi'

const DemandesItem = (props) => {
    const { demand } = props
    const [demandServiceByUser, { isLoading }] = useDemandServiceByUserMutation()
    const makeToPay = () => {
        setStorage("deman", toString(demand))
        const { address, time, numberHours, longitude, latitude, date } = demand
        let data = { address, time, user_id: getId(), numberHours, longitude, latitude, date, service_id: demand?.service?.id }
        demandServiceByUser(data).unwrap().then((resp) => {
            window.location.href = `${PAYMENT}/${resp?.service?.id}`
        })
    }
    return (

        <Box className='wrapper-box-demande' >
            <Stack direction={{ sm: "column", md: "row" }} spacing={1} >
                <Box flex={1} >
                    <div className='demande-img' style={{ background: `url(${demand?.service?.url}) bottom center` }}></div>
                    <Typography sx={{ fontFamily: 'Futura-Medium', fontSize: 22, fontWeight: '700', color: '#000' }} >
                        {demand?.service?.title}
                    </Typography>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 15, lineHeight: '25px', mb: 2 }} >
                        {new Date(demand?.date).toDateString()}  à {demand?.time}
                        ({(Math.trunc(demand?.numberHours / 60) > 0 ? Math.trunc(demand?.numberHours / 60) + "h" : "") +
                            (demand?.numberHours % 60 > 0 ? demand?.numberHours % 60 + "min" : "")})
                    </Typography>
                    <JButton loading={isLoading} title={"Payer ma demande"} onClick={makeToPay} />
                </Box>
                <Box flex={1} >
                    <Typography sx={{ fontFamily: 'Futura-Medium', fontWeight: '400', color: '#25282c', fontSize: 18 }} >
                        Prestataires réservé
                    </Typography>
                    <Typography sx={{ fontFamily: 'Futura-Medium', fontWeight: '400', color: '#25282c95', my: 2, fontSize: 16 }} >
                        Prestataires réservé
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Box sx={{ padding: 1.5, backgroundColor: '#0f4d6140', borderRadius: 7 }} >
                        <Stack direction={"row"} alignItems={"center"} >
                            <NotificationsIcon color='primary' fontSize='inherit' sx={{ fontSize: 24 }} />
                            <Typography color={"primary"} fontWeight={'700'} alignItems={'center'} >
                                Vous avez recu 5 offres
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} justifyContent={"flex-start"} alignItems={"center"} >

                            <AvatarGroup max={4}>
                                <Avatar alt="Remy Sharp" />
                                <Avatar alt="Travis Howard" />
                                <Avatar alt="Cindy Baker" />
                                <Avatar alt="Agnes Walker" />
                                <Avatar alt="Trevor Henderson" />
                            </AvatarGroup>
                            <Typography sx={{ fontFamily: 'Futura-Medium', fontWeight: '400', color: '#333', my: 2, fontSize: 14 }} >
                                Offres
                            </Typography>
                        </Stack>
                    </Box>
                    {/* <ArrowForwardIosIcon fontSize='small' color={colors.grey[500]} /> */}
                </Box>
            </Stack>
        </Box>

    )
}

export default DemandesItem