import { Box } from '@mui/material'
import React from 'react'
import TipsInfo from '../../components/common/TipsInfo'
import SelectDropdown from '../../components/common/SelectDropdown'

const Offreservice = () => {
    return (
        <Box>
            <SelectDropdown />
            <TipsInfo text={"Ces information seront transmises uniquement aux prestataires que vous réservez"} />
        </Box>
    )
}

export default Offreservice