import { Box, Button, Checkbox, colors, Divider, FormControlLabel, FormGroup, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import JButton from '../common/Button'
import Input from '../Input'
import { Link } from 'react-router-dom';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FaceButton from '../common/FacebookBtn';
import { useSignInMutation } from '../../app/services/authApi';
import { setAuth } from '../../app/features/authSlice';
import { dispatch } from '../../app/index';


const SignInForm = ({ openModal, setOpenModal, setOpenUpForm, OpenUpModal, setForgot }) => {
    const [signIn, { isLoading, error: errorApi }] = useSignInMutation()

    const [values, setValues] = React.useState({
        username: '',
        password: '',
        showPassword: false,
    });

    const handleSignIn = () => {
        const data = { username: values.username, password: values.password }
        signIn(data).unwrap().then((resp) => {
            if (resp?.accessToken) {
                dispatch(setAuth(resp))
                window.location.href = `/`
            }
        })
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleOpenUp = () => {
        setOpenModal(false)
        setOpenUpForm(true)
    }
    const handleForgot = () => {
        setOpenModal(false)
        setForgot(true)
    }

    return (
        <Box>
            <Stack spacing={2} >
                <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '700', fontSize: 30 }} >
                    Content de vous revoir!
                </Typography>
                <Typography variant='inherit' sx={{ fontSize: 18, color: '#333' }} >
                    Connectez-vous pour accéder à votre compte
                </Typography>
                <FaceButton />
                <Divider>OU</Divider>
                {errorApi && errorApi.status===401 && <Typography sx={{color: "red"}}>username ou mot de passe incorrect</Typography>}
                <Input
                    placeholder={"Nom d'utilisateur"}
                    type="text"
                    leftIcon={<EmailOutlinedIcon color={colors.grey[500]} />}
                    onChange={handleChange('username')}
                />
                <Input
                    placeholder={"Mot de passe"}
                    type={values.showPassword ? 'text' : 'password'}
                    leftIcon={<LockOutlinedIcon color={colors.grey[500]} />}
                    rightIcon={
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    }
                    onChange={handleChange('password')}
                />
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent='flex-end'
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    {/* <FormGroup>
                        <FormControlLabel sx={{ color: colors.grey[600], alignSelf: 'center' }} control={<Checkbox />} label="Se souvenir de moi" />
                    </FormGroup> */}
                    <Button sx={{ textTransform: 'none', fontWeight: '400', fontSize: 14 }} onClick={handleForgot} >Mot de passe oublié ?</Button>
                </Stack>
                <JButton title={"Se connecter"} onClick={handleSignIn} loading={isLoading} />
                <Stack
                    direction={'row'}
                    justifyContent='center'
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Typography variant='inherit' >
                        Pas encore membre ?
                    </Typography>
                    <Link className='link'>
                        <Typography onClick={handleOpenUp} variant='inherit' color={"primary"} >
                        Créer un compte
                       </Typography>
                    </Link>
                    
                </Stack>
            </Stack>

        </Box>
    )
}

export default SignInForm