import { Stack, Typography } from '@mui/material'
import React from 'react'
import './styles.css'

const HomeService = ({ title, icon, subTitle }) => {
    return (
        <Stack my={1} direction={"column"} justifyContent={"center"} alignItems={"flex-start"} flexDirection={"column"} >
            <div>
                {icon}
            </div>
            <Typography sx={{ fontFamily: 'Futura Md BT', fontWeight: '700', fontSize: 16, color: 'black', my: 2 }} >
                {title}
            </Typography>
            <Typography sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', color: '#5D636A', fontSize: 14, }} >
                {subTitle}
            </Typography>
        </Stack>


    )
}

export default HomeService