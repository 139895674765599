import { Button, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Input from '../../Input'

const EditItem = ({ title, value }) => {
    const [Modif, setModif] = useState(false)
    const [newValue, setNewValue] = useState(value)

    let disabled = true

    const setDisabled = () => {
        if (newValue != value) {
            disabled = false
        }
    }

    useEffect(() => {
        setDisabled()
        return () => { }
    }, [newValue])


    return (
        <React.Fragment>
            {
                Modif ?
                    <Stack width={"90%"} >
                        <Typography sx={{ color: '#333', fontFamily: 'Futura-Medium', fontSize: 18, fontWeight: '550', mb: 1 }} >
                            {title}
                        </Typography>
                        <Input defaultValue={value} onChange={(e) => setNewValue(e.target.value)} />
                        <Stack direction={"row"} spacing={1.2} my={1} >
                            <Button onClick={() => setModif(false)} variant='outlined' sx={{ textTransform: 'none', fontSize: 18, height: 45 }}  >
                                Annuler
                            </Button>
                            <Button variant='contained' disabled={disabled} sx={{ textTransform: 'none', fontSize: 18, height: 45 }}  >
                                Enregistrer
                            </Button>
                        </Stack>
                    </Stack> :
                    <Stack direction={"row"} width='100%' alignItems={"center"} >
                        <div style={{ flexGrow: 1 }} >
                            <Typography sx={{ color: '#00000070', fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '540' }} >
                                {title}
                            </Typography>
                            <Typography sx={{ color: '#000000', fontFamily: 'Futura-Medium', fontSize: 17, fontWeight: '600' }} >
                                {value}
                            </Typography>
                        </div>
                        <Button onClick={() => setModif(true)} sx={{ textTransform: 'none', fontSize: 18, height: 45 }} >
                            Modifier
                        </Button>
                    </Stack>
            }
            <Divider color='#c0c0c0' />
        </React.Fragment>


    )
}

export default EditItem