import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  canGetChats: true,
  room: null,
  messages: [],
 roomId: -1,

}

const chatSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    handleTheRoom(state, action) {
      state.room = action.payload
    },
    handleCanFetchMessages(state, action) {
      state.canGetChats = action.payload
    },
    handleChatMessages(state, action) {
      const { response, roomId } = action.payload

    state.roomId = roomId
       state.messages[state.roomId] = response

      //    const {response, arg} = action.payload
      //             const { index, user_id} = arg
      //             setIndexSms(index)
      //             state.indexMessage= index
      //             response?.result?.messages?.sort((m1 , m2) => compare(m1?.created, m2.created))
      //             const copyMessage = state.messages
      //             copyMessage[index] = {response, user_id}

      //             state.messages = copyMessage
  },
  handleRoomId(state, action){
    state.roomId = parseInt(action.payload)
    },
  },
})

export const {
  handleTheRoom,
  handleCanFetchMessages,
  handleChatMessages,
 handleRoomId,

} = chatSlice.actions

export default chatSlice