import React from 'react'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Grid } from '@mui/material';
import HomeService from '../common/HomeService';

import user from '../../assets/images/bxs-user-check.svg'
import shield from '../../assets/images/shield-check.svg'
import money from '../../assets/images/money.svg'
import phone from '../../assets/images/telephone.svg'

const bar_data = [
    { icon: <img src={user} style={{ height: 40, width: 40 }} />, title: "Prestataires qualifiées", sub_title: "Tous les prestataires sont vérifiés, suivis et évalués pour chaque service rendu afin de vous garantir le meilleur niveau de satisfaction." },
    { icon: <img src={shield} style={{ height: 40, width: 40 }} />, title: "Prestations assurées", sub_title: "Toutes les prestations sont couvertes par notre assurance AXA, qu’il s’agisse de dommages corporels ou matériels occasionnés chez vous, sans franchise." },
    { icon: <img src={money} style={{ height: 40, width: 40 }} />, title: "Budget respecté", sub_title: "Tous les prix sont définis à l’avance, les jobbers s’engagent à les respecter. Toutes les rémunérations sont déclenchées en ligne après votre accord." },
    { icon: <img src={phone} style={{ height: 40, width: 40 }} />, title: "Service Encadré", sub_title: "Notre service client est à votre disposition 7j/7 pour vous assurer une expérience parfaite de la prise de commande jusqu’à la fin de la prestation." },
]

const renderList = bar_data.map(({ title, icon, sub_title }, i) => (
    <Grid container justifyContent={"center"} key={i} item xs={12} sm={12} md={6} lg={3} >
        <HomeService title={title} icon={icon} subTitle={sub_title} />
    </Grid>
))

const HomeServiceList = () => {
    return (
        <Grid container justifyContent={"center"} flexDirection="row" alignItems={"center"} my={3} spacing={2.5} flexWrap="wrap" >
            {
                renderList
            }
        </Grid>
    )
}

export default HomeServiceList