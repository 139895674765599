import { Box, Container, Typography } from '@mui/material'
import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../../app'
import { handleNumberHours } from '../../app/features/authSlice'
import TimeItem from '../../components/common/TimeItem'

const HeurePrestations = () => {
    const { breadcrumbServices } = useSelector(state => state.auth)
    const time_data = [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360]
    const [ActiveIndex, setActiveIndex] = useState(undefined)
    const numberHours = (i, value) => {
        setActiveIndex(i)
        dispatch(handleNumberHours(value))
    }
    return (
        <Box>
            <Container maxWidth='sm' >
                <Typography sx={{ fontSize: 18, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
                    {breadcrumbServices?.at(-1)?.title}
                </Typography>
                <Stack my={2} display={"flex"} flexDirection={"row"} flexWrap={"wrap"} alignItems={"center"} justifyContent={"center"} >
                    {
                        time_data.map((time, i) => (
                            <TimeItem
                                time={(Math.trunc(time / 60) > 0 ? Math.trunc(time / 60) + "h" : "") + (time % 60 > 0 ? time % 60 + "min" : "")}
                                index={i}
                                activeIndex={ActiveIndex}
                                onClick={() => numberHours(i, time)}
                            />
                        ))
                    }
                </Stack>
            </Container>
        </Box>
    )
}

export default HeurePrestations