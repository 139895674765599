import React from 'react'
import { Stack } from '@mui/material';

import SwitchItem from '../common/SwitchItem';
import { useSelector } from 'react-redux';

const SwitchItemList = () => {
    const { profile } = useSelector(state => state.auth)
    return (
        <Stack direction={"row"} flexWrap={"wrap"} >
            {profile?.disponibility?.monday && <SwitchItem title={"Lundy"} />}
            {profile?.disponibility?.tuesday && <SwitchItem title={"Mardi"} />}
            {profile?.disponibility?.wednesday && <SwitchItem title={"Mercredi"} />}
            {profile?.disponibility?.thursday && <SwitchItem title={"Jeudi"} />}
            {profile?.disponibility?.friday && <SwitchItem title={"Vendredi"} />}
            {profile?.disponibility?.saturday && <SwitchItem title={"Samedi"} />}
            {profile?.disponibility?.sunday && <SwitchItem title={"Dimanche"} />}
        </Stack>
    )
}

export default SwitchItemList