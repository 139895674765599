import { Box, Container } from '@mui/material'
import React from 'react'
import StepperPages from '../components/StepperPages'

const IndexPage = () => {
    return (
        <Box className='container' >
            <Container maxWidth={"sm"} >
                <StepperPages />
            </Container>
        </Box>
    )
}

export default IndexPage