import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from '.'
import { ENDPOINTS, HOST, METHODS } from '.'
import { getId, getObjectAsSearchParameter } from '../../utilities'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: ['Auth'],
  endpoints: (builder) => ({

    signIn: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.SING_IN}`,
          method: `${METHODS?.POST}`,
          body,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    signUpClient: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.signUp}`,
          method: `${METHODS?.POST}`,
          body,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    signUpJobber: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.signUp}/jobbers`,
          method: `${METHODS?.POST}`,
          body,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    resetPassword: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.resetPassword}`,
          method: `${METHODS?.PUT}`,
          body,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    confirmResetPassword: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.resetPassword}/confirm`,
          method: `${METHODS?.PUT}`,
          body,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    verifyOtp: builder.mutation({
      query(params) {
        return {
          url: `auth/users/otp/verify`,
          method: `${METHODS?.PUT}`,
          params: getObjectAsSearchParameter(params),
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    resendOtp: builder.mutation({
      query(body) {
        return {
          url: `auth/users/otp/resend`,
          method: `${METHODS?.PUT}`,
          body
        }
      },
      extraOptions: { maxRetries: 0 },
    }),
    singOutAllSessions: builder.mutation({
      query() {
        return {
          url: `${ENDPOINTS?.SING_OUT}/${getId()}/all`,
          method: `${METHODS?.DELETE}`,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    processPayments: builder.mutation({
      query({ api_payment, body, Authorization, mnoName, lang, countryCode }) {
        return {
          url: api_payment,
          method: `${METHODS?.POST}`,
          body,
          headers: {
            Authorization,
            "mno-name": mnoName,
            "country-code": countryCode,
            "channel": "web",
            lang,
          },
        }
      },
      extraOptions: { maxRetries: 0 },
    }),
  }),
})


export const {
  useSignInMutation,
  useSignUpClientMutation,
  useSignUpJobberMutation,
  useSingOutAllSessionsMutation,
  useResetPasswordMutation,
  useConfirmResetPasswordMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useProcessPaymentsMutation,

} = authApi