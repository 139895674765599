import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import JButton from '../../components/common/Button'
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ServiceListItem from '../../components/common/ServiceListItem'
import TipsInfo from '../../components/common/TipsInfo';

const TypeDocs = () => {
    const navigate = useNavigate()

    const onClick = () => {
        navigate("docdetails")
    }
    return (
        <Box className='container' >
            <Container maxWidth='sm' >
                <Typography
                    sx={{ fontSize: 22, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
                    Sélectionnez le type de documents à envoyer
                </Typography>
                <Link className='link' to={"/docdetails"} >
                    <ServiceListItem onClick={onClick} icon={<DesignServicesIcon color={"primary"} />} />
                </Link>
                <TipsInfo text={"Ces documents sont necessaires pour valider votre identité, votre age, et votre éligibilité à travailler sur le térritoire. ils ne seront jamais rendus publics."} />

            </Container>
        </Box>
    )
}

export default TypeDocs