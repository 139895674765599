import { Box, Divider, Grid, Stack } from '@mui/material'
import React from 'react'
import './styles.css'
import search_logo from '../../../assets/images/bx-search-alt-2.svg'
import RoomIcon from '@mui/icons-material/Room';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { dispatch } from '../../../app';
import { hanleLatLonDemand } from '../../../app/features/authSlice';


const SearchBarAdvanced = () => {

    const handleSelect = async (address) => {
        // console.log('address :>> ', address);
        geocodeByAddress(address?.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                // console.log('Successfully got latitude and longitude', { lat, lng })
                dispatch(hanleLatLonDemand({ lat, lng }))
            }
            );
    }

    return (
        <Grid container direction={'row'} alignItems={'center'} className='wrapper' >
            <Grid container flex={1} item xs flexGrow={1} >
                <Grid height={'100%'} container justifyContent={'center'} item xs={12} sm={12} md={4} >
                    <Stack px={3} direction={'row'} alignItems={'center'} width={'100%'} >
                        <img src={search_logo} alt='logo search' style={{ height: 35, width: 35 }} />
                        <GooglePlacesAutocomplete apiKey={process.env.REACT_APP_GOOGLE_API_KEY} selectProps={{
                            onChange: handleSelect,
                            placeholder: 'Online job',
                            className: 'input-bar',
                        }} />
                    </Stack>
                </Grid>
            </Grid>
            <Grid item xs={3} height={'100%'} container justifyContent={'flex-end'}  >
                <button className='button-advanced' >
                    Rechercher
                </button>
            </Grid>
        </Grid>
    )
}

export default SearchBarAdvanced