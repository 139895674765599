import { Avatar, Box, Chip, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import JButton from '../../components/common/Button'
import './styles.css'
import { Navigate } from 'react-router'
import Stepper from '../../components/parrainageStepper'
import StepperInvite from '../../components/parrainageStepper'
import CustomizedAccordions from '../../components/ParraingeAccord'

const InviterAmis = () => {
  return (
    <Box>
        <Container maxWidth = {'lg'} >
            <Grid container display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} spacing={1.2} mb = {2} >
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Chip color='primary' label="nouveau" />
                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-center"} justifyContent={"center"} sx={{ flex: 1 }} >
                        <Typography color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 48, my: 1, }} >
                            Parrainez vos amis, récoltez des gains à vie
                        </Typography>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 2 }} >
                            Invitez vos amis et recevez 5% du montant dépensé par vos amis, à vie.
                        </Typography>
                        <JButton title={"Je parraine mes amis"} onClick={() => Navigate('/jobber')} style={{ backgroundColor: '#a6cc1f' }} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                    <div className='side-img2' />
                </Grid>
            </Grid>
            <Divider flexItem sx = {{ alignSelf: 'center', justifySelf: 'center', mb: 5 }} />
            <Container maxWidth = {'md'} >
                <Typography color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 48, my: 1, textAlign: 'center' }} >
                Soyez malin, devenez parrain
                </Typography>
                <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', textAlign: 'center', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 2 }} >
                Devenez parrain JeJobTout et cumulez de l’argent sur votre cagnotte pour économiser sur vos services à domicile.
                </Typography>
                <Stack spacing={4} direction ={{ md: 'row', sm: 'column', xs: 'column' }} alignItems={'center'} my ={2} >
                    <Avatar sx = {{ height: 220, width: 220 }} src='https://d1b747rczec55w.cloudfront.net/assets/referral/referral-avatar-me-76ed0f4b1216a1dbcb6124d6514306d8408ef04f428d42616afc755df67b7c4d.svg'  />
                    <Stack direction={'column'} justifyContent = 'center' alignItems={{ md: 'flex-start', sm: 'center' }} >
                        <Typography textAlign={{ md: 'start', sm: 'center' }} color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', mb: 1 }} >
                        Pour vous
                        </Typography>
                        <Typography textAlign={{ md: 'start', sm: 'center' }}  color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '700', fontSize: 38, lineHeight: '25px', lineHeight: 1}} >
                        5 % du montant de toutes les prestations payées par vos amis  <span style={{ fontFamily: 'Futura-Medium', color: '#000', textAlign: 'center', fontWeight: '500', fontSize: 38, lineHeight: '25px', }} >
                        sur JeJobTout, directement dans votre cagnotte.
                        </span>
                        </Typography>
                    </Stack>
                </Stack>
                <Stack spacing={4} direction ={{ md: 'row', sm: 'column-reverse', xs: 'column-reverse' }}  alignItems={'center'} my ={2} >
                    <Stack direction={'column'} justifyContent = 'center' alignItems={{ md: 'flex-start', sm: 'center' }} >
                        <Typography textAlign={{ md: 'start', sm: 'center' }} color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', mb: 1 }} >
                        Pour vos amis
                        </Typography>
                        <Typography textAlign={{ md: 'start', sm: 'center' }}  color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '700', fontSize: 38, lineHeight: '25px', lineHeight: 1}} >
                        3 € de réduction  <span style={{ fontFamily: 'Futura-Medium', color: '#000', textAlign: 'center', fontWeight: '500', fontSize: 38, lineHeight: '25px', }} >
                        sur leur premier service.
                        </span>
                        </Typography>
                    </Stack>
                    <Avatar sx = {{ height: 220, width: 220 }} src='https://d1b747rczec55w.cloudfront.net/assets/referral/referral-avatar-friend-11bca4427957a17cc501135d5743c76c609ed230cae167ebaf3dcb0bdb8438ed.svg'  />
                </Stack>
                <Divider flexItem sx = {{ alignSelf: 'center', justifySelf: 'center', mb: 5 }} />
                <Typography color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 48, my: 1, textAlign: 'center', mb: 2 }} >
                Comment ça marche ?
                </Typography>
                <StepperInvite />
            </Container>
            <Container sx ={{ mt: 6, px: 5 }} maxWidth = {'md'} >
            <Typography textAlign={{ md: 'start', sm: 'center' }}  color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '700', fontSize: 24, lineHeight: '25px', mb: 3, lineHeight: 1}} >
            Foire aux questions
            </Typography>
            <CustomizedAccordions />
            </Container>
        </Container>
    </Box>
  )
}

export default InviterAmis