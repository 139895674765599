import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, METHODS } from ".";
import { dispatch } from "..";
import { getId, getJobberId, getObjectAsSearchParameter } from "../../utilities";
import { handleOffers } from "../features/offerSlice";
import { baseQueryInterpolatorAuth } from "./interceptor";

export const offreApi = createApi({
  reducerPath: 'offreApi',
  baseQuery: baseQueryInterpolatorAuth,
  tagTypes: ["Offers", "Offers_Jobber", "Offer_Proposition"],
  endpoints: (builder) => ({
    allOffers: builder.query({
      query(params) {
        return {
          url: `${ENDPOINTS?.OFFRES}${getObjectAsSearchParameter(params)}`,
        }
      },
      keepUnusedDataFor: 0,
      providesTags: ["Offers"]
    }),

    offersOfUSer: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.OFFRES}/${getId()}/user`,
        }
      },
    }),
    offersPlatform: builder.query({
      query(id) {
        return {
          url: `${ENDPOINTS?.OFFRES}/all/${id}`,
        }
      },
      transformResponse: (response) => {
        dispatch(handleOffers(response))
        return response
      },
    }),

    offerById: builder.query({
      query(id) {
        return {
          url: `${ENDPOINTS?.OFFRES}/${id}`,
        }
      },
      keepUnusedDataFor: 0,
      providesTags: (response, meta, args) => [{ type: "Offers_Jobber", id: args }],
    }),

    offersMatchForJobberServices: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.OFFRES}/${getJobberId()}/jobber`,
        }
      },
      keepUnusedDataFor: 0,
      providesTags: ["Offers_Jobber"],
      transformResponse: (response) => {
        dispatch(handleOffers(response))
        return response
      },

    }),

    createOffre: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.OFFRES}`,
          body,
          method: `${METHODS?.POST}`
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Offers"],
    }),

    getEmitOfferByUser: builder.query({
      query(id) {
        return {
          url: `${ENDPOINTS?.OFFRES}/${id}/user`,
        }
      },
      extraOptions: { maxRetries: 0 },
      providesTags: ["Offers"],
    }),

    //
    emitPropositionByJobber: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.OFFERS_PROPOSITIONS}`,
          body,
          method: METHODS?.POST
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    allJobberPropositionsByOfferId: builder.query({
      query(id) {
        return {
          url: `${ENDPOINTS?.OFFERS_PROPOSITIONS}/${id}/jobbers`,
        }
      },
      extraOptions: { maxRetries: 0 },
      providesTags: ["Offers"],
    }),

  })
})

export const {
  useOffersOfUSerQuery,
  useOffersMatchForJobberServicesQuery,
  useCreateOffreMutation,
  useGetEmitOfferByUserQuery,
  useOfferByIdQuery,
  useEmitPropositionByJobberMutation,
  useAllJobberPropositionsByOfferIdQuery,
  useOffersPlatformQuery,
  useAllOffersQuery,

} = offreApi