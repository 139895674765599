import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import Slide from '@mui/material/Slide';
import Input from '../Input';
import { Box, colors, Container } from '@mui/material';
import ServiceList from '../ServiceList';
import ServiceItemList from '../ServiceItemList';
import logo from '../../assets/images/logonew.png'
import { handleFilterService } from '../../app/features/authSlice';
import { dispatch } from '../../app';
import { useSelector } from 'react-redux';
import { DEMANDE_SERVICE_ADDRESS } from '../../router';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogLayout({ open, setOpen }) {
    const { isMainService, searchService } = useSelector(state => state.auth)
    const handleClose = () => setOpen(false)
    const handleCloseList = () => { handleClose(); window.location.href = `${DEMANDE_SERVICE_ADDRESS}` }
    const handleSearchService = (e) => dispatch(handleFilterService(e.target.value))

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar elevation={1} sx={{ position: 'relative', bgcolor: '#0f4d61' }}>
                    <Toolbar>
                        <Stack direction={"row"} width={'100%'} alignItems={"center"} justifyContent={"space-between"} >
                            <Box flexGrow={1}>
                                <img alt={""} src={logo} style={{ width: 150,height:130 }} />
                            </Box>
                            <Button autoFocus  onClick={handleClose} sx={{ textTransform: 'none', fontFamily: 'Futura-Medium',color:'#fff' }} >
                                Fermer
                            </Button>
                            {/* <Button autoFocus color="secondary" onClick={handleClose} sx={{ textTransform: 'none', fontFamily: 'Futura-Medium',color:'#fff' }} >
                                Fermer
                            </Button> */}
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Container maxWidth='lg' sx={{ mt: 5 }} >
                    <Input style={{ width: '100%', mb: 4 }} value={searchService} onChange={handleSearchService} placeholder={"Rechercher un service..."} leftIcon={<SearchIcon color={colors.grey[500]} />} />
                    {( searchService !=="" ||  !isMainService) && <ServiceItemList onItemClick={handleCloseList} /> }
                    { searchService==="" && isMainService && <ServiceList />}
                </Container>
            </Dialog>
        </div>
    );
}