import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import JButton from '../../components/common/Button'
import Input from '../../components/Input'
import OutilSelect from '../../components/common/OutilSelect'
import { getItem, getJobberId, toJson } from '../../utilities'
import { useSelector } from 'react-redux'
import { useAddUpdateJobberServiceMutation } from '../../app/services/jobber-serviceApi'
import { HOME } from '../../router'

const JobAmount = () => {
    const { outils, allServices, jobberId } = useSelector(state => state.auth)
    if(jobberId===null)  window.location.href = `${HOME}`
    const selected = toJson(getItem("selected"))
    const [price, setPrice] = React.useState(5000);
    const service = allServices?.find(service => service.id === selected)
    const [addUpdateJobberService, {isLoading}] = useAddUpdateJobberServiceMutation()
    const next = () => {
        const data = {service_id: selected, outils, price,jobber_id: getJobberId()}
        addUpdateJobberService(data).unwrap().then((resp)=>{
            window.location.href = `${HOME}`
        })
    }

    return (
        <Box sx={{ pt: 6 }} >
            <Container maxWidth='sm'>
                <Typography sx={{ fontSize: 18, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
                    {service?.title}
                </Typography>
                <Typography sx={{ fontSize: 22, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
                    Quel est le montant minimum par heure de votre prestation ?
                </Typography>
                <Input 
                    type="number" 
                    style={{ width: '100%' }} 
                    placeholder={"Entrer le montant minimum"} 
                    onChange={(e) => setPrice(parseFloat(e.target.value))}
                    value={price}
                    defaultValue={5000}
                />
                <Typography sx={{ fontSize: 22, fontWeight: '700', fontFamily: 'catamaran', color: '#333', mt: 4 }} >
                    Quelle sont vos outils de travail ?
                </Typography>
                <OutilSelect />
                    <JButton loading={isLoading} onClick={() => next()} style={{ marginTop: 2 }} title={"Continuer"} />
            </Container>
        </Box>
    )
}

export default JobAmount