import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import CardComponent from '../../components/common/card'
import JButton from '../../components/common/Button'
import Counter from '../../components/common/Counter'

import orange_money from '../../assets/images/Orange_Money-Logo.png'
import mobile_money from '../../assets/images/mobile-money-logo.png'
import { getId, getStorage, setStorage, toJson, toString } from '../../utilities'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { DEMANDES, SETTINGS } from '../../router'
import { dispatch } from '../../app'
import { handleDemandToPay, handleHoursBeforepay } from '../../app/features/authSlice'
import { useProcessPaymentsMutation } from '../../app/services/authApi'

const Payment = () => {
    const { idService } = useParams()
    const setCount = (sign) => dispatch(handleHoursBeforepay(sign))
    const [Switch, setSwitch] = useState(false)

    const { allServices, mainServices, demandToPay } = useSelector(state => state.auth)
    const demand = toJson(getStorage("deman"))

    const service = allServices?.find(s => s.id === demand?.service?.id)
    const parent = mainServices?.find(s => s.id === service?.parent_id)

    const hours = (Math.trunc(demandToPay?.numberHours / 60) > 0 ? Math.trunc(demandToPay?.numberHours / 60) + "h" : "") + (demandToPay?.numberHours % 60 > 0 ? demandToPay?.numberHours % 60 + "min" : "")
    const nbhours = (Math.trunc(demandToPay?.numberHours / 60) > 0 ? Math.trunc(demandToPay?.numberHours / 60) : 0) + (demandToPay?.numberHours % 60 > 0 ? 0.5 : 0)

    const [processPayments, {isLoading}] = useProcessPaymentsMutation()
    const accessToken = process.env.REACT_APP_API_PAYMENT_ACCESS_TOKEN
    const reference = process.env.REACT_APP_API_PAYMENT_REFERENCE
    const api_payment = process.env.REACT_APP_API_PAYMENT
    const countryCode = "CM"
    const [mnoName, setMnoName] = React.useState('mtn')
    const lang = 'fr'
    const host = window.location.origin

    const processPayment = () => {
        const Authorization = "Bearer " + accessToken
        const order_id = "Jejobtout_" + idService +"_" + getId() + "_" + (new Date().getTime())
        const body = {
            currency: "XAF",
            order_id,
            "amount": 5000 * nbhours,
            reference,
            state: `payment`,
            return_url: `${host}`,
            cancel_url: `${host}${SETTINGS}${DEMANDES}`,
        }

        processPayments({ api_payment, body, Authorization, mnoName, lang, countryCode })
            .then((resp) => {
                console.log('resp', resp)
            }).catch((err) => {
                console.log('err', err)
            });
    }

    const payWithMtn = () => {
        setStorage("deman", toString(demandToPay))
        setMnoName(prev => prev = 'mtn')
        processPayment()
    }

    const payWithOrange = () => {
        setStorage("deman", toString(demandToPay))
        setMnoName(prev => prev = 'orange')
        processPayment()
    }

    React.useEffect(() => {
        dispatch(handleDemandToPay(demand))
    }, [0])

    return (
        <Box sx={{ flex: 1 }} >
            {
                Switch ? <CardComponent title={'Payment'} onClick={() => setSwitch(false)} >
                    <Typography sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 17, textAlign: 'center' }} >
                        {parent?.title}
                    </Typography>
                    <Stack direction={{ sx: 'column', sm: 'column', md: 'row' }} width={'100%'} my={2} >
                        <Stack direction={'column'} alignItems={'center'} sx={{ width: '100%', bgcolor: '#0f4d61' }} divider={<Divider sx={{ backgroundColor: 'lightgrey' }} orientation={{ sx: "horizontal", sm: "horizontal", md: "vertical" }} flexItem />} >
                            <Box sx={{ height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', }} >
                                <Typography sx={{ fontFamily: 'Futura-Medium', justifySelf: 'flex-start' }} >
                                    Type de services
                                </Typography>
                            </Box>
                            <Box sx={{ height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', }} >
                                <Typography sx={{ fontFamily: 'Futura-Medium', justifySelf: 'flex-start' }} >
                                    Durée prestations
                                </Typography>
                            </Box>
                            <Box sx={{ height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', }} >
                                <Typography sx={{ fontFamily: 'Futura-Medium', justifySelf: 'flex-start' }} >
                                    Prix Prestations
                                </Typography>
                            </Box>
                            <Box sx={{ height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', }} >
                                <Typography sx={{ fontFamily: 'Futura-Medium', justifySelf: 'flex-start' }} >
                                    Montant Total
                                </Typography>
                            </Box>
                        </Stack>

                        <Stack direction={'column'} alignItems={'center'} sx={{ width: '100%' }} divider={<Divider orientation={{ sx: "horizontal", sm: "horizontal", md: "vertical" }} flexItem />} >
                            <Box sx={{ height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', }} >
                                <Typography sx={{ fontFamily: 'Futura-Medium', justifySelf: 'flex-start' }} >
                                    {service?.title}
                                </Typography>
                            </Box>
                            <Box sx={{ height: 50, justifyContent: 'space-evenly', alignItems: 'center', display: 'flex', width: '100%' }} >
                                <Counter
                                    Count={hours}
                                    setCount={setCount}
                                />
                            </Box>
                            <Box sx={{ height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', }} >
                                <Typography sx={{ fontFamily: 'Futura-Medium', justifySelf: 'flex-start' }} >
                                    {5000 * nbhours} XAF
                                </Typography>
                            </Box>
                            <Box sx={{ height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', }} >
                                <Typography sx={{ fontFamily: 'Futura-Medium', justifySelf: 'flex-start' }} >
                                    5.000*{nbhours} = {5000 * nbhours} xAf
                                </Typography>
                            </Box>
                        </Stack>

                    </Stack>
                    <Typography sx={{ fontFamily: 'Futura-Medium', fontSize: 18, color: '#000', my: 2, textAlign: 'center', fontWeight: '600' }} >
                        Mode de Paiement
                    </Typography>
                    <Box onClick={payWithOrange} my={1} sx={{ border: '1px solid #0f4d61', width: '100%', display: 'flex', justifyContent: 'center', height: 56 }} >
                        <img src={orange_money} alt="orange money logo" style={{ height: 54, cursor: "pointer" }} />
                        {mnoName==="orange" && isLoading && <CircularProgress color="primary" />}
                    </Box>
                    <Box onClick={payWithMtn} my={1} sx={{ border: '1px solid #000', bgcolor: '#000', width: '100%', display: 'flex', justifyContent: 'center', height: 56 }} >
                        <img src={mobile_money} alt="mobile money logo" style={{ height: 44, cursor: "pointer" }} />
                        {mnoName==="mtn" && isLoading && <CircularProgress color="primary" />}

                    </Box>

                </CardComponent> :
                    <CardComponent title={'Payment'} >
                        <Box sx={{ height: 210, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%' }} >
                            <Typography sx={{ fontFamily: 'Futura-Medium' }} >
                                Combien d’heures de prestations voulez-vous ?
                            </Typography>
                            <Counter border
                                Count={hours}
                                setCount={setCount}
                            />
                        </Box>
                        <JButton  onClick={() => setSwitch(true)} style={{ borderRadius: 30, bgcolor: '#a6cc1f' }} title={"Suivant"} />
                    </CardComponent>
            }


        </Box >
    )
}

export default Payment