import { Grid } from '@mui/material'
import React from 'react'
import JobberItem from '../common/JobberItem'


const JobberItemLis = (props) => {
    const {jobbers } = props
    return (
        <Grid container justifyContent={"center"} flexDirection="row" alignItems={"center"} my={3} spacing={2} flexWrap="wrap" >
            {
                jobbers?.map((jobber, i) => (
                    <Grid container justifyContent={"center"} key={i} item xs={12} sm={12} md={6} lg={4} >
                        <JobberItem jobber={jobber}/>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default JobberItemLis