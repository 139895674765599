import { Box, Container, Grid, Stack, Typography, colors } from '@mui/material'
import React from 'react'
import './styles.css'
import img from '../../assets/images/sample.jpg'
import JButton from '../../components/common/Button'

const Blog = () => {
  return (
    <Box>
        <Container maxWidth = {'lg'} >
            <Typography sx = {{ fontSize: 22, fontWeight: '700', color: colors.common.black, my: 4 }} >
            Astuces et conseils pour le quotidien
            </Typography>

            <Box className = 'bg-here' display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} sx = {{ height: 410, borderRadius: 2, position: 'relative', mb: 4 }} >
                <Box sx = {{ bgcolor: '#00000080', bottom: 0, height: 110, px: 5 }} >
                <Typography sx={{ fontSize: 20, color: '#fff', fontWeight: '700', textAlign: 'center', mt: 3 }} >
                    Profitez de votre temps libre grâce à nos jobbers
                </Typography>
                <Typography sx={{ fontSize: 16, color: '#fff', fontWeight: '500',  }} >
                Mère célibataire, vous avez envie d'acheter une maison mais ne savez pas par où commencer ? Nous répondons à vos questions les plus fréquentes pour vous mettre sur la bonne piste !
                </Typography>
                </Box>
            </Box>
            <Stack direction = {{ sm: 'column', md: 'row' }} justifyContent={'space-between'} >
                <Box sx = {{ display: 'flex', bgcolor: 'white', width: 190, justifyContent: 'center', alignItems: 'center', border: '1px solid #00000030' }} >
                    <Typography color = {'primary'} sx = {{ fontSize: 18, fontWeight: '600' }} >
                        Ma Maison
                    </Typography>
                </Box>
                <Box sx = {{ display: 'flex', bgcolor: 'white', width: 190, justifyContent: 'center', alignItems: 'center', border: '1px solid #00000030' }} >
                    <Typography color = {'primary'} sx = {{ fontSize: 18, fontWeight: '600' }} >
                        Ma Maison
                    </Typography>
                </Box><Box sx = {{ display: 'flex', bgcolor: 'white', width: 190, justifyContent: 'center', alignItems: 'center', border: '1px solid #00000030' }} >
                    <Typography color = {'primary'} sx = {{ fontSize: 18, fontWeight: '600' }} >
                        Ma Maison
                    </Typography>
                </Box><Box sx = {{ display: 'flex', bgcolor: 'white', width: 190, justifyContent: 'center', alignItems: 'center', border: '1px solid #00000030' }} >
                    <Typography color = {'primary'} sx = {{ fontSize: 18, fontWeight: '600' }} >
                        Ma Maison
                    </Typography>
                </Box>
            </Stack>
            <Grid container mt={2} justifyContent={'space-between'} alignItems={'flex-start'} >
                    <Grid item xs={8} p={2} >
                        <Box sx = {{ width: '100%', bgcolor: '#fff', border: '1px solid #00000010', borderRadius: 2, mb: 2 }} >
                            <Stack direction={{ sm: 'column', md: 'row' }} >
                                <img style={{ height: 200, width: 200 }} src = {img} alt = 'sample image' />
                                <Box sx = {{ flex: 1, p: 1.5 }} >
                                <Typography color = {'#000000'} sx = {{ fontSize: 20, fontWeight: '700' }} >
                                    Besoin d'aide ? Trouvez quelqu'un pour vous aider
                                    </Typography>
                                    <Typography color = {'#00000070'} sx = {{ fontSize: 17, fontWeight: '500', mb: 2 }} >
                                        9 mars 2022
                                    </Typography>
                                    <Typography color = {'#333'} sx = {{ fontSize: 17, fontWeight: '500', mb: 2 }} >
                                    Tout le monde est confronté aux mauvaises odeurs dans les toilettes. Plutôt 
                                    que de vous orienter vers un désodorisant industriel, 
                                    pourquoi ne pas le fabriquer vous-même ? Simple, rapide et écologique, 
                                    un absorbeur d’odeur 
                                    naturel n’a rien à envier aux produits dans le commerce. De plus, 
                                    vous réduisez considérablement votre impact environnemental.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                        <Box sx = {{ width: '100%', bgcolor: '#fff', border: '1px solid #00000010', borderRadius: 2, mb: 2 }} >
                            <Stack direction={{ sm: 'column', md: 'row' }} >
                                <img style={{ height: 200, width: 200 }} src = {img} alt = 'sample image' />
                                <Box sx = {{ flex: 1, p: 1.5 }} >
                                <Typography color = {'#000000'} sx = {{ fontSize: 20, fontWeight: '700' }} >
                                    Besoin d'aide ? Trouvez quelqu'un pour vous aider
                                    </Typography>
                                    <Typography color = {'#00000070'} sx = {{ fontSize: 17, fontWeight: '500', mb: 2 }} >
                                        9 mars 2022
                                    </Typography>
                                    <Typography color = {'#333'} sx = {{ fontSize: 17, fontWeight: '500', mb: 2 }} >
                                    Tout le monde est confronté aux mauvaises odeurs dans les toilettes. Plutôt 
                                    que de vous orienter vers un désodorisant industriel, 
                                    pourquoi ne pas le fabriquer vous-même ? Simple, rapide et écologique, 
                                    un absorbeur d’odeur 
                                    naturel n’a rien à envier aux produits dans le commerce. De plus, 
                                    vous réduisez considérablement votre impact environnemental.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item container xs={3.5} p={1.2} >
                        <Box sx = {{ bgcolor: '#fff', padding: 3, border: '1px solid #00000010', borderRadius: 2, mb: 2 }} >
                        <Typography color = {'primary'} sx = {{ fontSize: 17, fontWeight: '600', textAlign: 'center', mb: 2 }} >
                        Les désodorisants naturels pour WC
                    </Typography>
                    <JButton title={'Demander un service'} />
                        </Box>
                        <Box sx = {{ bgcolor: '#fff', padding: 3, border: '1px solid #00000010', borderRadius: 2, mb: 2  }} >
                        <Typography color = {'secondary'} sx = {{ fontSize: 17, fontWeight: '600', textAlign: 'center', mb: 2 }} >
                        Réalisez des jobs et augmentez vos revenus
                    </Typography>
                    <JButton title={'Devenir prestataire'} />
                        </Box>
                    </Grid>
                </Grid>
        </Container>
    </Box>
  )
}

export default Blog