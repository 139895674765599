import React, { useEffect, useState } from 'react'
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, Sidebar, Search, ConversationHeader, ConversationList, Avatar, MessageSeparator, Conversation, TypingIndicator } from '@chatscope/chat-ui-kit-react';

import userIng from '../../assets/images/elizeu-dias-2EGNqazbAMk-unsplash.jpg'
import { useSelector } from 'react-redux';
import { useAllRoomsOfUserQuery, chatApi } from '../../app/services/chatApi';
import { getId } from '../../utilities';
import { connectSocket, sendTextMessage } from '../../app/services/socket';
import { dispatch } from '../../app';
import { handleRoomId, handleTheRoom } from '../../app/features/chatSlice';
import { Grid, Box } from '@mui/material';

/**
 * Chat Screen
 * @documentation Chat Components from https://chatscope.io/storybook/react/?path=/docs/documentation-introduction--page
 * TODO: Ulrich Bertrand lis la documentation voila le lien 
 */


const ChatDiscussion = () => {
    connectSocket()
    const [message, setMessage] = React.useState("")
    const { room, messages, roomId } = useSelector(state => state.chats)
    const sendMessage = () => {
        const toId = getId()=== room?.userLeft?.id ? room?.userRight?.id : room?.userLeft?.id
        const fromId = getId()
        sendTextMessage(room?.id, toId, fromId, message)
        setMessage("")
    }
        const { data: rooms } = useAllRoomsOfUserQuery()


    //     </Box>
    //     <Box sx={{ display: 'flex', flexDirection: 'row', margin: 2 }} >
    //         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: '#dedede80', height: 45, borderRadius: 3, px: 1.3, flex: 1, mr: 1 }} >
    //             <input type="text" name="input-bar" className='input-bar' />
    //             <IconButton aria-label="delete" size="large">
    //                 <CameraAltIcon color='inherit' sx={{ color: '#00000020' }} fontSize="inherit" />
    //             </IconButton>
    //         </Box>
    //         <div className='btn-send' >
    //             <IconButton aria-label="delete" size="large">
    //                 <TelegramIcon color='inherit' sx={{ color: '#fff' }} />
    //             </IconButton>
    //         </div>
    //     </Box>
    // </Box>


    // Set initial message input value to empty string                                                                     
    const [messageInputValue, setMessageInputValue] = React.useState("");
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [sidebarStyle, setSidebarStyle] = useState({});
    const [chatContainerStyle, setChatContainerStyle] = useState({});
    const [conversationContentStyle, setConversationContentStyle] = useState({});
    const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});
    const handleBackClick = () => setSidebarVisible(!sidebarVisible);

    useEffect(() => {
        if (sidebarVisible) {
            setSidebarStyle({
                display: "flex",
                flexBasis: "auto",
                width: "100%",
                maxWidth: "100%"
            });
            setConversationContentStyle({
                display: "flex"
            });
            setConversationAvatarStyle({
                marginRight: "1em"
            });
            setChatContainerStyle({
                display: "none"
            });
        } else {
            setSidebarStyle({});
            setConversationContentStyle({});
            setConversationAvatarStyle({});
            setChatContainerStyle({});
        }
    }, [sidebarVisible, setSidebarVisible, setConversationContentStyle, setConversationAvatarStyle, setSidebarStyle, setChatContainerStyle]);

    const handleMessagesRooms = (room) => {
        dispatch(handleTheRoom(room))
        dispatch(handleRoomId(room?.id))
        dispatch(chatApi.endpoints.AllMessageOfRoom.initiate(room?.id))
    }

    return <div style={{
        height: "100%",
        position: "relative"
    }}>
        <MainContainer responsive>
            <Sidebar position="right" scrollable={false} style={sidebarStyle}>
                <ConversationList>
                    {
                        rooms?.map((roomH, i) => (
                            <Conversation onClick={() => handleMessagesRooms(roomH)}>
                                {roomH?.userLeft?.id === getId() ?
                                    <Avatar src={roomH?.userRight?.profile !== null ? roomH?.userRight?.profile : userIng} name={`${roomH?.userRight?.username}`} status="available" style={conversationAvatarStyle} /> :
                                    <Avatar src={roomH?.userLeft?.profile !== null ? roomH?.userLeft?.profile : userIng} name={`${roomH?.userLeft?.username}`} status="available" style={conversationAvatarStyle} />
                                }
                                {
                                    roomH?.userLeft?.id === getId() ?
                                        <Conversation.Content name={`${roomH?.userRight?.username}`} lastSenderName="you" info="read" style={conversationContentStyle} /> :
                                        <Conversation.Content name={`${roomH?.userLeft?.username}`} lastSenderName={`${roomH?.userLeft?.username}`} info="read" style={conversationContentStyle} />
                                }
                            </Conversation>
                        ))
                    }
                </ConversationList>
            </Sidebar>
            <ChatContainer style={chatContainerStyle}>
               
                    {room !== null  ?
                        <ConversationHeader>
                       
                        <ConversationHeader.Back onClick={handleBackClick} />
                            <Avatar 
                                src={room?.userLeft?.id===getId() ? (room?.userRight?.profile !==null ? room?.userRight?.profile: userIng) : (room?.userLeft?.profile !==null ? room?.userLeft?.profile: userIng) } 
                                name={room?.userLeft?.id===getId() ? `${room?.userRight?.username}` : `${room?.userLeft?.username}` }
                            />
                            <ConversationHeader.Content userName={room?.userLeft?.id===getId() ? `${room?.userRight?.username}` : `${room?.userLeft?.username}` } info="Active" />
                        </ConversationHeader>
                        : <></>  }
               
                <MessageList>
                    {/* <MessageSeparator content="thursday, 15 July 2021" /> */}
                    {messages[roomId]?.lenght <= 0 || room === null ? 
                    <Grid xl={12} sx={{ position:"absolute", top: "50%", left:"30%", fontWeight: "900", fontSize: 25  }}> 
                    <Box>Cliquer sur un profil pour chatter ! </Box></Grid> :
                        messages[roomId]?.map((message, index) => (<>
                        {getId() === message?.to ?
                            <Message key={index+"message"} model={{
                                message: `${message?.message}`,
                                sentTime:`${new Date(message?.createdAt).toLocaleString()}`,
                                sender: "you",
                                direction: "incoming",
                                position: "single"
                            }} />
                            :
                            <Message key={index+"message"} model={{
                                message: `${message?.message}`,
                                sentTime:`${new Date(message?.createdAt).toLocaleString()}`,
                                sender: "",
                                direction: "incoming",
                                position: "single"
                            }} />                           
                        }
                        </>
                         ))
                    }
                </MessageList>
                <MessageInput 
                    placeholder="Type message here" value={message}
                    onChange={val => setMessage(val)} 
                    onSend={() => sendMessage()} 
                    />
            </ChatContainer>
        </MainContainer>
    </div>;

}

export default ChatDiscussion