import { Box, Checkbox, Container, FormControlLabel, FormGroup, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { baseUrl, ENDPOINTS } from '../../app/services'
import JButton from '../../components/common/Button'
import UploadPhoto from '../../components/common/UploadPhoto'
import { DOC_TYPE } from '../../router'
import { getId, getPhoto } from '../../utilities'

const ProfilePhoto = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [Img, setImg] = useState(null)
    const handlePhoto = () => {
        const data = new FormData()
        data.append("file", getPhoto())
        setIsLoading(true)
        fetch(`${baseUrl}${ENDPOINTS?.USER_PHOTO}/${getId()}`, {
            method: "POST",
            body: data
        }).then((res) => {
            if (res.ok) {
                setIsLoading(false)
                window.location.href = `${DOC_TYPE}`
            } else if (res.status == 401) {
                setIsLoading(false)
            }
        }, (e) => {
            setIsLoading(false)
        });
    }

    return (
        <Box className='container' >
            <Container maxWidth='sm' >
                <Typography sx={{ fontSize: 22, fontWeight: '800', fontFamily: 'catamaran', color: '#333' }} >
                    Compétences
                </Typography>
                <UploadPhoto setImg={setImg} Img={Img} />
                <Typography sx={{ fontSize: 18, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
                    Votre photo doit respecter ces conditions
                </Typography>
                <FormGroup>
                    <FormControlLabel sx={{ fontSize: 15, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} control={<Checkbox defaultChecked disabled />} label="Visage clairement visible" />
                    <FormControlLabel control={<Checkbox defaultChecked checked={true} disabled />} label="Photo nette" />
                    <FormControlLabel control={<Checkbox defaultChecked disabled color='primary' />} label="Aucun filtre modifiant le visage" />
                    <FormControlLabel control={<Checkbox defaultChecked disabled />} label="Seul(e) sur la photo" />
                    <FormControlLabel control={<Checkbox defaultChecked disabled />} label="Aucun accessoire masquant le vissge" />
                </FormGroup>
                <JButton loading={isLoading} title={"Continuer"} onClick={handlePhoto} />
            </Container>
        </Box>
    )
}

export default ProfilePhoto