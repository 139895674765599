import React from 'react'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Grid, Stack } from '@mui/material';
import HomeService from '../common/HomeService';

import user from '../../assets/images/bxs-user-check.svg'
import shield from '../../assets/images/shield-check.svg'
import money from '../../assets/images/money.svg'
import phone from '../../assets/images/telephone.svg'
import ProfileItem from '../common/ProfileItem';
import SwitchItem from '../common/SwitchItem';
import { useSelector } from 'react-redux';

const bar_data = [
    { title: "Plomberie", },
    { title: "Électricité" },
    { title: "Maçonnerie" },
    { title: "Charpanterie" },
    { title: "Informatique" },
]



const CompetenceUpdateList = () => {
    const { profile } = useSelector(state => state.auth)
    return (
        <Stack direction={"row"} flexWrap={"wrap"} >
            {
                profile?.services?.map((service, i) => (<SwitchItem key={i} title={service?.service?.title} />))
            }
        </Stack>
    )
}

export default CompetenceUpdateList