import { getUser } from "../../utilities";


const io  = require("socket.io-client");
// var token = getToken()
export const eventMessage = 'messageText'
const eventImage = 'private image'
const eventAudio = 'private audio'
const username = getUser()?.username
export var handle = false

console.log(process.env.REACT_APP_API_URL_SOCKET)
export const socket = io(""+process.env.REACT_APP_API_URL_SOCKET, {
    transports: ["websocket"],
    // reconnectionDelayMax: 5000,
    autoConnect: false,
    // reconnection: true,
    // reconnectionAttempts: maxRetries + maxRetries,
    // auth: { accessToken: token },
})

socket.on("error", (error) => {
    console.error("socket error", error)
})
    .on("connect_error", (e) => {
        // token = getToken()
        console.log("connect_error", e)
    })
    .on("connect", () => {
        console.log("connected", socket.connected)
    })
    .on(username, (args) => {
        console.log("Event text message", args)
        // const {to, from} = args
        // dispatch(handleAddMessageHistory(args))
        // handle=true
    })
    ;

export function connectSocket() {
    socket.connect()
}

export function sendTextMessage(roomId,toId, fromId, message){
    console.log("Text message", {toId, fromId, message})
    socket.emit(eventMessage, {toId, fromId, message})
}

export function sendImageMessage(to, file){
    socket.emit(eventImage, {to}, file)
}

export function sendAudioMessage(to, file){
    socket.emit(eventAudio, {to}, file)
}
