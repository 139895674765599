import { styled } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react'


const CustomButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    height: 44,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#fff',
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
}));



const JButton = ({ loading, onClick, title, style, icon }) => {
    return (
        <CustomButton loading={loading} onClick={onClick} sx={[{ textTransform: 'none', fontWeight: '600', width: '100%' }, style]}  >
            {icon && icon}{title}
        </CustomButton>
    )
}

export default JButton