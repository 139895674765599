import { Button, colors, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Input from '../../components/Input';
import JButton from '../../components/common/Button';
import { useVerifyOtpMutation } from '../../app/services/authApi';
import { Controller, useForm } from 'react-hook-form';
import { HOME } from '../../router';
import { dispatch } from '../../app';
import { handleOpenMoadalLayout } from '../../app/features/authSlice';
import ResendOTP from './ResendOTP';

const ConfirmOTPCode = () => {
    const [verifyOtp , {isLoading, error, isSuccess}] = useVerifyOtpMutation()
    const [open, setOpen] = React.useState(false)
    const { control, handleSubmit, formState: { errors } } = useForm({ defaultValues: { code: "", email: "" } });
    const confirmAccount = (data) => {
        verifyOtp(data).unwrap().then(() => {
            dispatch(handleOpenMoadalLayout(true))
        })
    }
    const resend =() => window.location.href = HOME

    return (
        <Container maxWidth="sm" sx={{ marginY: 5}}>
            <Stack spacing={2} >
                <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '700', fontSize: 40 }} >
                    Confirmer votre compte
                </Typography>
                <Controller
                    control={control}
                    rules={{
                        required: true
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            placeholder={"your email"}
                            type="email"
                            leftIcon={<EmailOutlinedIcon color={colors.grey[500]} />}
                            onChange={onChange}
                            value={value}
                        />
                    )}
                    name="email"
                />
                {errors.email && (<span style={{ color: "red" }}>password is required.</span>)}
                <Controller
                    control={control}
                    rules={{
                        required: true
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            placeholder={"your code.."}
                            type={'text'}
                            leftIcon={<LockOutlinedIcon color={colors.grey[500]} />}
                            onChange={onChange}
                            value={value}
                        />
                    )}
                    name="code"
                />
                {errors.code && (<span style={{ color: "red" }}> {"Code are required"}</span>)}
                {isSuccess && (<span style={{ color: "blue" }}> {"Compte valider avec succes"}</span>)}
                {error && (<span style={{ color: "red" }}>{error.data.message}</span>)}
                <JButton title={"Confirmer"} loading={isLoading} onClick={handleSubmit(confirmAccount)} />

                <Stack
                    direction={'row'}
                    justifyContent='right'
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Button sx={{ textTransform: 'none', fontWeight: '400', fontSize: 14 }} onClick={() => setOpen(true)} >code expire ?</Button>
                </Stack>
            </Stack>
            <ResendOTP openModal={open} setOpenModal={setOpen} /> 
        </Container>
    )
}

export default ConfirmOTPCode