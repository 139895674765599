import { Box, Container } from '@mui/material'
import React from 'react'
import JobberSignUpForm from '../../components/jobberSignUpForm'
import './styles.css'

const Jobber = () => {
    return (
        <Box>
            <Container maxWidth="md" >
                <JobberSignUpForm />
            </Container>
        </Box>
    )
}

export default Jobber