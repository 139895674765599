import { AppBar, Box, Container, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
// import Logo from '../Logo'
import logo from '../../../assets/images/logonew.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './styles.css'
import JButton from '../Button';
import { handleOpenMenuDialogLayout, handleOpenMoadalLayout } from '../../../app/features/authSlice';
import { dispatch } from '../../../app';
import { Link } from 'react-router-dom';
import { BLOG, COMPRENDRE_JOBBING, CONSEIL_JOBBING, INVITER_AMIS, JOBBER_PRO, OFFERS_JOBBER, PRESTATAIRE } from '../../../router';

const Footer = () => {
    const setOpenModal = (bool) => dispatch(handleOpenMoadalLayout(bool))
    const setOpenMenu = (bool) => dispatch(handleOpenMenuDialogLayout(bool)) 

    return (
        <AppBar position="relative" color="primary" sx={{ top: 'auto', bottom: 0, mt: 10 }}>
            <Box>
                <Container maxWidth={"md"} sx={{ my: 4 }} >
                    <Stack direction={{ sm: 'column', md: 'row' }} alignItems={'flex-start'} justifyContent={'space-around'} >
                             <Box sx={{ alignItems:'flex-start',justifyContent:'flex-start'}}>
                                <img alt={""} src={logo} style={{width:150 }} />
                            </Box>
                        <Box>
                            {/* <Logo /> */}                  
                            <Typography sx={{ color: '#a6cc1f', fontFamily: 'Futura-Medium', fontSize: 18, mb: 1, fontWeight: '600' }} >
                                Accéder à mon compte
                            </Typography>
                            <Stack direction={'row'} alignItems={'center'} spacing={2} mb={1} onClick={() => setOpenModal(true)} >
                                <Typography sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '600', cursor: "pointer" }} >
                                    S'identifier
                                </Typography>
                                <ArrowForwardIcon fontSize='small' color='inherit' sx = {{ color: 'white' }} />
                            </Stack>
                            <Typography sx={{ color: '#000', fontFamily: 'Futura-Medium', fontSize: 18, mb: 1, fontWeight: '600' }} >
                                Des travaux à réaliser
                            </Typography>
                            <JButton title={'Rechercher un service'} style={{ bgcolor: '#a6cc1f' }} onClick={() => setOpenMenu(true)} />
                        </Box>
                        <Box>
                            <Typography sx={{ color: '#a6cc1f', fontFamily: 'Futura-Medium', fontSize: 18, mb: 2, fontWeight: '600' }} >
                                Découvrir
                            </Typography>
                            <Link className='links' to={OFFERS_JOBBER} >
                            <Typography sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 14, mb: 1, fontWeight: '600' }} >
                                Trouver un job
                            </Typography>
                            </Link>
                            <Link className='links' to={PRESTATAIRE} >             
                                <Typography className='link' sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 14, mb: 1, fontWeight: '600' }} >
                                    Devenir prestataire
                                </Typography>
                            </Link>
                            <Link className='links' to={BLOG} >
                            <Typography sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 14, mb: 1, fontWeight: '600' }} >
                            Blog
                            </Typography>
                            </Link>
                            <Link className='links' to={INVITER_AMIS} >
                            <Typography sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 14, mb: 1, fontWeight: '600' }} >
                            Inviter des amis
                            </Typography>
                            </Link>
                            {/* <Typography className='link' sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 14, mb: 1, fontWeight: '600' }} >
                                Nous contacter
                            </Typography> */}
                        </Box>
                        <Box>
                            <Typography sx={{ color: '#a6cc1f', fontFamily: 'Futura-Medium', fontSize: 18, mb: 2, fontWeight: '600' }} >
                                Information Utile
                            </Typography>
                            <Link className='links' to={COMPRENDRE_JOBBING}>
                            <Typography sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 14, mb: 1, fontWeight: '600' }} >
                                Tout comprendre sur le jobbing
                            </Typography>
                            </Link>
                            <Link className='links' to={JOBBER_PRO} >
                            <Typography sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 14, mb: 1, fontWeight: '600' }} >
                                Qu’est ce qu’un jobber PRO ?
                            </Typography>
                            </Link>
                            <Link className='links' to={CONSEIL_JOBBING} >
                            <Typography sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 14, mb: 1, fontWeight: '600' }} >
                                Conseils Jobbing
                            </Typography>
                            </Link>
                            
                        </Box>
                    </Stack>
                </Container>
                <Divider sx={{ backgroundColor: '#f5f5f5', my: 2 }} />
                <Stack width={'100%'} justifyContent={'center'} alignItems={'center'} direction={'column'} >
                    <Typography sx={{ color: '#a6cc1f', fontFamily: 'Futura-Medium', fontSize: 18, mb: 2, fontWeight: '600', textAlign: 'center' }} >
                        Suivez - nous
                    </Typography>
                    <Stack direction={'row'} spacing={1.3} width={'100%'} justifyContent={'center'} >
                     <Link className='link'>
                     <FacebookIcon color='inherit' sx = {{ color: 'white' }} fontSize='small' />
                     </Link> 
                     <Link>
                     <InstagramIcon color='inherit' sx = {{ color: 'white' }} fontSize='small' />
                     </Link>
                     <Link>
                     <TwitterIcon color='inherit' sx = {{ color: 'white' }} fontSize='small' />
                     </Link>                     
                    </Stack>
                    <Typography sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 14, mb: 1, fontWeight: '600' }} >
                        © jejobtout 2023
                    </Typography>
                    <Typography sx={{ color: '#FFFFFF', fontFamily: 'Futura-Medium', fontSize: 14, mb: 1, fontWeight: '600' }} >
                      developed by ekosmartafrica.com
                    </Typography>
                </Stack>
                <Box width={'100%'} bgcolor={'#0f4d61'} height={55} ></Box>
            </Box>
        </AppBar>
    )
}

export default Footer