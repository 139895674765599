import { Box, Container, Divider, Stack } from '@mui/material'
import React, { useState } from 'react'
import EditForm from '../../components/EditForm'
import UpdateNICModal from '../../components/UpdateNICModal'
import UploadNIC from '../../components/UploadNIC'

const EditProfile = () => {
    const [OpenModal, setOpenModal] = useState(false)
    const [Img, setImg] = useState(null)
    const [Img2, setImg2] = useState(null)

    return (
        <Box>
            <Container maxWidth={"xl"} >
                <Stack divider={<Divider orientation="vertical" flexItem />} direction={{ sm: 'column', md: 'row' }} justifyContent={"space-around"} spacing={2} >
                    <EditForm />
                    <UploadNIC Img={Img} Img2={Img2} setImg={setImg} setImg2={setImg2} setOpen={setOpenModal} />
                </Stack>
            </Container>
            <UpdateNICModal open={OpenModal} setOpen={setOpenModal} />
        </Box>
    )
}

export default EditProfile