import { Box, LinearProgress, Typography } from '@mui/material'
import { Container } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { baseUrl, ENDPOINTS } from '../../app/services'
import JButton from '../../components/common/Button'
import TipsInfo from '../../components/common/TipsInfo'
import UploadPhoto from '../../components/common/UploadPhoto'
import { HOME } from '../../router'
import { getId, getPhoto } from '../../utilities'

const DocsDetails = () => {
    const [isLoading, setIsLoading] = useState(false)
    const handlePhotoCni = () => {
        const data = new FormData()
        data.append("recto", getPhoto())
        data.append("verso", getPhoto())
        setIsLoading(true)

        fetch(`${baseUrl}${ENDPOINTS.USER_CNI_PHOTO}/${getId()}`, {
            method: "POST",
            body: data
        }).then((res) => {
            console.log("response: ", res)
            if (res.ok) {
                setIsLoading(false)
                window.location.href = `${HOME}`
            } else if (res.status == 401) {
                setIsLoading(false)
            }
        }, (e) => {
            setIsLoading(false)

        });
    }

    const [Img1, setImg1] = useState(null)
    const [Img2, setImg2] = useState(null)
    return (
        <Box className='container' >
            <Container maxWidth='sm' >
                <Typography
                    sx={{ fontSize: 22, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
                    Télécharger les images de vos documents
                </Typography>
                <TipsInfo text={"Pour pouvoir travailler sur le territoire nous vous prions de bien vouloir mettre à jour vos documents"} />
                <Typography sx={{ fontSize: 18, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
                    Carte d'identité recto
                </Typography>
                <UploadPhoto Img={Img1} setImg={setImg1} />
                <Typography sx={{ fontSize: 18, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
                    Carte d'identité verso
                </Typography>
                <UploadPhoto Img={Img2} setImg={setImg2} />

                {isLoading ? <LinearProgress color='primary' />
                    : <JButton title={"Terminer"} onClick={handlePhotoCni} />}
            </Container>
        </Box>
    )
}

export default DocsDetails