import { Alert, Box, colors, Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, LinearProgress, MenuItem, Select, Stack, Switch, Typography } from '@mui/material'
import React, { useState } from 'react'
import JButton from '../common/Button'
import Input from '../Input'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FaceButton from '../common/FacebookBtn';
import { useNavigation } from 'react-router';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSignInMutation, useSignUpJobberMutation } from '../../app/services/authApi';
import { useFindAllCountriesQuery } from '../../app/services/countryApi';
import { CheckCircleOutline } from '@mui/icons-material';
import { COMPETENCE } from '../../router';
import { dispatch } from '../../app';
import { setAuth } from '../../app/features/authSlice';
import DatePicker from "react-datepicker";

const JobberSignUpForm = () => {
    const [showpwd, setShowpwd] = useState(false)
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [username, setUsername] = useState("")
    const [country_id, setCountryId] = useState(1)
    const [birthDate, setBirthDate] = React.useState(new Date())
    const [sex, setSex] = React.useState("M")
    const [phone, setPhone] = React.useState(0)
    const date = (value) =>{ 
        setBirthDate(value);
    }
    const handleSex = (e) => {
        setSex(e.target.value);
      };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { handleSubmit, control, formState: { errors } } = useForm({ defaultValues: { password: '', username: '', } })

    const [signUpJobber, { isLoading, isSuccess: isSuccessSignUp }] = useSignUpJobberMutation()
    const [signIn, { isLoading: isLoadingLoggin }] = useSignInMutation()
  
    const handleSignUpJobber = () => {
        const t = new Date(new Date(birthDate).getTime()).toJSON()

        const data = {
            firstname,
            lastname,
            email,
            address,
            username,
            password,
            country_id,
            sex, 
            birthDate:t,
            phone
        }
        signUpJobber(data).unwrap().then((resp) => {
            console.log(resp)
            const { username, password } = data
            signIn({ username, password }).unwrap().then((resp) => {
                if (resp?.accessToken) {
                    dispatch(setAuth(resp))
                    window.location.href = `${COMPETENCE}`
                }
            })
        })
    }

    const { data: countries } = useFindAllCountriesQuery()
    return (
        <Box>
            <Stack spacing={2} >
                <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '700', fontSize: 30 }} >
                    Créer un compte!
                </Typography>
                <Typography variant='inherit' sx={{ fontSize: 18, color: '#333' }} >
                    Profitez de votre temps libre grâce à nos jobbers
                </Typography>
                <FaceButton />
                <Divider>OU</Divider>
                {/* <form
                    // className='reg-form'
                    style={{ width: '100%' }}
                    onSubmit={handleSubmit(handleSignUpJobber)}
                > */}
                <Input
                    placeholder={"Nom"}
                    type="text"
                    leftIcon={<Person2OutlinedIcon color={colors.grey[500]} />}
                    name="firstname" id="firstname"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                />
                <Input
                    placeholder={"Prénom"}
                    type="text"
                    leftIcon={<PersonOutlineOutlinedIcon color={colors.grey[500]} />}
                    name="lastname"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                />
                 <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sexe</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sex}
                    label="Sex"
                    onChange={handleSex}
                >
                    <MenuItem value={"F"}>Femme</MenuItem>
                    <MenuItem value={"M"}>Homme</MenuItem>
                </Select>
                </FormControl>
                <label for="text">Date de naissance</label>
                <DatePicker  id = {"date"} className='custom-input' selected={birthDate} onChange={date} />
                <Input
                    placeholder={"TelePhone"}
                    type="number"
                    leftIcon={<PhoneIphoneOutlinedIcon  color={colors.grey[500]} />}
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={country_id}
                    label="country"
                    onChange={(e) => setCountryId(e.target.value)}
                >
                    <MenuItem value=""> <em>Country...</em></MenuItem>
                    {countries?.map(country => (<MenuItem key={country?.code} value={country?.id}>{country?.name}</MenuItem>))}
                </Select>
                <Input
                    placeholder={"Adresse"}
                    type="text"
                    leftIcon={<LocationCityOutlinedIcon color={colors.grey[500]} />}
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <Input
                    placeholder={"Nom d'utilisateur"}
                    type="text"
                    leftIcon={<Person3OutlinedIcon color={colors.grey[500]} />}
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                 <Input
                    placeholder={"Email"}
                    type="email"
                    name="email"
                    leftIcon={<EmailOutlinedIcon color={colors.grey[500]} />}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />            
                <Input
                    placeholder={"Mot de passe"}
                    type={showpwd ? 'text' : 'password'}
                    leftIcon={<LockOutlinedIcon color={colors.grey[500]} />}
                    name="password"
                    value={password}

                    rightIcon={
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowpwd(!showpwd)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showpwd ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    }
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Stack
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems='flex-start'
                    justifyContent={"flex-start"}
                    spacing={0}
                >
                    <FormControlLabel sx={{ color: colors.grey[600], alignSelf: 'center' }} control={<Switch defaultChecked />} label="Je souhaite être informé des nouveautés, cadeaux et bons plans" />
                </Stack>
                {isSuccessSignUp && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert variant='filled'
                        iconMapping={{
                            success: <CheckCircleOutline fontSize="inherit" />
                        }}>
                        Success registration... Automatic proccess Loggin
                    </Alert>
                </Stack>}
                {/* <Link to={"/comp"} > */}
                {(isLoading || isLoadingLoggin) ? <LinearProgress color="secondary" /> :
                    <JButton title={"Créer mon compte"} onClick={handleSignUpJobber} />
                }
                {/* </Link> */}

                <Typography variant='inherit' >
                    En vous inscrivant vous acceptez les <span style={{ color: '#507B92' }} >conditions générales</span> et la <span style={{ color: '#507B92' }}>politique de confidentialité</span>
                </Typography>
                {/* </form> */}
            </Stack>
        </Box>
    )
}

export default JobberSignUpForm