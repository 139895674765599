import { Box, IconButton } from '@mui/material'
import React, { useRef } from 'react'
import AddIcon from '@mui/icons-material/Add'
import './styles.css'
import { setPhoto } from '../../../utilities';

const UploadPhoto = ({ setImg, Img }) => {
    const inputRef = useRef()
    const handleChangeImage = e => {
        setImg({ [e.target.name]: URL.createObjectURL(e.target.files[0]) })
        setPhoto(e.target.files[0])
    }
    const upload = () => {
        inputRef.current.click()
    }
    return (
        <Box>
            {
                Img != null ? <>
                    <img className='img-box' src={Img.img} /> 
                    <IconButton onClick={upload} color="primary" aria-label="upload">
                        <AddIcon />
                    </IconButton>
                </> : 
                <div onClick={upload} className='row-flex dashed-box' >
                    <AddIcon />
                </div>
            }
            <input type="file" ref={inputRef} style={{ display: 'none' }} name="img" accept="image/*" onChange={handleChangeImage} />
        </Box>
    )
}

export default UploadPhoto