import { createSlice } from "@reduxjs/toolkit";
import { getId } from "../../utilities";

const initialState = {
    emitOffer: {
        userId: getId(),
        blocked: false,
        amount: 10000,
        serviceId: 1,
    },
    offers: [],
    offersFiltered: [],
    serviceId: null,
    page: 0,
    size: 10,
}

const offerSlice = createSlice({
    name: "offers",
    initialState,
    reducers: {
        handleDescriptionTitleOffer(state, action) {
            const { title, description } = action.payload
            state.emitOffer.description = description
            state.emitOffer.title = title
        },
        handlePriceOffer(state, action) {
            state.emitOffer.amount = parseFloat(action.payload)
        },
        handleExpiredDateOffer(state, action) {
            state.emitOffer.expiredAt = action.payload
        },
        handleServiceIdOffer(state, action) {
            state.emitOffer.serviceId = parseInt(action.payload)
        },
        handleOffers(state, action) {
            const offers = action.payload
            state.offers =offers
            state.offersFiltered = offers
        },
        handleServiceId(state, action) {
            state.serviceId = action.payload
        },
        handlePriceOffers(state, action) {
            const {rPrice, lPrice} = action.payload
            let offers = state.offers?.filter(offer => offer.amount>=lPrice && offer.amount<=rPrice)
            state.offersFiltered = offers
        },
        handlePage(state, action){
            state.page = action.payload
        },
    },
})

export const {
    handleDescriptionTitleOffer,
    handlePriceOffer,
    handleExpiredDateOffer,
    handleServiceIdOffer,
    handleOffers,
    handleServiceId,
    handlePriceOffers,
    handlePage,

} = offerSlice.actions

export default offerSlice