import { Box, Container, Stack } from '@mui/material'
import React, { useState } from 'react'
import DateInput from '../../../components/common/DateInput'
import TimeItem from '../../../components/common/TimeItem'

// const time_data = [
//     "07:00",
//     "08:00",
//     "09:00",
//     "10:00",
//     "11:00",
//     "12:00",
//     "13:00",
//     "14:00",
//     "15:00",
//     "16:00",
//     "17:00",
//     "18:00",
//     "19:00",
//     "20:00",
//     "21:00",
// ]

const DelaisOffre = () => {
    // const [ActiveIndex, setActiveIndex] = useState(undefined)
    // const handleChange = (i) => {
    //     setActiveIndex(i)
    // }
    return (
        <Box>
            <Container maxWidth='sm' >
                <DateInput />
                {/* <Stack my={2} display={"flex"} flexDirection={"row"} flexWrap={"wrap"} alignItems={"center"} justifyContent={"center"} >
                    {
                        time_data.map((time, i) => (<TimeItem time={time} index={i} activeIndex={ActiveIndex} onClick={() => handleChange(i)} />))
                    }
                </Stack> */}
            </Container>
        </Box>
    )
}

export default DelaisOffre