import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSelectedServices } from '../../app/features/authSlice'
import JButton from '../../components/common/Button'
import { HOME, JOB_AMOUNT } from '../../router'
import { saveItem, toString } from '../../utilities'
import CheckListItem from './CheckListItem'

const Competences = () => {
    const { mainServices, allServices, selected, jobberId } = useSelector(state => state.auth)
    if(jobberId===null) window.location.href = `${HOME}`
    const dispatch = useDispatch()
    const handleSelected = (service, index) => {
        if (service?.category_id !== null) dispatch(handleSelectedServices({ service, index }))
    }
    const handleValidate = () => {
        saveItem("selected", toString(selected));
        window.location.href = `${JOB_AMOUNT}`
    }

    return (
        <Box>
            <Container maxWidth='sm' >
                <Typography sx={{ fontSize: 22, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
                    Compétences
                </Typography>
                {
                    mainServices?.map((service, index) => {
                        return (
                            <React.Fragment>
                                <Typography sx={{ fontSize: 18, fontWeight: '600', fontFamily: 'catamaran', color: '#333' }} >
                                    {service?.title}
                                </Typography>
                                {
                                    allServices.filter(s => s?.parent_id === service?.id)?.map((item, i) => (
                                        <CheckListItem onClick={() => handleSelected(item, i)} id={item?.id} title={item?.title} index={i} key={i} />
                                    ))
                                }
                            </React.Fragment>
                        )
                    })
                }
                <JButton title={"Continuer"} onClick={handleValidate} />
            </Container>
        </Box>
    )
}

export default Competences