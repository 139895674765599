import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import './styles.css'

import { useSelector } from 'react-redux'

const UploadNICModal = ({ setOpen, hide, }) => {
    const [Img, setImg] = useState(null)
    const [Img2, setImg2] = useState(null)

    const handleChangeImage = e => {
        setImg({ [e.target.name]: URL.createObjectURL(e.target.files[0]) })
    }
    const handleChangeImage2 = e => {
        setImg2({ [e.target.name]: URL.createObjectURL(e.target.files[0]) })
    }
    const upload = () => {
        document.getElementById("imgUpdate").click()
    }
    const upload2 = () => {
        document.getElementById("imgUpdate2").click()
    }
    const { profile } = useSelector(state => state.auth)

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
            <Stack direction={"row"} width='100%' alignItems={"center"} >
                {
                    hide ? null :
                        <React.Fragment>
                            <div style={{ flexGrow: 1 }} >
                                <Typography sx={{ color: '#00000070', fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '540' }} >
                                    Jours de prestations dd
                                </Typography>
                            </div>
                            <Button onClick={() => setOpen(true)} sx={{ textTransform: 'none', fontSize: 18, height: 45 }} >
                                Modifier
                            </Button>
                        </React.Fragment>
                }
            </Stack>
            <Typography sx={{ color: '#00000070', fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '540' }} >
                Recto de la carte d’identité
            </Typography>
            {
                profile?.infos?.photoCni?.rectoUrl != null ?
                    <img className='img-box' src={profile?.infos?.photoCni?.rectoUrl} /> : Img === null ?
                        <div onClick={upload} className='dashed-box_2' >
                            <Typography sx={{ color: '#00000050', fontFamily: 'Futura-Medium', fontSize: 15, fontWeight: '540' }} >
                                Verification d’identité
                            </Typography>
                            <Typography sx={{ color: '#00000030', fontFamily: 'Futura-Medium', fontSize: 15, fontWeight: '540' }} >
                                Appuyer pour télécharger ou prendre une photo
                            </Typography>
                        </div> :
                        <img className='img-box' src={Img.img} alt="upload logo" />

            }
            <input type="file" id="imgUpdate" name="img" accept="image/*" onChange={handleChangeImage} />
            <Typography sx={{ color: '#00000070', fontFamily: 'Futura-Medium', fontSize: 16, fontWeight: '540' }} >
                Verso de la carte d’identité
            </Typography>
            {
                profile?.infos?.photoCni?.versoUrl != null ?
                    <img className='img-box' src={profile?.infos?.photoCni?.versoUrl} /> : Img2 === null ?
                        <div onClick={upload2} className='dashed-box_2' >
                            <Typography sx={{ color: '#00000050', fontFamily: 'Futura-Medium', fontSize: 15, fontWeight: '540' }} >
                                Verification d’identité
                            </Typography>
                            <Typography sx={{ color: '#00000030', fontFamily: 'Futura-Medium', fontSize: 15, fontWeight: '540' }} >
                                Appuyer pour télécharger ou prendre une photo
                            </Typography>
                        </div> : <img className='img-box' src={Img2.img} alt="upload logo" />
            }
            <input type="file" id="imgUpdate2" name="img" accept="image/*" onChange={handleChangeImage2} />

        </Box>
    )
}

export default UploadNICModal