import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Divider, IconButton, Select, Stack, TableFooter, Typography } from '@mui/material';
import FilterSelect from '../FilterSelect';
import { StyledTableCell, StyledTableRow } from './styles';
import { Person } from '@mui/icons-material';

function createData(ordres, nom, service, pay_method, amount, presta_time, tel, date) {
    return { ordres, nom, service, pay_method, amount, presta_time, tel, date };
}

const rows = [
    createData(<Person color='inherit' sx={{ color: '#9A9A9A' }} />, 'Germain Yonta Tchoumi', "Installation d'un robinet", "MoMo", 15000, "45Min", '677756748', <MoreHorizIcon color='inherit' sx={{ color: '#9A9A9A' }} />),
    createData(<Person color='inherit' sx={{ color: '#9A9A9A' }} />, 'Germain Yonta Tchoumi', "Installation d'un robinet", "MoMo", 15000, "45Min", '677756748', <MoreHorizIcon color='inherit' sx={{ color: '#9A9A9A' }} />),
    createData(<Person color='inherit' sx={{ color: '#9A9A9A' }} />, 'Germain Yonta Tchoumi', "Installation d'un robinet", "MoMo", 15000, "45Min", '677756748', <MoreHorizIcon color='inherit' sx={{ color: '#9A9A9A' }} />),
    createData(<Person color='inherit' sx={{ color: '#9A9A9A' }} />, 'Germain Yonta Tchoumi', "Installation d'un robinet", "MoMo", 15000, "45Min", '677756748', <MoreHorizIcon color='inherit' sx={{ color: '#9A9A9A' }} />),
    createData(<Person color='inherit' sx={{ color: '#9A9A9A' }} />, 'Germain Yonta Tchoumi', "Installation d'un robinet", "MoMo", 15000, "45Min", '677756748', <MoreHorizIcon color='inherit' sx={{ color: '#9A9A9A' }} />),
    createData(<Person color='inherit' sx={{ color: '#9A9A9A' }} />, 'Germain Yonta Tchoumi', "Installation d'un robinet", "MoMo", 15000, "45Min", '677756748', <MoreHorizIcon color='inherit' sx={{ color: '#9A9A9A' }} />),
    createData(<Person color='inherit' sx={{ color: '#9A9A9A' }} />, 'Germain Yonta Tchoumi', "Installation d'un robinet", "MoMo", 15000, "45Min", '677756748', <MoreHorizIcon color='inherit' sx={{ color: '#9A9A9A' }} />),

];

export default function TransactionTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center" >Orders</StyledTableCell>
                        <StyledTableCell align="center">Nom</StyledTableCell>
                        <StyledTableCell align="center">Service</StyledTableCell>
                        <StyledTableCell align="center">Mode de paiement</StyledTableCell>
                        <StyledTableCell align="center">Montant</StyledTableCell>
                        <StyledTableCell align="center">Durée de prestation</StyledTableCell>
                        <StyledTableCell align="center">Telephone</StyledTableCell>
                        <StyledTableCell align="center">Date</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow align="center" key={row.name}>
                            <StyledTableCell align="center" >
                                {row.ordres}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.nom}</StyledTableCell>
                            <StyledTableCell align="center">{row.service}</StyledTableCell>
                            <StyledTableCell align="center">{row.pay_method}</StyledTableCell>
                            <StyledTableCell align="center">{row.amount}</StyledTableCell>
                            <StyledTableCell align="center">{row.presta_time}</StyledTableCell>
                            <StyledTableCell align="center">{row.tel}</StyledTableCell>
                            <StyledTableCell align="center">{row.date}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <Stack direction={'row'} justifyContent={'flex-end'} >
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}