export const HOME = "/";

export const INSCRIPTION = "/inscription";
export const JOBBER = "/jobber";
export const COMPETENCE = "/comp";
export const DISPONIBILITE = "/dispo";
export const MAP = "/map";
export const PROFILE_PHOTO = "/photo";
export const DOC_TYPE = "/doctype";
export const DOC_DETAILS = "/docdetails";
export const COMPLETE_BOOKING = "/completedBooking";
export const PRESTATION_ADDRESS = "/prestationAddress";
export const PRESTATION_DAYS = "/jourPrestation";
export const PROFILE = "/profile";
export const PRESTATAIRE = "/prestataire";
export const SETTINGS = "/settings";
export const EDIT_PROFILE = "/edit-profile";
export const JOB_AMOUNT = "/jobAmount";
export const HOURS_PRESTATION = "/heurePrestation";
export const DEMANDES = "/mes-demandes";
export const INVITER_AMIS = "/parrainage";
export const COMPRENDRE_JOBBING = "/jobbing";
export const JOBBER_PRO = "/jobberPro";
export const CONSEIL_JOBBING = "/conseilJobbing";

export const CHAT = "/chat/with";
export const BLOG = "/blog";
export const OFFERS = "/offres/news";
export const OFFERS_JOBBER = "/offresJobber";

export const DEMANDE_SERVICE_ADDRESS = "/indexPage";

export const PAYMENT = "/payment";
export const BOOKING = "/booking";
export const LOGIN_PAGE = "/auth/login";
export const CONFIRM_RESET_PWD = "/reset-password/confirm";
