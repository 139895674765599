import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, METHODS } from ".";
import { dispatch } from "..";
import { handleJobbers } from "../features/authSlice";
import { baseQueryInterpolatorAuth } from "./interceptor";

export const jobber_serviceApi = createApi({
  reducerPath: 'jobber_serviceApi',
  baseQuery: baseQueryInterpolatorAuth,
  tagTypes: ["jobberService"],
  endpoints: (builder) => ({
    getAllJobbersByPosition: builder.query({
      query({ service_id, longitude, latitude, date }) {
        return {
          url: `${ENDPOINTS?.JOBBER_SERVICE}/positions/${service_id}/${latitude}/${longitude}/${date}`,
        }
      },

      transformResponse(response) {
        dispatch(handleJobbers(response))
        return response
      },
    }),

    addUpdateJobberService: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.JOBBER_SERVICE}`,
          body,
          method: `${METHODS?.POST}`
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: [{ type: "settings", id: "settings" }]
    }),

  })
})

export const { useGetAllJobbersByPositionQuery, useAddUpdateJobberServiceMutation } = jobber_serviceApi