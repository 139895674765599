import { Avatar, Box, Chip, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import AvTimerIcon from '@mui/icons-material/AvTimer';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import ThumbsUpDownOutlinedIcon from '@mui/icons-material/ThumbsUpDownOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import React from 'react'

const data_1 = [
    {
        icon: <AvTimerIcon fontSize='inherit' color='primary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Rendez-vous',
        desc: `Définissez avec votre jobber le lieu, la date et l'heure du job. Assurez-vous que ces éléments conviennent à tous les deux`
    },
    {
        icon: <WorkOutlineIcon fontSize='inherit' color='primary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Job',
        desc: `Rédigez avec votre jobber une liste des missions à réaliser`
    },
    {
        icon: <HowToRegOutlinedIcon fontSize='inherit' color='primary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Rémunération',
        desc: `Assurez-vous que la rémunération établie entre vous et le jobber soit clair. En cas d'heures supplémentaires définissez une rémunération horaire`
    },
    {
        icon: <SavingsOutlinedIcon fontSize='inherit' color='primary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Paiement',
        desc: `Payez votre jobber une fois le travail totalement réalisé`
    },
    {
        icon: <SpeedOutlinedIcon fontSize='inherit' color='primary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Contrôle',
        desc: `Contrôlez que l'ensemble des tâches à bien été réalisé par votre jobber. Dans le cas contraire, ne payez pas la totalité du travail et établissez avec votre jobber la liste des choses à faire ou à rectifier (si des heures supplémentaires sont nécessaires, reportez-vous à la rémunération horaire que vous aviez préalablement fixé)`
    },
    {
        icon: <ThumbsUpDownOutlinedIcon fontSize='inherit' color='primary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Evaluation',
        desc: `Pensez à évaluer votre jobber. L'ensemble des évaluations permet de garder un niveau élevé de confiance et de sécurité`
    },
]

const data_2 = [
    {
        icon: <AvTimerIcon fontSize='inherit' color='secondary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Rendez-vous',
        desc: `Définissez avec votre jobber le lieu, la date et l'heure du job. Assurez-vous que ces éléments conviennent à tous les deux`
    },
    {
        icon: <ConstructionOutlinedIcon fontSize='inherit' color='secondary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Equipement',
        desc: `Vérifiez avec votre client s'il dispose des outils nécessaires pour la bonne réalisation du job. Sinon pensez à apporter les vôtres`
    },
    {
        icon: <WorkOutlineIcon fontSize='inherit' color='secondary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Job',
        desc: `Rédigez avec votre jobber une liste des missions à réaliser`
    },
    {
        icon: <HowToRegOutlinedIcon fontSize='inherit' color='secondary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Rémunération',
        desc: `Assurez-vous que la rémunération établie entre vous et le jobber soit clair. En cas d'heures supplémentaires définissez une rémunération horaire`
    },
    {
        icon: <RocketLaunchOutlinedIcon fontSize='inherit' color='secondary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Réalisations',
        desc: `Apportez à votre client la meilleure prestation possible. N'oubliez pas de nettoyer votre zone de travail une fois le job réalisé`
    },
    {
        icon: <SpeedOutlinedIcon fontSize='inherit' color='secondary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Contrôle',
        desc: `Contrôlez que l'ensemble des tâches à bien été réalisé par votre jobber. Dans le cas contraire, ne payez pas la totalité du travail et établissez avec votre jobber la liste des choses à faire ou à rectifier (si des heures supplémentaires sont nécessaires, reportez-vous à la rémunération horaire que vous aviez préalablement fixé)`
    },
    {
        icon: <ThumbsUpDownOutlinedIcon fontSize='inherit' color='secondary' sx = {{ fontSize: 120, fontWeight: '500', mb: 3 }} />,
        title: 'Evaluation',
        desc: `Pensez à évaluer votre jobber. L'ensemble des évaluations permet de garder un niveau élevé de confiance et de sécurité`
    },
]

const ConseilJobbing = () => {
  return (
    <Box>
        <Container maxWidth = {'lg'} >
        <Typography textAlign={'center'} color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 48, my: 5, }} >
        Pratiquer le jobbing
&
améliorer son quotidien
        </Typography>
        <Typography color={'#333'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', mt: 5, mb: 3 }} >
            Comment pratiquer le jobbing dans les meilleures conditions ?
        </Typography>
        <Typography color={'#5D636A'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
            Vous avez posté un job en ligne et venez de réserver un jobber ? Vous avez été réservé pour réaliser un job ? Voici quelques conseils qui vous 
            permettront de vivre votre expérience Jejobtout dans les meilleures conditions
        </Typography>

        <Typography color={'#333'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', mt: 5, mb: 3 }} >
            Conseils Client
        </Typography>
        <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} spacing={3} mb = {4} mt = {8} >
            {
                data_1?.map(({ icon, title, desc }, i)=> (
                    <Grid container flexDirection={'column'} alignItems={'center'} item xs={12} sm={12} md={4} lg={4} >
                        {icon}
                        <Box width={'85%'} >
                            <Typography width={'100%'} color={'primary'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 32, lineHeight: '25px', mb: 2 }} >
                                {title}
                            </Typography>
                            <Divider sx = {{ my: 2 }} />
                            <Typography width={'100%'} color={'#333'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', mb: 1 }} >
                                {desc}
                            </Typography>
                    </Box>
                </Grid>
                ))
            }    
        </Grid>
        </Container>
        <Divider />
        <Typography color={'#333'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', mt: 5, mb: 3 }} >
            Conseils jobbers
        </Typography>
        <Container maxWidth = {'lg'} >
        <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"row"} spacing={3} mb = {4} mt = {8} >
            {
                data_2?.map(({ icon, title, desc }, i)=> (
                    <Grid container flexDirection={'column'} alignItems={'center'} item xs={12} sm={12} md={4} lg={4} >
                        {icon}
                        <Box width={'85%'} >
                            <Typography width={'100%'} color={'secondary'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 32, lineHeight: '25px', mb: 2 }} >
                                {title}
                            </Typography>
                            <Divider sx = {{ my: 2 }} />
                            <Typography width={'100%'} color={'#333'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', mb: 1 }} >
                                {desc}
                            </Typography>
                    </Box>
                </Grid>
                ))
            }    
        </Grid>
        </Container>
        {/* <Divider />
        <Stack direction={{ sm: 'column', md: 'row' }} alignItems={'flex-start'} justifyContent={{ sm: 'center', md: 'space-around' }} >
            <Typography color={'#333'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 2 }} >
                Nous sommes là pour vous aider
            </Typography>
            <Box>
                <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                    En cas de besoin, consultez notre centre d'aide ou écrivez-nous via le formulaire de contact
                </Typography>
                <Stack direction={'row'} spacing={1} alignItems={'center'} >
                    <Typography color={'primary'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 2 }} >
                        Obtenir de l'aide
                    </Typography>
                    <ChevronRightIcon color='primary' />
                </Stack>
            </Box>
        </Stack> */}
    </Box>
  )
}

export default ConseilJobbing