import { Box, Chip, Container, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import JButton from '../../components/common/Button'
import { JOBBER } from '../../router';

const JobberPro = () => {
    const registerJobber = () => window.location.href = JOBBER
    const BecomeJobber = () => (
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} my={3} >
            <JButton title={'Devenir Jobber Pro'} style={{ bgcolor: '#a6cc1f', mt: 5, width: 250 }} onClick={registerJobber} />
        </Stack>
    )
    return (
        <Box>
            <Container maxWidth={'lg'} >
                <Typography textAlign={'center'} color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 48, my: 5, }} >
                    Qu’est ce qu’un jobber PRO ?
                </Typography>

                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} >
                    <Chip color='primary' label="PRO" />
                </Stack>
                <Typography textAlign={'center'} color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                    Des profils de créateurs émergent chaque jour donnant naissance à des créations d’entreprises. Plus de la moitié se créaient sous le régime de l’Auto-Entrepreneur, désormais appelé « Micro-entreprise ». Les avantages sont nombreux :
                </Typography>
                <ul>
                    <li>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                            Un régime fiscal et des démarches administratives simplifiées
                        </Typography>
                    </li>
                    <li>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                            Accessible à tous : Retraités, en recherches d’emplois, particuliers, étudiants etc.
                        </Typography>
                    </li>
                    <li>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                            Un enregistrement simplifié et rapide, possible en ligne
                        </Typography>
                    </li>
                    <li>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                            Vous êtes votre propre dirigeant
                        </Typography>
                    </li>
                    <li>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                            Diverses activités sont compatibles avec ce régime
                        </Typography>
                    </li>
                </ul>
                <Typography color={'#5D636A'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                    Vous venez de vous lancer en tant qu’auto-entrepreneur ? Passez à la vitesse supérieure grâce aux jobbing. Issue de la consommation collaborative,
                    cette pratique est aujourd’hui la solution la plus adaptée pour développer votre activité de service en tant qu’auto-entrepreneur. Créez votre
                    profil en quelques clics, obtenez le badge <span><Chip color='primary' label="PRO" /></span> et commencez à répondre aux jobs qui sont proposés
                    autour de chez vous. Vous serez alors certifié comme l’un de nos jobbe Pro (un jobber ayant une structure professionnelle).
                </Typography>
                <BecomeJobber />
            </Container>
            <Box bgcolor={'#F5F5F5'} py={2} >
                <Container maxWidth={'lg'} >
                    <Typography color={'#333'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', mt: 5, mb: 3 }} >
                        Obtenir son badge PRO ?
                    </Typography>
                    <Typography color={'#5D636A'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                        Lors de l'inscription choisissez le statut « Entrepreneur », renseignez votre numéro de SIRET dans la partie badge de votre tableau de bord. Après vérification par nos équipes de modération votre badge sera validé et s'affichera sur votre profil.
                    </Typography>
                    <Typography color={'#5D636A'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                        Devenez le nouveau prestataire de services et développez votre activité grâce au badge <span><Chip color='primary' label="PRO" /></span>
                    </Typography>

                    <Typography color={'#333'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', mt: 5, mb: 3 }} >
                        C’est le moment de remplir vos agendas !
                    </Typography>
                    <Typography color={'#5D636A'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                        Les auto-entrepreneurs sont les bienvenus. Une solution simple, efficace pour développer votre business a été créée grâce à ce badge <span><Chip color='primary' label="PRO" /></span>.
                        En effet, il indiquera alors à la communauté que vous avez structuré votre activité, que vous êtes en mesure de produire une facture et
                        que votre activité est couverte par une assurance. Tous ces éléments sont un plus qui viennent rassurer le client sur votre futur prestation.
                        Bien évidemment, il n'en reste pas moins qu'il faut réaliser des jobs de qualité pour obtenir les meilleures évaluations !
                    </Typography>

                    <Typography color={'#333'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', mt: 5, mb: 3 }} >
                        Développer votre notoriété
                    </Typography>
                    <Typography color={'#5D636A'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                        Vous ne devez pas oublier que même si les formalités sont simplifiées, vous venez de créer une activité dont l’enjeu clé est de
                        pérenniser votre entreprise sur le marché. Il est alors important de se faire connaître : vous et votre savoir-faire. Et pourtant
                        ce n’est pas si facile quand on débute… Rassurez vous, être jobbers PRO est un avantage non sans effet ici : Vous êtes énormément
                        sollicité et apprécié des clients.
                    </Typography>

                    <Typography color={'#333'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', mt: 5, mb: 3 }} >
                        Le nouveau « Trip Advisor » des prestations de service
                    </Typography>
                    <Typography color={'#5D636A'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                        Un gros plus pour votre activité. Vous pourrez ainsi recevoir des avis / des témoignages de vos clients JeJobTout et même de vos clients externes,
                        ayant eu recours à vos services. Vous allez pouvoir construire votre réputation auprès de vos clients et futurs clients. Vos clients se sentent
                        en confiance et vous confient toujours plus de mission.
                    </Typography>

                    <Typography color={'#333'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', mt: 5, mb: 3 }} >
                        Développer votre clientèle
                    </Typography>
                    <Typography color={'#5D636A'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                        Encore une fois, détenir un important portefeuille client, n’est pas une chose simple. Cependant nous mettons à votre disposition des outils efficaces pour y remédier.
                    </Typography>
                    <Typography color={'#5D636A'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                        Un client potentiel ? Partagez lui votre profil JeJobTout. Toutes vos évaluations, vos avis reçus y sont répertoriés. Ainsi, plus besoin de venter tous vos mérites, vos anciens clients s’en seront chargés pour vous.
                    </Typography>
                    <Typography color={'#5D636A'} textAlign={'center'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                        N’attendez plus, les clients n’attendent que vous
                    </Typography>
                    <BecomeJobber />
                </Container>
            </Box>
            {/* <Divider />
            <Stack direction={{ sm: 'column', md: 'row' }} alignItems={'flex-start'} justifyContent={{ sm: 'center', md: 'space-around' }} >
                <Typography color={'#333'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 2 }} >
                    Nous sommes là pour vous aider
                </Typography>
                <Box>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                        En cas de besoin, consultez notre centre d'aide ou écrivez-nous via le formulaire de contact
                    </Typography>
                    <Stack direction={'row'} spacing={1} alignItems={'center'} >
                        <Typography color={'primary'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 2 }} >
                            Obtenir de l'aide
                        </Typography>
                        <ChevronRightIcon color='primary' />
                    </Stack>
                </Box>
            </Stack> */}
        </Box>
    )
}

export default JobberPro