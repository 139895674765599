import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, METHODS } from ".";
import { dispatch } from "..";
import { getId, getJobberId } from "../../utilities";
import { handlePersonalProfile, handleSettingsJobber } from "../features/authSlice";
import { baseQueryInterpolatorAuth } from "./interceptor";

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryInterpolatorAuth,
  tagTypes: ["settings"],
  endpoints: (builder) => ({
    singOut: builder.mutation({
      query() {
        return {
          url: `${ENDPOINTS?.SING_OUT}`,
          method: `${METHODS?.POST}`,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    findAllUser: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.USERS}`,
        }
      },
    }),


    findAllJobbers: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.JOBBERS}`,
        }
      },
    }),

    findJobberProfile: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.JOBBERS}/${getJobberId()}/profile`,
        }
      },
      transformResponse: (response) => {
        dispatch(handlePersonalProfile(response))
        return response
      }
    }),

    findUsersProfile: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.USERS}/${getId()}/profile`,
        }
      },
      transformResponse: (response) => {
        console.log('response', response)
        return response
      },
    }),

    addServiceToJobber: builder.mutation({
      query({ jobberId, serviceId }) {
        return {
          url: `${ENDPOINTS?.JOBBERS}/${jobberId}/add-service/${serviceId}`,
          method: `${METHODS?.POST}`,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    updateUserPhoto: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.USER_PHOTO}/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    updateCNIPhoto: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.USER_CNI_PHOTO}/${getId()}`,
          method: `${METHODS?.POST}`,
          body,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    settingsJobber: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.USERS}/${getJobberId()}/settings`,
        }
      },
      transformResponse: (response) => {
        dispatch(handleSettingsJobber(response))
        return response
      },
      providesTags: [{ type: "settings", id: "settings" }]
    }),


  })
})

export const {
  useSingOutMutation,
  useFindAllJobbersQuery,
  useFindAllUserQuery,
  useUpdateUserPhotoMutation,
  useFindJobberProfileQuery,
  useSettingsJobberQuery,
  useFindUsersProfileQuery,
  useUpdateCNIPhotoMutation,

} = userApi