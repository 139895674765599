import { Stack, Typography } from '@mui/material'
import React from 'react'

const InfoBarItem = ({ icon, title }) => {
    return (
        <Stack my={1} direction={"row"} justifyContent={"center"} alignItems={"center"} flexDirection={"row"} width='100%' >
            {icon}
            <Typography sx={{ fontFamily: 'catamaran', fontWeight: '500', color: '#25282c', ml: 2 }} >
                {title}
            </Typography>
        </Stack>
    )

}

export default InfoBarItem