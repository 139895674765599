
import React, { useMemo, useState } from 'react'
import { Circle, GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Box } from '@mui/material';
import JButton from '../Button';
import DistanceBtn from '../DistanceBtn'
import { useSelector } from 'react-redux';
import { useZoneInterventionToJobberMutation } from '../../../app/services/zoneInterventionApi';
import { PROFILE_PHOTO } from '../../../router';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PushPinIcon from '@mui/icons-material/PushPin';
import { colors } from '@mui/material';

import './styles.css'
import { dispatch } from '../../../app';
import { handleZoneIntervention } from '../../../app/features/authSlice';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const libraries = ["places"]

const MapView = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries
    })
    const options = {
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillOpacity: 0.05,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        strokeColor: '#0f4d61',
        fillColor: '#0f4d61',
        zIndex: 3
    }
    const [Selected, setSelected] = useState(null)
    const [value, setValue] = useState(null);
    const [Map, setMap] = useState(/** @type  google.maps.Map */(null))
    const [Rayon, setRayon] = useState(1)
    const center = useMemo(() => ({ lat: 40, lng: -88 }), [])
    const { zoneIntervention } = useSelector(state => state.auth)
    const [zoneInterventionToJobber, { isLoading }] = useZoneInterventionToJobberMutation()
    const handleZone = () => {
        zoneInterventionToJobber(zoneIntervention).unwrap().then((resp) => {
            window.location.href = `${PROFILE_PHOTO}`
        })
    }

    const handleSelect = async (address) => {
        geocodeByAddress(address?.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                setValue(address?.label)
                Map?.panTo({ lat, lng })
                setSelected({ lat, lng })
                dispatch(handleZoneIntervention({ lat, lng, address: address?.label }))
            }
            );
    }
    if (!isLoaded) return <div>Map Loading</div>
    return (
        <Box>
            <GoogleMap
                zoom={10}
                center={center}
                mapContainerClassName='map-container'
                mapContainerStyle={{ margin: `20px 0px` }}
                onLoad={(map) => setMap(map)}
            >
                {Selected && <Circle
                    radius={Rayon * 1000}
                    // required
                    center={Selected}
                    // required
                    options={options}
                    editable
                />}
                {Selected && <Marker position={Selected} />}
            </GoogleMap>
            <div>
                {isLoaded && <div>
                    <GooglePlacesAutocomplete apiKey={process.env.REACT_APP_GOOGLE_API_KEY} selectProps={{
                        onChange: handleSelect,
                        placeholder: 'Entrer votre adresse',

                    }} />
                    <DistanceBtn setRayon={setRayon} />
                </div>
                }
            </div>
             <JButton loading={isLoading} title={"Valider"} onClick={handleZone} />
        </Box>
    )
}

export default MapView