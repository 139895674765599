import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, } from '@mui/material';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { dispatch } from '../../../app';
import { handleJobberbyPriceInterval } from '../../../app/features/authSlice';

export default function PriceSlider() {
    const [value1, setValue1] = React.useState([0, 200])
    const handleValue = (value) => {
        setValue1(value);
        dispatch(handleJobberbyPriceInterval({lPrice:value[0]*1000, rPrice: value[1]*1000 }))
    }

    return (
        <Box sx={{ width: '100%', position: 'relative', }}>
            <Slider
                range
                value={value1}
                allowCross={false}
                min={0}
                max={100}
                onChange={(value) => handleValue(value)}
            />
            <Typography sx={{ fontSize: 16, fontWeight: '600', fontFamily: 'Futura-Medium', color: '#000' }} >
                <span style={{ fontSize: 15, fontWeight: '500', fontFamily: 'Futura-Medium', color: '#00000045' }} >Price</span>  
                {`CFA ${value1[0] * 1000}`}--{`CFA ${value1[1] * 1000}`}
            </Typography>
        </Box>
    );
}