import { Backdrop, Box, Button, Fade, IconButton, Modal, Stack, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";


export default function TransitionsModal({ open, setOpen, children }) {

    const resizeModal = useMediaQuery('(max-width:600px)');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: resizeModal ? '90%' : 500,
        bgcolor: 'background.paper',
        borderRadius: 3,
        p: 2,
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Stack flexDirection={"row"} justifyContent={"flex-end"} >
                            <IconButton onClick={handleClose} >
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </Stack>
                        {children}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}