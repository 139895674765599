import { Stack, Typography } from '@mui/material'
import React from 'react'
import './styles.css'

const TipsRound = ({ title, icon, subTitle, index }) => {
    return (
        <Stack my={1} direction={"column"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} height={"100%"} >
            <div className='row-flex wrapper-box-tip' >
                {icon}
            </div>
            <Typography sx={{ fontFamily: 'Futura Md BT', fontWeight: '700', fontSize: 16, color: 'white', my: 2 }} >
                {index}.{title}
            </Typography>
            <Typography sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', width: '50%', color: 'whitesmoke', fontSize: 14, textAlign: 'center' }} >
                {subTitle}
            </Typography>
        </Stack>


    )
}

export default TipsRound