import { Box, Container, Paper, Typography, colors, CircularProgress, Grid } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../../app'
import { handlePage } from '../../app/features/offerSlice'
import { useAllOffersQuery } from '../../app/services/offreApi'
import JButton from '../../components/common/Button'
import JobberOffreItem from '../../components/JobberOffreItem'
import Pagination from '../../components/Pagination'
import { JOBBER } from '../../router'

const OffreJobbers = () => {
  const { page, size } = useSelector(state => state.offers)
  const { isLoading, data } = useAllOffersQuery({ page, size }, { refetchOnMountOrArgChange: true, refetchOnFocus: true })
  const handlePagePagination = page => dispatch(handlePage(page - 1));
  const jobberRegistry = () => window.location.href = JOBBER

  return (
    <Box>
      <Container maxWidth={'lg'} >
        <Typography sx={{ fontSize: 22, fontWeight: '700', color: colors.common.black }} >
          Annonces et offres d'emploi à pourvoir
        </Typography>
        <Typography sx={{ fontSize: 16, fontWeight: '500', color: colors.common.black }} >
          Vous êtes à la recherche d'un emploi ? Vous souhaitez travailler chez un particulier et gagner un complément de salaire ?
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
          <Typography sx={{ fontSize: 18, fontWeight: '700', width: '100%', color: colors.common.black }} >
            Trouvez un job parmi nos {data ? data?.totalElements : 0} annonces
          </Typography>
          <Paper component={Box} sx={{ bgcolor: `#0f4d61`, borderRadius: 2, padding: 2, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2, mx: 1 }} >
            <Typography textAlign={'left'} sx={{ fontSize: 18, width: '100%', fontWeight: '600', color: colors.common.white }} >
              Envie de compléter vos revenus ou de lancer votre activité de service à domicile ?
            </Typography>
            <JButton style={{ bgcolor: 'white', color: '#333' }} onClick={jobberRegistry} title={'Devenez Jobber'} />
          </Paper>
          { data && data.content.length < 0 && <Grid> <Typography> Pas d'annonces pour le moment</Typography></Grid>}
          {
            isLoading ? <CircularProgress color="primary" /> :
              data && data.content?.length > 0 && data?.content?.map((offer, i) => (
                <JobberOffreItem
                  key={i + "offer"}
                  service={offer?.service?.title}
                  amount={offer?.amount}
                  address={"not available"}
                  description={offer?.description}
                  expired={offer?.expiredAt}
                  id={offer?.id}
                />))
          }
          {data && <Pagination
            onPageChange={page => handlePagePagination(page)}
            totalCount={data?.totalElements}
            currentPage={page + 1}
            pageSize={data?.size}
          />}
        </Box>
      </Container>
    </Box>
  )
}

export default OffreJobbers