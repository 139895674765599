import { Backdrop, Box, colors, Fade, IconButton, Modal, Stack, Typography } from '@mui/material'
import React from 'react'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useResendOtpMutation } from '../../app/services/authApi';
import { Controller, useForm } from 'react-hook-form';
import { LOGIN_PAGE } from '../../router';
import Input from '../../components/Input';
import JButton from '../../components/common/Button';

const ResendOTP = ({ openModal, setOpenModal, }) => {

    const [resendOtp, { isLoading, error }] = useResendOtpMutation()
    const { control, handleSubmit, formState: { errors } } = useForm({ defaultValues: { email: '' } });
    const reset =(data) => {
        resendOtp(data.email).unwrap().then(() => {
            window.location.href = `${LOGIN_PAGE}/confirm`
        })
    }
    const handleClose = () => setOpenModal(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        borderRadius: 3,
        p: 2,
    };
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <Stack flexDirection={"row"} justifyContent={"flex-end"} >
                            <IconButton onClick={handleClose} >
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </Stack>
                        <Box>
                            <Stack spacing={2} >
                                <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '700', fontSize: 40 }} >
                                    Code invalid, expire ?
                                </Typography>
                                <Typography variant='inherit' sx={{ fontSize: 18, color: '#333', fontFamily: 'Futura-Medium' }} >
                                    Veuillez saisir votre adresse e-mail ci-dessous. Nous vous enverrons un e-mail contenant un nouveau code de validation
                                </Typography>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            placeholder={"Adresse-email"}
                                            type="email"
                                            leftIcon={<EmailOutlinedIcon color={colors.grey[500]} />}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    name="email"
                                />
                                {errors.email && (<span style={{ color: "red" }}>Email is required.</span>)}
                                {error && (<span style={{ color: "red" }}>{error?.data.message}</span>)}
                                <JButton title={"Confirmer"} loading={isLoading} onClick={handleSubmit(reset)} />
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>

    )
}

export default ResendOTP