import { Box, Button, CircularProgress, colors, Container, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';
import './sytles.css'
import {
    FacebookShareButton,
    FacebookIcon,
    PinterestShareButton,
    PinterestIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import JobberItemLis from '../../components/JobberItemList';
import { useGetAllJobbersByPositionQuery } from '../../app/services/jobber-serviceApi';
import SearchBarAdvanced from '../../components/common/SearchBarAdvanced';
import { getItem, toJson } from '../../utilities';
import TransitionsModal from '../../components/Modal';
import SideFilter from '../../components/common/SideFilter';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useSelector } from 'react-redux';
import { dispatch } from '../../app';
import { handleServiceDemand } from '../../app/features/authSlice';


const CompletedBooking = () => {

    const { jobbersFilter } = useSelector(state => state.auth)
    const demandServices = toJson(getItem("dmdServices"))
    const breadcrumbServicess = toJson(getItem("breadcrumbServices"))
    const serviceDemands = breadcrumbServicess[0]
    const { date, latitude, longitude } = demandServices
    const service_id = breadcrumbServicess.at(-1)?.id

    const datas = { date: new Date(date).toJSON(), latitude, longitude, service_id }

    const { isLoading } = useGetAllJobbersByPositionQuery(datas, {refetchOnMountOrArgChange: true, refetchOnFocus: true, refetchOnReconnect: true})

    const dateT = new Date(demandServices?.date)?.toJSON()?.toString()


    const [openDialog, setOpenDialog] = React.useState(false);

    const matches = useMediaQuery('(max-width:900px)');

    return (
        <Box>
            <Container maxWidth={"xl"} >
                <div className='desc-img' style={{ background: `url(${serviceDemands?.url}) bottom center` }} ></div>
                <Stack justifyContent={"space-between"} alignItems={"center"} width={'100%'} direction={{ sm: 'column', md: 'row' }}>
                    <Box>
                        <Typography sx={{ fontSize: 28, my: 1, color: colors.grey[900], fontWeight: '700', fontFamily: 'catamaran' }} >
                            {serviceDemands?.title}
                        </Typography>
                        <Typography sx={{ fontSize: 18, color: colors.grey[600], fontWeight: '400', fontFamily: 'catamaran' }} >
                            {new Date(dateT).toDateString()}  à {demandServices?.time}
                            ({(Math.trunc(demandServices?.numberHours / 60) > 0 ? Math.trunc(demandServices?.numberHours / 60) + "h" : "") +
                                (demandServices?.numberHours % 60 > 0 ? demandServices?.numberHours % 60 + "min" : "")})
                        </Typography>
                    </Box>
                    <Button sx={{ textTransform: 'none', fontSize: 16, backgroundColor: colors.blue[400], width: 100, height: 46 }} >
                        Modifier
                    </Button>
                </Stack>
                <Stack my={1} direction={'row'} spacing={2} >
                    <Stack direction={'row'}>
                        <PlaceIcon color='primary' />
                        <Typography sx={{ fontSize: 18, ml: 1.2, color: colors.grey[700], fontWeight: '400', fontFamily: 'catamaran' }} >
                            Address: {demandServices?.address}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'}>
                        <PersonIcon color='primary' />
                        <Typography sx={{ fontSize: 18, ml: 1.2, color: colors.grey[700], fontWeight: '400', fontFamily: 'catamaran' }} >
                       { jobbersFilter?.length} Jobber demander
                        </Typography>
                    </Stack>
                </Stack>
                <Typography sx={{ fontSize: 15, color: colors.grey[900], fontWeight: '400', fontFamily: 'Futura-Medium' }} >
                    Partager votre offre ?
                </Typography>
                <Stack direction={"row"} spacing={1} >
                    <FacebookShareButton url='https://www.npmjs.com/package/react-share' children={<FacebookIcon round size={34} />} />
                    <PinterestShareButton url='https://www.npmjs.com/package/react-share' children={<PinterestIcon round size={34} />} />
                    <TelegramShareButton url='https://www.npmjs.com/package/react-share' children={<TelegramIcon round size={34} />} />
                    <WhatsappShareButton url='https://www.npmjs.com/package/react-share' children={<WhatsappIcon round size={34} />} />
                </Stack>
                <Typography sx={{ fontSize: 20, my: 1.2, color: colors.grey[700], fontWeight: '400', fontFamily: 'catamaran' }} >
                    Offres reçues {jobbersFilter && <>({jobbersFilter?.length})</>}
                </Typography>

                <SearchBarAdvanced />
                {matches ? <Stack my={2} direction={'row'} justifyContent={'flex-end'} >
                    <IconButton onClick={() => setOpenDialog(true)} >
                        <WidgetsIcon direction={'row'} justifyContent={'flex-end'} sIcon color='inherit' fontSize='inherit' sx={{ fontSize: 45, color: '#00000020' }} />
                    </IconButton>
                </Stack> : null}
                <Grid container mt={2} justifyContent={'space-between'} alignItems={'flex-start'} >
                    <Grid item container xs={3} p={1.2} className='border-grid' >
                        <SideFilter />
                    </Grid>
                    { isLoading ? 
                        <Grid item xs={8} p={2} className='border-grid' >
                            <CircularProgress color="primary" />
                        </Grid>:
                        jobbersFilter?.length > 0 ? 
                            <Grid item xs={8} p={2} className='border-grid' >
                                <JobberItemLis jobbers={jobbersFilter} />
                            </Grid>:
                            <Grid item xs={8} p={2} className='border-grid' >
                                <br /> No jobber found for this address, date or Service selected...
                                <br /> Please back and update this informations
                            </Grid>
                    }
                </Grid>
            </Container>
            <TransitionsModal open={openDialog} setOpen={setOpenDialog} >
                <SideFilter />
            </TransitionsModal>
        </Box>
    )
}

export default CompletedBooking;
