import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import cost from '../../../assets/images/Tracé 6154 (1).svg'
import './styles.css'
import { Link } from 'react-router-dom';
import { OFFERS } from '../../../router';
import { days } from '../../../utilities';

const OffreItem = (props) => {
    const { offer } = props
   

    return (
        <Box my={1.2} >
            <Grid container spacing={1} >
                <Grid container item xs={12} >
                    <Grid item container xs={12} sm={12} md={7} justifyContent={'space-between'} >
                        <Stack spacing={1} direction={{ md: 'column', lg: 'row' }}  >
                            <Typography sx={{ fontFamily: 'Futura-Medium', color: '#000', fontWeight: '600' }} >
                               {offer?.title}
                            </Typography>
                            <Stack direction={'row'} spacing={1} >
                                <AccessTimeIcon />
                                <Typography sx={{ fontFamily: 'Futura-Medium', color: '#000' }} >
                                    {days(offer?.expiredAt)} jours restant
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item container justifyContent={'space-between'} xs={12} sm={12} md={5} >
                        <Stack direction={{ md: 'column', lg: 'row' }} spacing={1} >
                            <Stack direction={'row'} >
                                <img style={{ height: 20, width: 20 }} src={cost} alt='icon img' />
                                <Typography sx={{ fontFamily: 'Futura-Medium', color: '#000', }} >
                                    {offer?.service?.title}
                                </Typography>
                            </Stack>
                            <Typography sx={{ fontFamily: 'Futura-Medium', color: '#000', }} >
                               {offer?.amount} XAF/ offre moyenne
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1} justifyContent={'space-between'} >
                    <Grid item xs={12} sm={12} md={8} >
                        <Typography sx={{ fontSize: 14 }} >
                            {offer?.description}
                        </Typography>
                    </Grid>
                    <Grid container justifyContent={'center'} item xs={12} sm={12} md={3} >
                        <Link style={{ width: '100%' }} className='link' to={`${OFFERS}/views/${offer?.id}`} >
                            <button className='offre-btn' >
                                Prendre l’offre
                            </button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: 'lightgrey', mt: 1.3 }} />
        </Box>
    )
}

export default OffreItem