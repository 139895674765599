import React from 'react'
import HelpIcon from '@mui/icons-material/Help';
import './styles.css'
import { Typography } from '@mui/material';

const TipsInfo = ({ text }) => {
    return (
        <div className='row-flex-space info-wrapper' >
            <HelpIcon color='primary' />
            <Typography sx={{ fontFamily: 'catamaran', ml: 1.2, fontWeight: '400', color: '#333', fontSize: 14 }} >
                {text}
            </Typography>
        </div>
    )
}

export default TipsInfo