import { colors, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Input from '../../components/Input';
import JButton from '../../components/common/Button';
import { useConfirmResetPasswordMutation } from '../../app/services/authApi';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { handleOpenMoadalLayout } from '../../app/features/authSlice';
import { dispatch } from '../../app';

const ConfirmPassword = () => {
    const params = useParams()
    const {code, email } = params
    const [confirmResetPassword, { isLoading, error }] = useConfirmResetPasswordMutation()
    const { control, handleSubmit, formState: { errors } } = useForm({ defaultValues: { newPassword: "", confirm: "" } });
    const [errorPwd, setErrorPwd] = React.useState(null)
    const confirmReset = (data) => {
        if(data.newPassword !== data.confirm){
            setErrorPwd("Password don't match")
        }else{
            setErrorPwd(null)
            confirmResetPassword({code, email, newPassword: data.newPassword}).unwrap().then((resp) => {
                dispatch(handleOpenMoadalLayout(true))
            })
        }
    }
    return (
        <Container maxWidth="sm" sx={{ marginY: 5}}>
            <Stack spacing={2} >
                <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '700', fontSize: 40 }} >
                    Change your password
                </Typography>
                <Controller
                    control={control}
                    rules={{
                        required: true, minLength: 6
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            placeholder={"New password"}
                            type="password"
                            leftIcon={<EmailOutlinedIcon color={colors.grey[500]} />}
                            onChange={onChange}
                            value={value}
                        />
                    )}
                    name="newPassword"
                />
                {errors.newPassword && (<span style={{ color: "red" }}>password is required.</span>)}
                <Controller
                    control={control}
                    rules={{
                        required: true, minLength: 6
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            placeholder={"Confirm new password"}
                            type={'password'}
                            leftIcon={<LockOutlinedIcon color={colors.grey[500]} />}
                            onChange={onChange}
                            value={value}
                        />
                    )}
                    name="confirm"
                />
                {errors.confirm && (<span style={{ color: "red" }}> Please confirm.</span>)}
                {error && (<span style={{ color: "red" }}>{"This Link are expired."}</span>)}
                {errorPwd && (<span style={{ color: "red" }}>{errorPwd}</span>)}
                <JButton title={"Se connecter"} loading={isLoading} onClick={handleSubmit(confirmReset)} />
            </Stack>
        </Container>
    )
}

export default ConfirmPassword