import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, METHODS } from ".";
import { baseQueryInterpolatorAuth } from "./interceptor";

export const zoneInterventionApi = createApi({
 reducerPath: 'zoneInterventionApi',
 baseQuery: baseQueryInterpolatorAuth,
 tagTypes: [],
 endpoints: (builder) => ({
  zoneInterventionToJobber: builder.mutation({
   query(body) {
    return {
     url: `${ENDPOINTS?.ZONE_INTERVENTIONS}`,
     method: `${METHODS?.POST}`,
     body,
    }
   },
   extraOptions: { maxRetries: 0 },
  })




 })
})

export const { useZoneInterventionToJobberMutation } = zoneInterventionApi