import { Box, Container, Grid, IconButton, Stack, useMediaQuery } from '@mui/material';
import React from 'react';
import './styles.css'
import SearchBarAdvanced from '../../components/common/SearchBarAdvanced';
import SideFilter from '../../components/common/SideFilter';
import JobberItemLis from '../../components/JobberItemList';
import WidgetsIcon from '@mui/icons-material/Widgets';
import TransitionsModal from '../../components/Modal';

const Booking = () => {
    const [openDialog, setOpenDialog] = React.useState(false);

    const matches = useMediaQuery('(max-width:900px)');

    return (
        <Box className='container' >
            <Container maxWidth='xl' >
                <SearchBarAdvanced />
                {matches ? <Stack my={2} direction={'row'} justifyContent={'flex-end'} >
                    <IconButton onClick={() => setOpenDialog(true)} >
                        <WidgetsIcon direction={'row'} justifyContent={'flex-end'} sIcon color='inherit' fontSize='inherit' sx={{ fontSize: 45, color: '#00000020' }} />
                    </IconButton>
                </Stack> : null}
                <Grid container mt={2} justifyContent={'space-between'} alignItems={'flex-start'} >
                    <Grid item container xs={3} p={1.2} className='border-grid' >
                        <SideFilter />
                    </Grid>
                    <Grid item xs={8} p={2} className='border-grid' >
                        <JobberItemLis />
                    </Grid>
                </Grid>
            </Container>
            <TransitionsModal open={openDialog} setOpen={setOpenDialog} >
                <SideFilter />
            </TransitionsModal>
        </Box>
    )
}

export default Booking;