import { Box, colors, Paper, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './styles.css'


const ServiceItem = (props) => {
    const { onClick, title, icon } = props
    return (
        <Paper onClick={onClick} elevation={0} component={Box} >
            <Box className='row-flex wrapper-box-service' >
                <div className='serv-img' style={{ backgroundImage: `url(${icon})` }} ></div>
                <div className='row-flex-space flex-box' >
                    <Typography sx={{ fontFamily: 'catamaran', fontWeight: '500', color: '#25282c', }} >
                        {title}
                    </Typography>
                    <ArrowForwardIosIcon fontSize='small' color={colors.grey[500]} />
                </div>
            </Box>
        </Paper>
    )
}

export default ServiceItem