import { Avatar, Box, Chip, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import './styles.css'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import JButton from '../../components/common/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ComprendreJobbing = () => {
  return (
    <Box>
        <Container maxWidth = 'lg' >
        <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} spacing={3} my = {2} >
            <Grid item xs={12} sm={12} md={6} lg={6} >
                <Chip color='primary' label="nouveau" />
                <Box display={"flex"} flexDirection={"column"} alignItems={"flex-center"} justifyContent={"center"} sx={{ flex: 1 }} >
                    <Typography color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 48, my: 1, }} >
                    Tout comprendre sur le jobbing
                    </Typography>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >
                    Vous avez entendu parler du jobbing et vous êtes curieux ? Ce concept à l’air intéressant mais vous ne savez pas si c’est fait pour vous ?
                    </Typography>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px',  }} >
                    Que vous soyez prestataire ou futur client, on vous explique tout sur le jobbing afin que plus rien ne reste sans réponse !
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                <div className='side-img-job' />
            </Grid>
        </Grid>
        </Container>
        <Divider sx = {{ my: 5 }} />
        <Container maxWidth = {'lg'} >
            <Typography color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 28, lineHeight: '25px', my: 2 }} >
            C'est quoi le jobbing ?
            </Typography>
            <Typography color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 24, lineHeight: '25px', my: 2 }} >
            La définition et comment ça marche
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Le jobbing est un mode de travail venu des Etats-Unis basé sur la prestation à la mission. Il s’agit donc à l’origine de petits boulots d'appoint qui permettent à des "jobbers" de rendre des services divers à des particuliers, avec le but d’améliorer leur quotidien.
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Le concept s’est d'abord développé dans le domaine du bricolage, face aux besoins de certains particuliers d’obtenir un coup de pouce pour un besoin très spécifique, là où un artisan ne se serait pas nécessairement déplacé pour une petite prestation.
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Poser un miroir au mur, installer un robinet ou déboucher un évier sont quelques exemples de petits boulots que peut réaliser un jobber.
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                <span style={{ fontWeight: '600', color: '#333' }} >Le Jobbing permet au prestataire</span> de travailler à la mission et donc de choisir les tâches qu’il souhaite réaliser en fonction de son planning et de ses compétences.
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                <span style={{ fontWeight: '600', color: '#333' }} >Côté particuliers,</span> le Jobbing à permis à de nombreuses familles de gagner des heures de temps libre en déléguant leurs petites tâches du quotidien.
            </Typography>

            <Typography color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 24, lineHeight: '25px', mt: 5, mb: 2 }} >
                Le jobbing en France et la création du service à domicile
            </Typography>
            <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} spacing={3} mb = {4} >
                <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                    <div className='side-img-job1' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-center"} justifyContent={"center"} sx={{ flex: 1 }} >
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                            En France, le phénomène du Jobbing arrive en 2012, <span style={{ fontWeight: '600', color: '#0f4d61' }} > comme l’explique le CNRS, </span> avec des sites pionniers comme JeJobTout qui s’appelait Youpijob à l’époque :
                        </Typography>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                        “Le phénomène arrive en France en 2012, on a pu observer à partir de cette date une multiplication des plates-formes spécialisées dans le jobbing, avec l’apparition des sites comme YoupiJob.fr, ...“
                        </Typography>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px',  }} >
                            Il est d’abord proposé dans le cadre de services rendus entre particuliers, mais s’est très vite étendu aux professionnels et auto-entrepreneurs. On vous explique d’ailleurs dans la partie suivante, qui sont les prestataires qui font le Jobbing.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Typography color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 24, lineHeight: '25px', mt: 5, mb: 2 }} >
                Qui peut faire du jobbing ?
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Le Jobbing est ouvert à tous ceux qui souhaitent développer leur activité professionnelle ou compléter leurs revenus à côté de leur activité principale.
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Tout le monde peut s’enregistrer sur une plateforme de Jobbing à condition d’avoir les qualifications nécessaires pour réaliser les missions. C’est d’ailleurs pour éviter le mauvais matching que JeJobTout a créé un algorithme qui élimine de notre plateforme les prestataires qui ne répondent pas aux attentes des clients.
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                En résumé, le jobbing c’est pour :
            </Typography>
            <ul>
                <li>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                        un particulier qui cherche à développer son activité
                    </Typography>
                </li>
                <li>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                        un professionnel qui souhaite compléter ses revenus
                    </Typography>
                </li>
                <li>
                <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                        un étudiants à la recherche de petits boulots et de flexibilité
                    </Typography>
                </li>
                <li>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                        un retraité cherchant un travail d’appoint
                    </Typography>
                </li>
            </ul>
            <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} spacing={3} mb = {4} >
                <Grid container flexDirection={'column'} alignItems={'center'} item xs={12} sm={12} md={4} lg={4} >
                    <Avatar sx = {{ height: 220, width: 220 }} src='https://d1b747rczec55w.cloudfront.net/assets/seo/people/yann-8c3295502b158296d0609a0d89ba8687ce6816095134ee9f5bc10cba440e9f8a.jpg'  />
                    <Stack height = {'100%'} direction={'row'} spacing={0} >
                        <Stack  direction={'row'} justifyContent = 'flex-start' alignItems={'flex-start'} >
                            <FormatQuoteIcon fontSize='large' sx = {{ color: '#5D636A' }} />
                        </Stack>
                        <Box width={'85%'} >
                            <Typography width={'100%'} color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 1 }} >
                            Je fais du jobbing depuis 2015 et j’addore la flexibilité que cela m’apporte. C’est surtout une façon pour moi de mettre de l’argent de côté en m’occuppant le weekend à côté de mon job d’interimaire
                            </Typography>
                            <Typography width={'100%'} color={'#5D636A90'} sx={{ fontFamily: 'Futura-Medium', fontStyle: 'italic', fontWeight: '500', fontSize: 18, lineHeight: '25px', mb: 1 }} >
                                Yann, jobber bricolage
                            </Typography>
                        </Box>
                        <Stack direction={'row'} justifyContent = 'flex-end' alignItems={'flex-end'} >
                            <FormatQuoteIcon fontSize='large' sx = {{ color: '#5D636A' }} />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid container flexDirection={'column'} alignItems={'center'} item xs={12} sm={12} md={4} lg={4} >
                    <Avatar sx = {{ height: 220, width: 220 }} src='https://d1b747rczec55w.cloudfront.net/assets/seo/people/yann-8c3295502b158296d0609a0d89ba8687ce6816095134ee9f5bc10cba440e9f8a.jpg'  />
                    <Stack height = {'100%'} direction={'row'} spacing={0} >
                        <Stack  direction={'row'} justifyContent = 'flex-start' alignItems={'flex-start'} >
                            <FormatQuoteIcon fontSize='large' sx = {{ color: '#5D636A' }} />
                        </Stack>
                        <Box width={'85%'} >
                            <Typography width={'100%'} color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 1 }} >
                            Je fais du jobbing depuis 2015 et j’addore la flexibilité que cela m’apporte. C’est surtout une façon pour moi de mettre de l’argent de côté en m’occuppant le weekend à côté de mon job d’interimaire
                            </Typography>
                            <Typography width={'100%'} color={'#5D636A90'} sx={{ fontFamily: 'Futura-Medium', fontStyle: 'italic', fontWeight: '500', fontSize: 18, lineHeight: '25px', mb: 1 }} >
                                Yann, jobber bricolage
                            </Typography>
                        </Box>
                        <Stack direction={'row'} justifyContent = 'flex-end' alignItems={'flex-end'} >
                            <FormatQuoteIcon fontSize='large' sx = {{ color: '#5D636A' }} />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid container flexDirection={'column'} alignItems={'center'} item xs={12} sm={12} md={4} lg={4} >
                    <Avatar sx = {{ height: 220, width: 220 }} src='https://d1b747rczec55w.cloudfront.net/assets/seo/people/yann-8c3295502b158296d0609a0d89ba8687ce6816095134ee9f5bc10cba440e9f8a.jpg'  />
                    <Stack height = {'100%'} direction={'row'} spacing={0} >
                        <Stack  direction={'row'} justifyContent = 'flex-start' alignItems={'flex-start'} >
                            <FormatQuoteIcon fontSize='large' sx = {{ color: '#5D636A' }} />
                        </Stack>
                        <Box width={{ xs: '100%', sm: '100%', md: '85%', lg: '85%' }} >
                            <Typography width={'100%'} color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 1 }} >
                            Je fais du jobbing depuis 2015 et j’addore la flexibilité que cela m’apporte. C’est surtout une façon pour moi de mettre de l’argent de côté en m’occuppant le weekend à côté de mon job d’interimaire
                            </Typography>
                            <Typography width={'100%'} color={'#5D636A90'} sx={{ fontFamily: 'Futura-Medium', fontStyle: 'italic', fontWeight: '500', fontSize: 18, lineHeight: '25px', mb: 1 }} >
                                Yann, jobber bricolage
                            </Typography>
                        </Box>
                        <Stack direction={'row'} justifyContent = 'flex-end' alignItems={'flex-end'} >
                            <FormatQuoteIcon fontSize='large' sx = {{ color: '#5D636A' }} />
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            <Typography color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 24, lineHeight: '25px', mt: 5, mb: 2 }} >
                Je cherche du travail, est-ce que le jobbing est fait pour moi ?
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', mt: 1, mb: 4 }} >
                Bien-sûr, en plus le jobbing offre de nombreux avantages :
            </Typography>

            <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} mb = {4} >
                <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                    <div className='side-img-job1' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-center"} justifyContent={"center"} sx={{ flex: 1 }} >
                        <Typography color={'#333'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 1 }} >
                        Une grande flexibilité pour les prestataires
                        </Typography>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px',  }} >
                            Vous ne postulez qu’aux missions qui vous intéressent
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} mb = {4} >
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-center"} justifyContent={"center"} sx={{ flex: 1 }} >
                        <Typography color={'#333'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 1 }} >
                            Des clients qui profitent plus de la vie
                        </Typography>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px',  }} >
                            Moins de tâches ménagères, moins de tracas au quotidien, plus <br /> de temps pour faire les choses qui vous intéressent.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                    <div className='side-img-job1' />
                </Grid>
            </Grid>
            <Grid container width= "100%" display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} mb = {4} >
                <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                    <div className='side-img-job1' />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-center"} justifyContent={"center"} sx={{ flex: 1 }} >
                        <Typography color={'#333'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 1 }} >
                            Des prestations pour toutes les spécialisations
                        </Typography>
                        <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px',  }} >
                            Du montage de meuble IKEA au jardinage en passant par l'aide ménagère et le déménagement, le jobbing vous permet de trouver des missions tous les jours en fonction de vos compétences
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Typography color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 24, lineHeight: '25px', mt: 5, mb: 2 }} >
                Où puis-je faire du jobbing ?
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Il n’y a pas de limite, le Jobbing c’est partout : des plus grandes villes, en passant par les plus petits bourgs de France, Belgique, Suisse et du Luxembourg.
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Parce que le Jobbing fonctionne aussi de particulier à particulier, il est possible que votre voisin soit un jobber, même si vous habitez une petite bourgade.
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Vous souhaitez savoir quels types de services vous pouvez trouver dans votre ville ?
            </Typography>

            <Typography color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 24, lineHeight: '25px', mt: 5, mb: 2 }} >
                Est-ce que JeJobTout est un site de Jobbing ?
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                JeJobTout a commencé son aventure en 2012, sous le nom de JeJobTout, comme pionnier pour les sites de jobbing qui ont suivi.
            </Typography>
            <Typography color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Mais fort de son succès, JeJobTout a su évoluer pour proposer à ses clients des services qui vont bien au-delà de la simple prestation de Jobbing.
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Notre objectif a toujours été de pouvoir mettre en relation des particuliers qui ont un besoin spécifique avec les meilleurs prestataire de services autour de chez eux, en toute sérénité.
            </Typography>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Cet objectif nous a poussé à développer la technologie de notre plateforme afin de mieux encadrer les prestations et devenir la plateforme de service à la personne la plus complète et la plus sécurisée sur le marché francophone :
            </Typography>
            <ul>
                <li>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                    Algorithme de suivi des performances des Jobbers
                    </Typography>
                </li>
                <li>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                    Assurance sans franchise en cas de tracas
                    </Typography>
                </li>
                <li>
                <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                Accompagnement personnalisé à toutes les étapes de la prestation
                    </Typography>
                </li>
                <li>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                    Vérification manuelle de toutes les inscriptions sur notre plateforme
                    </Typography>
                </li>
                <li>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                    Une rémunération claire et sans surprise
                    </Typography>
                </li>
                <li>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                    Paiement sécurisé pour éviter les malentendus
                    </Typography>
                </li>
                <li>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                    Prise en charge des tickets CESU
                    </Typography>
                </li>
                <li>
                    <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                    Création de JeJobTout Direct pour gérer l’administratif de vos employés de maison
                    </Typography>
                </li>
            </ul>
            <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
            Aujourd’hui, JeJobTout est devenu une plateforme complète de service à domicile avec des prestataires de services qualifiés.
            </Typography>
        </Container> 
        {/* <Divider sx = {{ my: 5 }} />
        <Stack direction={{ sm: 'column', md: 'row' }} alignItems={'flex-start'} justifyContent={{ sm: 'center', md: 'space-around' }} >
            <Typography color={'#333'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 2 }} >
                Nous sommes là pour vous aider
            </Typography> */}
            {/* <Box>
                <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 16, lineHeight: '25px', my: 2 }} >
                    En cas de besoin, consultez notre centre d'aide ou écrivez-nous via le formulaire de contact
                </Typography>
                <Stack direction={'row'} spacing={1} alignItems={'center'} >
                    <Typography color={'primary'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 2 }} >
                        Obtenir de l'aide
                    </Typography>
                    <ChevronRightIcon color='primary' />
                </Stack>
            </Box> */}
        {/* </Stack> */}
    </Box>
  )
}

export default ComprendreJobbing