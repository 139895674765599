import { Autocomplete, Box } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleDescriptionTitleOffer } from '../../app/features/offerSlice'
import TipsInfo from '../../components/common/TipsInfo'
import Input from '../../components/Input'

const JobDesc = () => {
    const dispatch = useDispatch()
    const { emitOffer } = useSelector(state => state.offers)
    const handleDescription = (e) => dispatch(handleDescriptionTitleOffer({title: emitOffer?.title, description: e.target.value}))
    const handleTitle = (e) => dispatch(handleDescriptionTitleOffer({title: e.target.value, description: emitOffer?.description}))

    return (
        <Box>
            <Input type={"text"} value={emitOffer?.title} onChange={handleTitle} style={{ width: '100%', marginTop: 2 }} placeholder={"intitulé du job"} />
            <Input type={"text"} value={emitOffer?.description} onChange={handleDescription} style={{ width: '100%', marginTop:2 }} placeholder={"job description"} />

            <TipsInfo text={"Ces information seront transmises uniquement aux prestataires que vous réservez"} />
        </Box>
    )
}

export default JobDesc