import { AppBar, Box, Button, colors, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { motion } from 'framer-motion';
import NavBarButton from './NavBarButton';
import './styles.css'
import { Link } from 'react-router-dom';
import SignInForm from '../../SignInForm';
import TransitionsModal from '../../Modal';
import DialogLayout from '../../layout/DialogLayout';
import AccountSettingsMenu from '../../layout/AccountSettingsMenu';
import { useSelector } from 'react-redux';

// import logo from '../../../assets/images/logo_new.png'
import logo from '../../../assets/images/logonew.png'
import add_icon from '../../../assets/images/plus-circle-solid.svg'
import add_user from '../../../assets/images/add-user.svg'
import work_alt from '../../../assets/images/work-alt.svg'
import SignUpForm from '../../SignUpForm';
import ForgotPassForm from '../../ForgotPassForm';
import { useFindAllParentServicesQuery } from '../../../app/services/serviceApi';
import { OFFERS, JOBBER } from '../../../router';
import { returnCompleteSettings } from '../../../utilities';
import { dispatch } from '../../../app';
import { handleOpenMenuDialogLayout, handleOpenMoadalLayout } from '../../../app/features/authSlice';

const NavBar = () => {
    const { isLogged, user, openModal, openMenu } = useSelector(state => state?.auth)
    if (isLogged && user?.jobber_id !== null) returnCompleteSettings(isLogged, user)

    const [isOpen, setOpen] = useState(false)
    const setOpenModal = (bool) => dispatch(handleOpenMoadalLayout(bool))
    const [openForgot, setForgot] = React.useState(false);
    const [OpenUpForm, setOpenUpForm] = React.useState(false);
    const matches = useMediaQuery('(max-width:1000px)');
    const hideJobBtn = useMediaQuery('(max-width:730px)');
    const hideServBtn = useMediaQuery('(max-width:460px)');
    const reduceBtn = useMediaQuery('(min-width:920px)');

    const setOpenMenu = (bool) => dispatch(handleOpenMenuDialogLayout(bool))
    const [openDialog, setOpenDialog] = React.useState(false)

    useFindAllParentServicesQuery(undefined, { refetchOnReconnect: true, refetchOnMountOrArgChange: true })

    useEffect(() => {
        if (matches === false) {
            setOpen(false)
        }
        return () => { }
    }, [matches])


    return (
        <AppBar position='sticky' elevation={1} component={Box} sx={{ bgcolor: colors.common.white, borderWidth: 0, height: 70, mb: 3,backgroundColor:'#0f4d61' }} variant='outlined' >
            <Toolbar>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} flex={1} >
                    <Stack direction={"row"} spacing={0} alignItems={"center"} >
                        <Link className='link' to={"/"} >
                            <img alt={""} src={logo} style={{ width: 170 }} />
                        </Link>
                    </Stack>
                    <Stack direction={"row"} spacing={2} >
                        {
                            hideServBtn ? null : <NavBarButton reduceBtn={reduceBtn} onClick={() => setOpenDialog(true)} title={"Demander un service"} style={{ backgroundColor: '#0f4d61', }} iconLeft={<img alt={""} style={{ height: 20, width: 20 }} src={add_icon} />} />
                        }
                        {!isLogged &&
                            (hideJobBtn ? null : <Link className='link' to={`${JOBBER}`} >
                                <NavBarButton reduceBtn={reduceBtn} title={"Devenir prestataire"} style={{ backgroundColor: '#a6cc1f', }} iconLeft={<img alt={""} style={{ height: 20, width: 20 }} src={add_user} />} />
                            </Link>)
                        }
                        {isLogged &&
                            (hideJobBtn ? null : <Link className='link' to={`${OFFERS}`} >
                                <NavBarButton reduceBtn={reduceBtn} title={"Parcourirs les offres"} style={{ backgroundColor: '#0f4d61', }} iconLeft={<img alt={""} style={{ height: 20, width: 20 }} src={work_alt} />} />
                            </Link>)
                        }

                    </Stack>
                    {
                        matches ?
                            <IconButton onClick={() => setOpen(!isOpen)} edge='start' size='large'  aria-label='logo' sx={{color:'#fff'}} >
                                <MenuRoundedIcon fontSize='large' />
                            </IconButton> :
                            <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"center"} >
                                {isLogged && <AccountSettingsMenu className='link' />}

                                {!isLogged && <Typography className='link' onClick={() => setOpenModal(true)} sx={{ fontSize: 16, color: '#fff', fontWeight: '600' }} >
                                    S'identifier
                                </Typography>}
                            </Stack>
                    }
                </Box>
            </Toolbar>
            <motion.div
                layout='size'
                className="box"
                data-expanded={isOpen}
                style={{
                    height: 200,
                    width: '100%'                 
                }}
            >
                <Stack>
                    <Stack direction={"column"} alignItems={"flex-start"} spacing={0} >
                        <Link onClick={() => {
                            setOpen(false)
                        }} className='link' to={'/jobber'} >
                            <Button color={"primary"} sx={{ textTransform: 'none', fontSize: 16 }} >Devenir jobber</Button>
                        </Link>
                        {
                            hideServBtn ? <Button color={"primary"} onClick={() => { setOpenDialog(true); setOpen(false) }} sx={{ textTransform: 'none', fontSize: 16 }} >Demander un service</Button> : null
                        }
                        <Button color={"primary"} onClick={() => { setOpenModal(true); setOpen(false); }} sx={{ textTransform: 'none', fontSize: 16 }} >Connexion</Button>
                        {/* <Link onClick={() => {
                            setOpen(false)
                        }} className='link' to={'/profile'} >
                            <Button color={"primary"} sx={{ textTransform: 'none', fontSize: 16 }} >Profile</Button>
                        </Link> */}
                        {/* <Link onClick={() => {
                            setOpen(false)
                        }} className='link' to={'/inscription'} >
                            <Button color={"primary"} sx={{ textTransform: 'none', fontSize: 16 }} >Inscription</Button>
                        </Link> */}
                    </Stack>
                </Stack>
            </motion.div>
            <TransitionsModal open={openModal} setOpen={setOpenModal} >
                <SignInForm openModal={openModal} setOpenModal={setOpenModal} OpenUpModal={OpenUpForm} setOpenUpForm={setOpenUpForm} setForgot={setForgot} />
            </TransitionsModal>
            <TransitionsModal open={OpenUpForm} setOpen={setOpenUpForm} >
                <SignUpForm />
            </TransitionsModal>
            <TransitionsModal open={openDialog} setOpen={setOpenDialog} >
                <Stack spacing={3} my={2} direction={'column'} >
                    <NavBarButton
                        onClick={() => setOpenMenu(true)}
                        title={"Demander un service"} style={{ backgroundColor: '#0f4d61', }}
                        iconLeft={<img alt={""} style={{ height: 20, width: 20 }} src={add_icon} />}
                    />
                    <Link className='link' to={'/offrePage'} onClick={() => setOpenDialog(false)}>
                        <NavBarButton title={"Faire une offre/freelancer"} style={{ backgroundColor: '#a6cc1f', }} iconLeft={<img alt={""} style={{ height: 20, width: 20 }} src={work_alt} />} />
                    </Link>
                </Stack>
            </TransitionsModal>
            <DialogLayout open={openMenu} setOpen={setOpenMenu} />
            <ForgotPassForm openModal={openForgot} setOpenModal={setForgot} />
        </AppBar>
    )
}

export default NavBar