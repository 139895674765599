import React from 'react'
import TransitionsModal from '../Modal'
import UploadNICModal from '../UploadNICModal'

const UpdateNICModal = ({ open, setOpen }) => {

    return (
        <TransitionsModal open={open} setOpen={setOpen} >
            <UploadNICModal setOpen={setOpen} hide={true} />
        </TransitionsModal>
    )
}

export default UpdateNICModal