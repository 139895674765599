import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, METHODS } from ".";
import { dispatch } from "..";
import { handleServices } from "../features/authSlice";
import { baseQueryInterpolatorAuth, baseQueryInterpolatorPublic } from "./interceptor";

export const serviceApi = createApi({
  reducerPath: 'serviceApi',
  baseQuery: baseQueryInterpolatorAuth,
  tagTypes: ["services", "jobber-services"],
  endpoints: (builder) => ({
    findAllParentServices: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.SERVICES}`,
        }
      },
      providesTags: ["services"],
      keepUnusedDataFor: 2048,
      transformResponse: (response) => {
        dispatch(handleServices(response))
        return response;
      }
    }),

    addUpdateJobberService: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.JOBBER_SERVICE}`,
          method: `${METHODS?.POST}`,
          body,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),


  })
})

export const { useFindAllParentServicesQuery, useAddUpdateJobberServiceMutation } = serviceApi