import { Button, styled, useMediaQuery } from '@mui/material';
import React from 'react'
import { purple } from '@mui/material/colors';
import { Box } from '@mui/material';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    borderRadius: 25,
    paddingLeft: 15,
    paddingRight: 15,
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
}));



const NavBarButton = ({ onClick, title, iconLeft, style, }) => {
    const resizeBtnTxt = useMediaQuery('(min-width:920px)');
    const resizeBtn = useMediaQuery('(max-width:768px)');
    const hideIcon = useMediaQuery('(max-width:578px)');
    const resizeTxt = useMediaQuery('(max-width:320px)');

    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'center', height: resizeBtn ? 44 : 50, display: 'flex', }} >
            <ColorButton onClick={onClick} sx={[{ textTransform: 'none', fontWeight: '600', fontSize: resizeTxt ? 12 : resizeBtnTxt ? 14 : 'inherit' }, style]} startIcon={!hideIcon && iconLeft}>
                {title}
            </ColorButton>
        </Box>
    )
}

export default NavBarButton;