import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router'
import NavBar from '../common/AppBar'
import Footer from '../common/Footer'

const Layout = () => {
    return (
        <Box>
            <NavBar />
            <Box sx={{ flex: 1, height: '100%', width: '100%' }} >
                <Outlet />
            </Box>
            <Footer />
        </Box>
    )
}

export default Layout