import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import authSlice from "./features/authSlice";
import { authApi } from "./services/authApi";
import { userApi } from "./services/userApi";
import { countryApi } from './services/countryApi';
import { weekDayApi } from './services/weekDayApi';
import { serviceApi } from './services/serviceApi';
import { zoneInterventionApi } from "./services/zoneInterventionApi";
import { jobber_serviceApi } from "./services/jobber-serviceApi";
import { demandServiceApi } from "./services/demandServiceApi";
import { offreApi } from "./services/offreApi";
import { chatApi } from "./services/chatApi";
import chatSlice from "./features/chatSlice";
import offerSlice from "./features/offerSlice";


const store = configureStore({
    reducer: {

        [authSlice.name]: authSlice.reducer,
        [chatSlice.name]: chatSlice.reducer,
        [offerSlice.name]: offerSlice.reducer,

        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [countryApi.reducerPath]: countryApi.reducer,
        [weekDayApi.reducerPath]: weekDayApi.reducer,
        [serviceApi.reducerPath]: serviceApi.reducer,
        [zoneInterventionApi.reducerPath]: zoneInterventionApi.reducer,
        [jobber_serviceApi.reducerPath]: jobber_serviceApi.reducer,
        [demandServiceApi.reducerPath]: demandServiceApi.reducer,
        [offreApi.reducerPath]: offreApi.reducer,
        [chatApi.reducerPath]: chatApi.reducer,

    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(
            authApi.middleware,
            userApi.middleware,
            countryApi.middleware,
            weekDayApi.middleware,
            serviceApi.middleware,
            zoneInterventionApi.middleware,
            jobber_serviceApi.middleware,
            demandServiceApi.middleware,
            offreApi.middleware,
            chatApi.middleware,
            
        ),
})

setupListeners(store.dispatch)

export default store;