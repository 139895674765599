import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'

const ProfileItem = ({ title, icon, subTitle, to }) => {
    return (
        <Link className='link' to={to} >
            <Stack className='link' my={1} direction={"column"} justifyContent={"center"} alignItems={"flex-start"} flexDirection={"column"} >
                <div>
                    {icon}
                </div>
                <Typography color={"primary"} sx={{ fontFamily: 'Futura Md BT', fontWeight: '700', fontSize: 16, my: 1 }} >
                    {title}
                </Typography>
                <Typography sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', color: '#5D636A', fontSize: 14, }} >
                    {subTitle}
                </Typography>
            </Stack>

        </Link>

    )
}

export default ProfileItem