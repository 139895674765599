import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import React from "react";
import Home from "../pages/Home";
import Layout from "../components/layout/Layout";
import Inscription from "../pages/Inscription";
import Jobber from "../pages/Jobber";
import Competences from "../pages/Jobber/Competences";
import Disponibilites from "../pages/Jobber/Disponibilites";
import ZoneInterventions from "../pages/Jobber/ZoneInterventions";
import ProfilePhoto from "../pages/Jobber/ProfilePhoto";
import TypeDocs from "../pages/Jobber/TypeDocs";
import DocsDetails from "../pages/Jobber/DocsDetails";
import CompletedBooking from "../pages/CompletedBooking";
import {
  BLOG,
  BOOKING,
  COMPETENCE,
  COMPLETE_BOOKING,
  COMPRENDRE_JOBBING,
  CONFIRM_RESET_PWD,
  CONSEIL_JOBBING,
  DEMANDES,
  DEMANDE_SERVICE_ADDRESS,
  DISPONIBILITE,
  DOC_DETAILS,
  DOC_TYPE,
  EDIT_PROFILE,
  HOME,
  INSCRIPTION,
  INVITER_AMIS,
  JOBBER,
  JOBBER_PRO,
  JOB_AMOUNT,
  LOGIN_PAGE,
  MAP,
  OFFERS,
  OFFERS_JOBBER,
  PAYMENT,
  PRESTATAIRE,
  PROFILE,
  PROFILE_PHOTO,
  SETTINGS,
} from ".";
import Profile from "../pages/Profile";
import EditProfile from "../pages/EditProfile";
import JobAmount from "../pages/Jobber/JobAmount";
import IndexPage from "../pages/indexPage";
import ListDemandes from "../pages/Demandes/ListDemandes";
import Payment from "../pages/Payment";
import Chat from "../pages/Chat";
import Transactions from "../pages/Transaction";
import ParcourirOffres from "../pages/ParcourirOffres/index.jsx";
import OfffreDetails from "../pages/OfffreDetails";
import OffrePage from "../pages/OffreSteps";
import Booking from "../pages/Booking";
import ProfileIndex from "../pages/ProifileIndex";
import Loggin from "../pages/Loggin";
import OffreJobbers from "../pages/OffreJobbers";
import Blog from "../pages/Blog";
import InviterAmis from "../pages/InviterAmis";
import ComprendreJobbing from "../pages/ComprendreJobbing";
import JobberPro from "../pages/JobberPro";
import ConseilJobbing from "../pages/ConseilJobbing";
import ConfirmPassword from "../pages/ConfirmPassword";
import ConfirmOTPCode from "../pages/ConfirmOTPCode";
import Prestataire from "../pages/Prestataire";

const RouterNav = () => {
  return (
    <Router>
      <Routes>
        <Route path={`${HOME}`} element={<Layout />}>
          <Route index path="" exact element={<Home />} />
          <Route path={`${DEMANDE_SERVICE_ADDRESS}`} element={<IndexPage />} />
          <Route path={"/offrePage"} element={<OffrePage />} />
          <Route path={`${INSCRIPTION}`} element={<Inscription />} />
          <Route path={`${JOBBER}`} element={<Jobber />} />
          <Route path={`${COMPETENCE}`} element={<Competences />} />
          <Route path={`${DISPONIBILITE}`} element={<Disponibilites />} />
          <Route path={`${MAP}`} element={<ZoneInterventions />} />
          <Route path={`${PROFILE_PHOTO}`} element={<ProfilePhoto />} />
          <Route path={`${DOC_TYPE}`} element={<TypeDocs />} />
          <Route path={`${DOC_DETAILS}`} element={<DocsDetails />} />
          <Route path={`${COMPLETE_BOOKING}`} element={<CompletedBooking />} />
          <Route path={JOB_AMOUNT} element={<JobAmount />} />
          <Route path={`${PAYMENT}/:idService`} element={<Payment />} />
          <Route path={`${BOOKING}`} element={<Booking />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path={BLOG} element={<Blog />} />
          <Route path={INVITER_AMIS} element={<InviterAmis />} />
          <Route path={COMPRENDRE_JOBBING} element={<ComprendreJobbing />} />
          <Route path={JOBBER_PRO} element={<JobberPro />} />
          <Route path={CONSEIL_JOBBING} element={<ConseilJobbing />} />
          <Route path={PRESTATAIRE} element={<Prestataire />} />

          <Route path={`${OFFERS}`} element={<ParcourirOffres />} />
          <Route path={`${OFFERS}/views/:id`} element={<OfffreDetails />} />
          <Route path={`${OFFERS_JOBBER}`} element={<OffreJobbers />} />

          <Route path={SETTINGS} element={<ProfileIndex />}>
            <Route path={`${SETTINGS}${PROFILE}`} element={<Profile />} />
            <Route path={`${SETTINGS}${PROFILE}${EDIT_PROFILE}`} element={<EditProfile />} />
            <Route path={`${SETTINGS}${DEMANDES}`} element={<ListDemandes />} />
            <Route path={`${SETTINGS}/transactions`} element={<Transactions />} />
            <Route path={`${SETTINGS}/chat`} element={<Chat />} />
          </Route>
          <Route path={`${LOGIN_PAGE}`} element={<Loggin />} />
          <Route path={`${LOGIN_PAGE}/confirm`} element={<ConfirmOTPCode />} />
          <Route path={`${CONFIRM_RESET_PWD}/:code/:email`} element={<ConfirmPassword />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RouterNav;
