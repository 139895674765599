import { Box } from '@mui/material'
import React from 'react'
import ChatDiscussion from '../../components/ChatDiscussion'
import { Grid } from 'swiper'

const Chat = () => {
    return (
        <Box sx={{ height: '78vh', bgcolor: 'violet' }} >
            <ChatDiscussion />
        </Box>
    )
}

export default Chat