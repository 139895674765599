import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { colors } from '@mui/material';
import './styles.css'
import Typed from 'typed.js';
import { motion } from 'framer-motion';

const SearchButton = ({ openService }) => {
    // Create reference to store the DOM element containing the animation
    const el = React.useRef(null);
    // Create reference to store the Typed instance itself
    const typed = React.useRef(null);

    React.useEffect(() => {
        const options = {
            strings: [
                '<i>Menuisier</i>',
                '<strong>Electricite</strong>',
                '<strong>Menage</strong>',
                '<strong>Plomberie</strong>',
                '<strong>Renovation</strong>',
            ],
            typeSpeed: 50,
            backSpeed: 50,
            loop: true
        };

        // elRef refers to the <span> rendered below
        typed.current = new Typed(el.current, options);

        return () => {
            // Make sure to destroy Typed instance during cleanup
            // to prevent memory leaks
            typed.current.destroy();
        }
    }, [])

    return (
        <div className='row-flex btn-wrapper' onClick={openService} >
            <span style={{ whiteSpace: 'pre' }} ref={el} ></span>
            <motion.div whileTap={{ scale: 0.965 }} className='row-flex icon-wrapper'  >
                <SearchIcon fontSize='medium' color={"inherit"} sx={{ color: colors.common.white }} />
            </motion.div>
        </div>
    )
}

export default SearchButton