import React from 'react';

import { ChevronLeft, ExpandMore } from "@mui/icons-material"
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary } from '../Accordion';
import PriceSlider from '../PriceSlider';
import FilterListItem from '../FilterListItems';
import { useSelector } from 'react-redux';
import { dispatch } from '../../../app';
import { handleChangeSousMenu, handleServiceDemand } from '../../../app/features/authSlice';
import { getItem, setStorage, toJson, toString } from '../../../utilities';
import { useGetAllJobbersByPositionQuery } from '../../../app/services/jobber-serviceApi';


function SideFilter(props) {

  const { mainServices, sousMenu, demandService } = useSelector(state => state.auth)
  const [expanded, setExpanded] = React.useState('panel1');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedIndexSousMenu, setSelectedIndexSousMenu] = React.useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    dispatch(handleChangeSousMenu(index))
    handleSousListItemClick(event, 0)
  };

  const { isLoading } = useGetAllJobbersByPositionQuery(demandService, { refetchOnMountOrArgChange: true })
  const handleSousListItemClick = (event, index) => {
    const demandServices = toJson(getItem("dmdServices"))

    const { date, latitude, longitude } = demandServices
    const datas = { date: new Date(date).toJSON(), latitude, longitude }
    dispatch(handleServiceDemand({ ...datas }))
    setSelectedIndexSousMenu(index);
    dispatch(handleServiceDemand({ ...datas, ...{ service_id: sousMenu[index].id } }))
  };



  return (
    <div style={{ margin: `0 auto`, padding: `3px 10px`, width: '100%', }} >
      <Accordion
        expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header">
          <Typography sx={{ fontSize: 16, fontWeight: '600', fontFamily: 'Futura-Medium', color: '#000' }} >Types de services</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="nav" aria-label="secondary mailbox folder">

            {
              mainServices?.map((service, index) => (
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemText primary={service?.title} />
                </ListItemButton>
              ))
            }
          </List>
        </AccordionDetails>
      </Accordion>


      <Accordion
        expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ fontSize: 16, fontWeight: '600', fontFamily: 'Futura-Medium', color: '#000' }} >Prix</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PriceSlider />
        </AccordionDetails>
      </Accordion>


      <Accordion
        expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={{ fontSize: 16, fontWeight: '600', fontFamily: 'Futura-Medium', color: '#000' }} >Sous-categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="nav" aria-label="main mailbox folders">
            {
              sousMenu?.map((service, index) => (
                <ListItemButton
                  selected={selectedIndexSousMenu === index}
                  onClick={(event) => handleSousListItemClick(event, index)}
                >
                  <ListItemText primary={service?.title} />
                </ListItemButton>
              ))
            }
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default SideFilter;