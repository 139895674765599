import { colors, styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react'
import GoogleIcon from '@mui/icons-material/Google';
import { blue, } from '@mui/material/colors';
import GoogleLogin from 'react-google-login';
import { useGoogleLogin, } from '@react-oauth/google';

import googleIcon from '../../../assets/images/google-icon.svg'

const FButton = styled(LoadingButton)(({ theme }) => ({
    color: theme.palette.getContrastText(blue[600]),
    borderRadius: 8,
    border: '1px solid lightgrey',
    height: 45,
    paddingLeft: 15,
    paddingRight: 15,

}));

const FaceButton = ({ loading, onClick }) => {
    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => console.log(tokenResponse),
        onError: err => console.log('google error', err)
    });
    return (
        <FButton loading={loading} onClick={googleLogin} sx={{ textTransform: 'none', fontWeight: '600', width: '100%', color: '#4885ed' }}  >
            <img src = {googleIcon} alt = 'google icon' style={{ height: 32, width: 32, marginRight: 6 }} /> <span></span> Continuer avec Google 
        </FButton>

    )
}

export default FaceButton