import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, METHODS } from ".";
import { getId } from "../../utilities";
import { baseQueryInterpolatorAuth } from "./interceptor";
import { dispatch } from '..';
import { handleMyDemands } from "../features/authSlice";

export const demandServiceApi = createApi({
  reducerPath: 'demandServiceApi',
  baseQuery: baseQueryInterpolatorAuth,
  tagTypes: ["services", "jobber-services", "demandService"],
  endpoints: (builder) => ({

    demandServiceByUser: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.DEMAND_SERVICE}`,
          method: `${METHODS?.POST}`,
          body,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

    demandServices: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.DEMAND_SERVICE}/${getId()}/services`,
        }
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: 'demandService', id: "LIST" }],
      transformResponse: (response) => {
        dispatch(handleMyDemands(response))
        return response;
      }
    }),



  })
})

export const {
  useDemandServiceByUserMutation,
  useDemandServicesQuery,
  
} = demandServiceApi