import React from 'react'
import ServiceMainItem from '../common/ServiecMainItem';
import { Grid } from '@mui/material';

import { useSelector } from 'react-redux';

const ServiceMainList = ({ openService }) => {
    const { mainServices } = useSelector(state => state.auth)
    return (
        <Grid container justifyContent={"center"} flexDirection="row" alignItems={"center"} my={3} spacing={2} flexWrap="wrap" >
            {mainServices?.map((service, i) => (
                <Grid key={i} item xs={12} sm={12} md={6} lg={4} >
                    <ServiceMainItem title={service?.title} icon={service?.url} onClick={openService} />
                </Grid>
            ))}
        </Grid>
    )
}

export default ServiceMainList