import { Alert, Box, Button, Checkbox, colors, Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Stack, Typography } from '@mui/material'
import React from 'react'
import JButton from '../common/Button'
import Input from '../Input'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FaceButton from '../common/FacebookBtn';
import { useSignUpClientMutation } from '../../app/services/authApi';
import { CheckCircleOutline, Height } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import Select, { SelectChangeEvent } from '@mui/material/Select';

const SignUpForm = () => {
    const [signUpClient, { isLoading, isSuccess }] = useSignUpClientMutation()
    const [values, setValues] = React.useState({
        email: '',
        password: '',
        username: '',
        fisrtname:"",
        lastname:"",
        showPassword: false,
    });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        const { email, username, password,firstname, lastname } = values
        const t = new Date(new Date(birthDate).getTime()).toJSON()
        console.log({ email, username, password,firstname, lastname, sex, birthDate:t })
        signUpClient({ email, username, password,firstname, lastname, sex, birthDate:t }).unwrap().then(() => {

        })
    }
    const [birthDate, setBirthDate] = React.useState(new Date())
    const [sex, setSex] = React.useState("M")
    const date = (value) =>{ 
        setBirthDate(value);
        console.log(value)
    }
    const handleSex = (e) => {
        setSex(e.target.value);
      };

    return (
        <Box>
            <Stack spacing={0.9} >
                <Typography variant='h3' sx={{ fontFamily: 'catamaran', fontWeight: '700', fontSize: 30 }} >
                    Créer un compte!
                </Typography>
                <Typography variant='inherit' sx={{ fontSize: 18, color: '#333' }} >
                    Profitez de votre temps libre grâce à nos jobbers
                </Typography>
                <FaceButton />
                <Divider>OU</Divider>
                {/* <Input
                    placeholder={"Prénom"}
                    type="text"
                    leftIcon={<PersonOutlineOutlinedIcon color={colors.grey[500]} />}
                    onChange={handleChange('premom')}
                /> */}
                <Input
                    placeholder={"Nom"}
                    type="text"
                    leftIcon={<Person2OutlinedIcon color={colors.grey[500]} />}
                    onChange={handleChange('firstname')}
                />
                 <Input
                    placeholder={"Prenom"}
                    type="email"
                    leftIcon={<PersonOutlineOutlinedIcon color={colors.grey[500]} />}
                    onChange={handleChange('lastname')}
                />
                <FormControl style={{ marginTop:13}}>
                <InputLabel id="demo-simple-select-label">Sexe</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sex}
                    label="Sex"
                    sx={{height:42}}
                    onChange={handleSex}
                >
                    <MenuItem value={"F"}>Femme</MenuItem>
                    <MenuItem value={"M"}>Homme</MenuItem>
                </Select>
                </FormControl>
                <label for="date">Date de naissance</label>
                <DatePicker style={{justifyContent:'flex-start', width:500}} id={"date"}  className='custom-input' selected={birthDate} onChange={date} />
                <Input
                    placeholder={"Nom d'utilisateur"}
                    type="text"
                    leftIcon={<Person3OutlinedIcon color={colors.grey[500]} />}
                    onChange={handleChange('username')}
                />
                <Input
                    placeholder={"Email"}
                    type="email"
                    leftIcon={<EmailOutlinedIcon color={colors.grey[500]} />}
                    onChange={handleChange('email')}
                />
                
                <Input
                    placeholder={"Mot de passe"}
                    type={values.showPassword ? 'text' : 'password'}
                    leftIcon={<LockOutlinedIcon color={colors.grey[500]} />}
                    rightIcon={
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {values?.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    }
                    onChange={handleChange('password')}
                />
                <Stack
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems='flex-start'
                    justifyContent={"flex-start"}
                    spacing={0}
                >

                    {/* <FormControlLabel sx={{ color: colors.grey[600], alignSelf: 'center' }} control={<Checkbox defaultChecked />} label="Je souhaite être informé des nouveautés, cadeaux et bons plans" />
                    <FormControlLabel sx={{ color: colors.grey[600], alignSelf: 'center' }} control={<Checkbox defaultChecked />} label="Je souhaite recevoir les bons plans des partenaires" /> */}

                </Stack>
                {isSuccess && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert variant='filled'
                        iconMapping={{
                            success: <CheckCircleOutline fontSize="inherit" />
                        }}>
                        Success registration.
                    </Alert>
                </Stack>
                }
                <JButton title={"Je m'inscris"} loading={isLoading} onClick={handleSubmit} />

                <Typography variant='inherit' >
                    En vous inscrivant vous acceptez les <span style={{ color: '#507B92' }} >conditions générales</span> et la <span style={{ color: '#507B92' }}>politique de confidentialité</span>
                </Typography>

            </Stack>
        </Box>
    )
}

export default SignUpForm