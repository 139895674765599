import { Box, Typography } from '@mui/material'
import React from 'react'
import './styles.css'
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';

const CheckListItem = ({ onClick, title, index, id }) => {
    const { indexComp, selected } = useSelector(state => state.auth)
    return (
        <div >
            <Box className='row-flex wrapperL-box' onClick={onClick} >
                <Checkbox checked={index === indexComp && selected === id ? true : false} />
                <Box className='row-flex-space flexL-box'>
                    <Typography sx={{ fontFamily: 'catamaran', fontWeight: '500', color: '#25282c', }} >
                        {title}
                    </Typography>
                    {/* <ArrowForwardIosIcon fontSize='small' color={colors.grey[500]} /> */}
                </Box>
            </Box>
        </div>
    )
}

export default CheckListItem