import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, METHODS } from ".";
import { getJobberId } from "../../utilities";
import { baseQueryInterpolatorAuth } from "./interceptor";

export const weekDayApi = createApi({
  reducerPath: 'weekDayApi',
  baseQuery: baseQueryInterpolatorAuth,
  tagTypes: ["disponibilities"],
  endpoints: (builder) => ({
    addPlanningToJobber: builder.mutation({
      query(body) {
        return {
          url: `${ENDPOINTS?.DISPONIBILITIES}`,
          body: { jobber_id: getJobberId(), ...body },
          method: `${METHODS?.POST}`,
        }
      },
      extraOptions: { maxRetries: 0 },
    }),

  })
})

export const { useAddPlanningToJobberMutation } = weekDayApi