import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import './styles.css'

import user_logo from '../../assets/images/user-svgrepo-com.svg'
import ProfileItemList from '../../components/ProfileItemList';
import { useFindJobberProfileQuery, useFindUsersProfileQuery, useUpdateUserPhotoMutation } from '../../app/services/userApi';
import { useSelector } from 'react-redux';

const Profile = () => {
  const [updateUserPhoto] = useUpdateUserPhotoMutation()
  const handleChangeImage = e => {
    const image = e.target.files[0]
    let data = new FormData()
    data.append("file", image)
    updateUserPhoto(data)
  }
  const upload = () => {document.getElementById("img").click()}
  const { jobberId, isLogged } = useSelector(state => state.auth)
  useFindJobberProfileQuery(null, { skip: !(isLogged === true && jobberId != null) })
  const {data } = useFindUsersProfileQuery(undefined, { refetchOnFocus: true, refetchOnMountOrArgChange: true, refetchOnReconnect: true })

  return (
    <Box sx={{ mt: 2.3, flex: 1 }} >
      <Container maxWidth={"xl"} >
        <Stack my={2} direction={{ sm: 'column', md: 'row' }} width={"100%"} spacing={2} justifyContent={{ sm: 'center', md: 'flex-start' }} alignItems={{ sm: 'flex-start', md: 'center' }} >
          <Box className='user-logo-wrapper'>
            <input style={{ display: 'none' }} type="file" id="img" name="img" accept="image/*" onChange={handleChangeImage} />
            {data && data?.profile !== null && <img className='user-logo-img' src={data?.profile} alt="logo uer" />}
            {data && data?.profile===null && <img className='user-logo-img' src={user_logo} alt="logo uer" />}
            <div className='row-flex take-photo' onClick={upload}>
              <AddAPhotoOutlinedIcon color='inherit' fontSize='small' sx={{ color: '#fff' }} />
            </div>
          </Box>
          <Typography sx={{ fontFamily: "Futura-Medium", fontSize: 28, fontWeight: '700', color: 'black' }} >
            { data && `${data?.user?.firstname} ${data?.user?.lastname}`}
          </Typography>
        </Stack>
        <Typography sx={{ fontFamily: "Futura-Medium", fontSize: 20, fontWeight: '700', color: 'black', my: 2 }} >
          Gérer mon compte
        </Typography>
        <ProfileItemList />
      </Container>
    </Box>
  )
}

export default Profile