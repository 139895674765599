import { Box, Checkbox, Container, Typography } from '@mui/material'
import React from 'react'
import JButton from '../../components/common/Button'
import CheckListItem from '../../components/common/CheckListItem '
import { useSelector } from 'react-redux';
import { dispatch } from '../../app'
import { handleAllDisponibilities } from '../../app/features/authSlice'
import { useAddPlanningToJobberMutation } from '../../app/services/weekDayApi'
import { HOME, MAP } from '../../router';

const Disponibilites = () => {
  const { weedays, disponibility, AllDisponibilities, jobberId } = useSelector(state => state.auth)
  if(jobberId===null) window.location.href = `${HOME}`
  const [addPlanningToJobber, { isLoading }] = useAddPlanningToJobberMutation()
  const saveDisponibility = () => addPlanningToJobber(disponibility).unwrap().then(() =>{window.location.href= `${MAP}`})
  const checkAll = () => dispatch(handleAllDisponibilities())

  return (
    <Box className='container' >
      <Container maxWidth='sm' >
        <Typography sx={{ fontSize: 22, fontWeight: '700', fontFamily: 'catamaran', color: '#333' }} >
          Remplissez vos disponibilités
        </Typography>
        <Typography sx={{ fontSize: 18, fontWeight: '400', fontFamily: 'catamaran', color: '#333' }} >
          Sélectionnez tout ?
        </Typography>
        <Checkbox
          inputProps= { {'aria-label': 'Checkbox demo'} }
          checked={AllDisponibilities}
          onChange={checkAll}
        />
        {
          weedays?.map((week, i) => {
            return (
              <CheckListItem title={week?.title} key={i} index={i} checked={week?.value} />
            )
          })
        }
          <JButton loading={isLoading} title={"Continuer"} onClick={saveDisponibility} />
     </Container>
    </Box>
  )
}

export default Disponibilites