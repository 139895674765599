import { createTheme, ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import Router from "./router/Router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useSelector } from 'react-redux';
import { useSettingsJobberQuery } from "./app/services/userApi";

const myTheme = createTheme({
  palette: {
    primary: {
      main: "#0f4d61",
      light: "#0f4d6190",
    },
    secondary: {
      main: "#a6cc1f",
    },
  },
  typography: {
    fontFamily: "'Futura' sans-serif",
  },
});


function App() {
  const { isLogged, jobberId } = useSelector(state => state?.auth)
  useSettingsJobberQuery(null, { skip: !(isLogged === true && jobberId !== null) })

  return (
    <GoogleOAuthProvider
      clientId={`679857466919-3fmsn6je4q9b1sl8nntdighpe4f2hpn2.apps.googleusercontent.com`}
    >
      <ThemeProvider theme={myTheme}>
        <Router />
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
