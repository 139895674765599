import { Box, Container } from '@mui/material'
import React, { useState } from 'react'
import TipsInfo from '../../components/common/TipsInfo'
import Input from '../../components/Input'
import { handleAddressLgLt } from '../../app/features/authSlice'
import { dispatch } from '../../app/index';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';

const AddressPrestation = () => {
    const [Places, setPlaces] = useState(/** @type google.maps.places.Autocomplete */(null))
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ["places"]
    })
    const handlingMapData = (place) => setPlaces(place)
    const handlingPlaceChange = (e) => {
        const place = Places.getPlace()
        if (place) {
            const { formatted_address, geometry } = place
            const latitude = geometry.location.lat()
            const longitude = geometry.location.lng()
            // const latitude = (geometry?.viewport?.Wa?.hi + geometry?.viewport?.Wa?.lo) / 2
            // const longitude = (geometry?.viewport?.Ja?.hi + geometry?.viewport?.Ja?.lo) / 2
            dispatch(handleAddressLgLt({ address: formatted_address, longitude, latitude }))
        }
    }
    if (!isLoaded) {
        return <p>Loading</p>
    }
    return (
        <Box  >
            <Container maxWidth='sm' >
                <Autocomplete onLoad={handlingMapData} onPlaceChanged={handlingPlaceChange} >
                    <Input style={{ width: '100%' }} placeholder={"Entrer l'addresse"} />
                </Autocomplete>
                <TipsInfo text={"Ces information seront transmises uniquement aux prestataires que vous réservez"} />
            </Container>
        </Box>
    )
}

export default AddressPrestation