import { Box, Container, Divider, Grid, Stack, Typography, colors, useMediaQuery } from '@mui/material'
import React from 'react'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PhoneIcon from '@mui/icons-material/Phone';
import Person2Icon from '@mui/icons-material/Person2';
import './styles.css'
import TransitionsModal from '../../components/Modal';
import JButton from '../../components/common/Button';
import { useParams } from 'react-router';
import { useAllJobberPropositionsByOfferIdQuery, useEmitPropositionByJobberMutation, useGetEmitOfferByUserQuery, useOfferByIdQuery } from '../../app/services/offreApi';
import { days, getJobberId } from '../../utilities';
import { DEMANDES, SETTINGS } from '../../router';
import Input from '../../components/Input';
import EmailOutlined from '@mui/icons-material/EmailOutlined';

const OfffreDetails = () => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const { id } = useParams()
    const { data: offer} = useOfferByIdQuery(id)
    const [amount, setAmount] = React.useState(0)
    const handleAmount = (e) => setAmount(e.target.value)
    const {data:propositions} = useAllJobberPropositionsByOfferIdQuery(offer?.id)
    const {data: offers } = useGetEmitOfferByUserQuery(offer?.user?.id)
    const [emitPropositionByJobber , {isLoading:isLoadingProps} ] = useEmitPropositionByJobberMutation()
    const handlePropositionByJobber =() => {
        const data = {jobber_id: getJobberId(), offer_id: offer?.id, price: amount===0 ?offer?.amount : amount}
        emitPropositionByJobber(data).unwrap().then(() =>{
            window.location.href = `${SETTINGS}${DEMANDES}`
        })
    }

    const matches = useMediaQuery('(max-width:900px)');


    return (
        <Box className='container' style={{ flex: 1 }} >
            <Container maxWidth='xl' >
                <Stack my={2} justifyContent={'space-between'} alignItems={'center'} px={2} height={56} bgcolor={'#0f4d61'} direction={{ md: 'row', sm: 'row', xs: 'column' }} >
                    <Typography sx={{ color: '#fff', fontWeight: '600', fontFamily: 'Futura-Medium', fontSize: 18 }} >
                       {offer?.title}
                    </Typography>
                    <Typography sx={{ color: '#fff', fontWeight: '600', fontFamily: 'Futura-Medium', fontSize: 18 }} >
                       {offer?.amount} XAF/ offre Moyenne
                    </Typography>
                </Stack>

                <Grid container direction={'row'} justifyContent={{ md: 'space-between' }}  >
                    <Grid className='head-bar' item xs={12} sm={12} md={8.4} >
                        <Typography sx={{ fontWeight: '600', color: '#000', fontFamily: 'Futura-Medium', my: 3 }} >
                            Job description
                        </Typography>
                        <Typography sx={{ fontWeight: '500', color: '#000', fontFamily: 'Futura-Medium', mb: 2, fontSize: 14, width: '70%' }} >
                            {offer?.description}
                        </Typography>
                        <Stack mb={2} direction={{ sm: 'column', md: 'row' }} spacing={1} >
                            <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Medium' }} >
                                Compétences :
                            </Typography>
                            <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Medium', color: '#0f4d61' }} >
                                {offer?.service?.title}
                            </Typography>
                        </Stack>
                        <Stack mb={2} direction={{ sm: 'column', md: 'row' }} spacing={1} >
                            <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Medium' }} >
                                Concernant le client :
                            </Typography>
                            <Stack direction={'row'} spacing={1} >
                                <Typography fontSize={14} >
                                    Douala, Cameroun
                                </Typography>
                                <MailOutlineIcon fontSize='small' color='primary' />
                                <PhoneIcon color='primary' fontSize='small' />
                                <Person2Icon color='primary' fontSize='small' />
                            </Stack>
                        </Stack>
                        <Stack mb={2} direction={{ sm: 'column', md: 'row' }} spacing={1} >
                            <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Medium' }} >
                                Numero du Projet:
                            </Typography>
                            <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Medium', color: '#0f4d61' }} >
                                {offer?.id}{offer?.service?.id}
                            </Typography>
                        </Stack>
                        <Divider sx={{ backgroundColor: 'lightgrey', my: 2 }} />
                        <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Medium', mb: 3 }} >
                            Proposer de travailler sur cet emploi maintenant ! La période s’acheve dans {days(offer?.expiredAt)} jours
                        </Typography>
                        <Grid container spacing={1} my={2} alignItems={'center'}>
                            <Grid item sm={12} md={3} container direction={'column'}   >
                                <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Medium', textAlign: 'center' }} >
                                    Numero du Projet: {offer?.id}{offer?.service?.id}
                                </Typography>
                                <Input
                                    placeholder={"Votre offre"}
                                    type="number"
                                    min={offer?.amount} value={amount} onChange={handleAmount}
                                    rightIcon={
                                        <Box className='cfa-div' sx={{width: "40%"}} >
                                            <Typography textAlign={'center'} width={'100%'} >{offer?.amount} XAF</Typography>
                                        </Box>
                                    }
                                    style={{borderRadius: 0, height: 45}}
                                />
                            </Grid>
                           
                            <Grid item sm={12} md={3} container direction={'column'} justifyContent={'center'} sx={{marginTop:3}} >
                                <button className='offre-demand' onClick={() => setOpenDialog(true)} >
                                    Faire une offre
                                </button>
                            </Grid>
                            <Grid item sm={12} md={3} container direction={'column'} justifyContent={'center'} sx={{marginTop:3}} >
                                <button className='offre-cancel' >
                                    Annuler
                                </button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid p={3} className='head-bar' item xs={12} sm={12} md={3} >
                        <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Medium', textAlign: 'center', }} >
                            Autres Offres de ce client
                        </Typography>
                        <Divider sx={{ backgroundColor: 'lightgrey', my: 2 }} />
                            {
                                offers?.length <=0 ? 
                                <Stack direction={'row'} spacing={2} my={1} >
                                    <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Medium', textAlign: 'left', }} >
                                        Aucune Autre offre
                                    </Typography>
                                 </Stack> : 
                                 offers?.map((otherOffer, i) => (
                                    <Stack direction={'row'} spacing={2} my={1} key={i+"otherOffer"} >
                                        <ArrowRightIcon />
                                        <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Medium', textAlign: 'left', }} >
                                            {otherOffer?.title}
                                        </Typography>
                                    </Stack>
                                 ))
                            }

                        <Divider sx={{ backgroundColor: 'lightgrey', mt: 10, mb: 2 }} />
                        <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Md-Bt', color: '#a6cc1f', textAlign: 'center', fontSize: 19 }} >
                            {propositions?.length} personnes ont souscris à L’offre
                        </Typography>
                    </Grid>
                </Grid>
                <TransitionsModal open={openDialog} setOpen={setOpenDialog} >
                    <Typography sx={{ fontWeight: '600', fontFamily: 'Futura-Md-Bt', textAlign: 'center', fontSize: 19 }} >
                        Confirmer l’offre ?
                    </Typography>
                    <Stack spacing={3} my={2} direction={'row'} >
                        <JButton loading={isLoadingProps} style={{ borderRadius: 30, bgcolor: '#a6cc1f' }} onClick={handlePropositionByJobber} title={"OK"} />
                        <JButton style={{ borderRadius: 30, bgcolor: '#0f4d61' }} title={"Annuler"} />
                    </Stack>
                </TransitionsModal>
            </Container>
        </Box>
    )
}

export default OfffreDetails