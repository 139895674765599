import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, METHODS } from ".";
import { getId } from "../../utilities";
import { baseQueryInterpolatorAuth } from "./interceptor";
import { dispatch } from '..';
import { handleChatMessages, handleTheRoom, handleCanFetchMessages } from "../features/chatSlice";

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: baseQueryInterpolatorAuth,
  tagTypes: ["Chats"],
  endpoints: (builder) => ({
    AllMessageOfRoom: builder.query({
      query(id) {
        return {
          url: `${ENDPOINTS?.CHAT}/${id}/messages`,
        }
      },
      transformResponse: (response, meta, id) => {
        dispatch(handleChatMessages({response, roomId:id}))
        return response
      },
      // providesTags: (resp,meta, id) => [{type: "Chats", id}],
      // keepUnusedDataFor: 0,
    }),

    allRoomsOfUser: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.CHAT}/${getId()}`,
        }
      },
    }),

    roomWithUser: builder.query({
      query(toUserId) {
        return {
          url: `${ENDPOINTS?.CHAT}/get/${getId()}/with/${toUserId}`,
        }
      },
      transformResponse: (response) => {
        dispatch(handleTheRoom(response))
        dispatch(handleCanFetchMessages(false))
        return response;
      },
      keepUnusedDataFor: 0,
    }),

    addRoomWithUser: builder.mutation({
      query(toUserId) {
        return {
          url: `${ENDPOINTS?.CHAT}/add/${getId()}/with/${toUserId}`,
          method: METHODS?.POST
        }
      },
    }),


  })
})

export const {
  useAddRoomWithUserMutation,
  useAllMessageOfRoomQuery,
  useAllRoomsOfUserQuery,
  useRoomWithUserQuery,

} = chatApi