import { COMPETENCE, DISPONIBILITE, EDIT_PROFILE, HOME, JOB_AMOUNT, MAP, PROFILE, PROFILE_PHOTO, SETTINGS } from './router';

const CryptoJS = require("crypto-js")
const password = 'l5JmP+G0/1zB%;r8B8?2?2pcqGcL^3:YoUsHoP2022$%4&&*()_++!@`@!`'
const tokenType = 'Bearer '
const keyAuth = 'ajeujiots'
export const crypted = (text) => CryptoJS.AES.encrypt(text, password)
export const decrypted = (text) => (CryptoJS.AES.decrypt(text, password)).toString(CryptoJS.enc.Utf8)
export const isRealValue = (obj) => obj && obj !== null && obj !== undefined
export const toString = (json) => JSON.stringify(json)
export const toJson = (string) => JSON.parse(string)
export const cryptKey = (key) => key
export const saveItem = (key, value) => localStorage.setItem(cryptKey(key), crypted(value))
export const getItem = (key) => !isRealValue(localStorage.getItem(key)) ? null : decrypted((localStorage.getItem(key))?.toString())
export const setAuthUser = (auth) => saveItem(keyAuth, toString(auth))
export const getAuth = () => toJson(getItem(cryptKey(keyAuth))) || null
export const updateAuth = (accessToken) => { let auth = getAuth(); auth.accessToken = accessToken; setAuthUser(auth); }
export const getUser = () => getAuth()?.user
export const getId = () => getUser()?.id
export const getJobberId = () => getUser()?.jobber_id || null
export const getToken = () => getAuth()?.accessToken
export const getRefreshToken = () => getAuth()?.refreshToken
export const isLogged = () => isRealValue(getAuth())
export const authorization = () => getToken()
export const refreshToken = () => getRefreshToken()
export const clearStorage = () => localStorage.clear()
export const compare = (a, b) => (a < b) ? -1 : (a > b) ? 1 : 0
export const compareEquals = (a, b) => (isRealValue(a) && isRealValue(b)) ? ((a === b) ? true : false) : false
export const providesList = (results, tagType) => results ? [...results.map((item) => ({ type: tagType, id: item?.key })), { type: tagType, id: 'LIST' }] : [{ type: tagType, id: 'LIST' }]
export const providesKeyList = (results, tagType) => results ? [{ type: tagType, id: 'LIST' }, ...results.map((key) => ({ type: tagType, id: key })),] : [{ type: tagType, id: 'LIST' }]
export const diffForHumanDate = (date) => new Date(date).toDateString()
export const diffLocalDate = (date) => new Date(date).toLocaleDateString()
export const serverHost = () => window.location.host
export const serverHostname = () => window.location.hostname
export const serverHref = () => window.location.href
export const serverOrigin = () => window.location.origin
export const serverPathname = () => window.location.pathname
export const serverPort = () => window.location.port
export const serverProtocol = () => window.location.protocol
export const serverSearch = () => window.location.search
export const capitalizeFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);
export const capitalize = (string) => string.toUpperCase()
export const getFakeName = (name) => { let worlds = name?.trim()?.split(" "); return worlds?.at(0) + " " + worlds?.at(1); }
export const getStorage = (key) => !isRealValue(localStorage.getItem(key)) ? null : localStorage.getItem(key)
export const setStorage = (key, value) => localStorage.setItem(key, value)
export const deleteItem = (key) => localStorage.removeItem(key)

export const setSetting = (setting) => {
  var auth = getAuth()
  auth.user.settings = setting
  setAuthUser(auth)
}

export const getObjectAsSearchParameter = (jsonObject) => {
  let str = '', i = 0;
  if (isRealValue(jsonObject)) {
    for (const [key, value] of Object.entries(jsonObject)) {
      let param = `${key}=${value}`
      i === 0 ? str = `?${param}` : str += `&${param}`
      i++
    }
  }
  return str
}

var photo = null
export function setPhoto(file) { photo = file }
export function getPhoto() { return photo }


export function returnCompleteSettings(isLogged, user) {
  if (isLogged === true) {
    const path = window.location.pathname
  
    if (user?.settings?.hasDisponibility === false) {
      if (path === DISPONIBILITE) return;
      window.location.href = DISPONIBILITE; return;
    }

    if (user?.settings?.hasZoneIntervention === false) {
      if (path === MAP) return;
      window.location.href = MAP; return;
    }
    if (user?.settings?.hasPhoto === false) {
      if (path === PROFILE_PHOTO) return;
      window.location.href = PROFILE_PHOTO; return;
    }
    if (user?.settings?.hasService === false) {
      if (path === COMPETENCE || path === JOB_AMOUNT) {
        if(path === COMPETENCE) return;
        if(path === JOB_AMOUNT) return;
      }
      window.location.href = COMPETENCE; return;
    }
    // if (user?.settings?.hasService === true) {
    //   if (path === JOB_AMOUNT) window.location.href = HOME;
    //  return;
    // }
    // if (user?.settings?.hasPhotocni === false) {
    //   if (path === `${SETTINGS}${PROFILE}/${EDIT_PROFILE}`) return;
    //   window.location.href = `${SETTINGS}${PROFILE}/${EDIT_PROFILE}`; return;
    // }
    return
  } else return
}

export function days(date){
  const day = 1000 * 3600 * 24
  let numberDay = new Date(date).getTime() - new Date().getTime()
  return parseInt(numberDay/day)
}