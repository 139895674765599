
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { LinearProgress, Typography } from '@mui/material';
import { StyledBadge } from './style';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loggedOut } from '../../../app/features/authSlice';
import { getUser } from '../../../utilities';
import user_icon from '../../../assets/images/user-avatar-filled.svg'
import { useFindJobberProfileQuery } from '../../../app/services/userApi';
import { DEMANDES, HOME, PROFILE, SETTINGS } from '../../../router';
import { useSingOutAllSessionsMutation } from '../../../app/services/authApi';

const AccountSettingsMenu = () => {
    const { profile, isLogged, jobberId } = useSelector(state => state.auth)
    useFindJobberProfileQuery(null, { skip: !(isLogged === true && jobberId != null) })
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [singOutAllSessions, { isLoading: isLoadingOut }] = useSingOutAllSessionsMutation()

    const goTo = (to, options) => {
        handleClose()
        navigate(to)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function stringAvatar(name) {
        let blocks = name.split(' '), children = `${blocks[0][0]}`;
        if (blocks?.length > 1) children += `${blocks[1][0]}`
        children = children.toUpperCase()
        return { children }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const signout = () => {
        singOutAllSessions().unwrap().then((resp) => {
            dispatch(loggedOut())
            goTo(HOME)
        })
    }

    return (
        <>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >
                        {
                            profile?.infos?.photo?.url ? <Avatar sx={{ width: 32, height: 32 }} src={`${profile?.infos?.photo?.url}`} /> :
                                <Avatar sx={{ width: 32, height: 32 }} {...stringAvatar(`${getUser()?.username}`)} />
                        }
                    </StyledBadge>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <div style={{ alignItems: 'center', justifyContent: 'center', textAlign: "center", padding: '10px', }}>
                    <div >
                        {
                            profile?.infos?.photo?.url ? <img style={{ borderRadius: 50, width: "70px", height: "70px" }} src={`${profile?.infos?.photo?.url}`} alt={"profile"} /> :
                                <img style={{ borderRadius: 50, width: "70px", height: "70px" }} src={user_icon} alt={"profile"} />
                        }
                    </div>
                    <Typography className='link-text' sx={{ fontSize: 14, fontFamily: "Futura", fontWeight: 'bold' }} >{getUser()?.username}</Typography>
                    <Typography className='link-text' sx={{ fontSize: 17, fontFamily: "Futura", fontWeight: '400' }} >{getUser()?.email}</Typography>
                </div>
                <MenuItem onClick={() => goTo(`${SETTINGS}${PROFILE}`)} sx={{ fontSize: 14, fontFamily: "Futura" }}>
                    {
                        profile?.infos?.photo?.url ? <Avatar sx={{ width: 25, height: 25 }} src={`${profile?.infos?.photo?.url}`} /> :
                            <Avatar  {...stringAvatar(`${getUser()?.username}`)} />
                    }
                    Profile
                </MenuItem>
                <MenuItem onClick={() => goTo(`${SETTINGS}${PROFILE}`)} sx={{ fontSize: 14, fontFamily: "Futura" }}>
                    <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => goTo(`${SETTINGS}${DEMANDES}`)} sx={{ fontSize: 14, fontFamily: "Futura" }}>
                    <Avatar /> Mes demandes
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={signout}
                    sx={{ fontSize: 14, fontFamily: "Futura" }}
                    disabled={isLoadingOut}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
                {isLoadingOut && <LinearProgress color="secondary" />}
            </Menu>
        </>
    )
}
export default AccountSettingsMenu