import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import HeurePrestations from '../pages/HeurePrestations';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { getId } from '../utilities';
import { useDemandServiceByUserMutation } from '../app/services/demandServiceApi';
import JobDesc from '../pages/OffreSteps/jobDesc';
import OffreAmount from '../pages/OffreSteps/offreAmount';
import OffreService from '../pages/OffreSteps/offreService';
import DelaisOffre from '../pages/OffreSteps/DelaisOffre';
import { useCreateOffreMutation } from '../app/services/offreApi';
import { HOME, LOGIN_PAGE } from '../router';


const steps = [
    {
        label: "Entrer votre job description",
        description: <JobDesc />,
    },
    {
        label: "Entrer votre budget",
        description: <OffreAmount />,
    },
    {
        label: " Entrer un délai pour l'offre ?",
        description: <DelaisOffre />
    },
    {
        label: "Choisir le service ?",
        description: <OffreService />
    },
];

export default function OffreSteps() {
    const { emitOffer } = useSelector(state => state?.offers)
    const { isLogged } = useSelector(state => state.auth)
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps?.length;
    const [createOffre, { isLoading }] = useCreateOffreMutation()

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === maxSteps - 1) {
            const data = { ...emitOffer, expiredAt: new Date(emitOffer?.expiredAt).toJSON()}
            if(!isLogged) window.location.href = `${LOGIN_PAGE}`
            else
            createOffre(data).unwrap().then((resp) => {
                window.location.href = `${HOME}`
            })
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ width: '100%', flexGrow: 1 }}>
            <Paper
                elevation={0}
                sx={{
                    pl: 2,
                    bgcolor: 'background.default',
                }}
            >
                <Typography sx={{ fontSize: 18, fontWeight: '700', fontFamily: 'Futura-Medium', color: '#333' }} >
                    {emitOffer?.title}
                </Typography>
                <br />
                <Typography sx={{ fontSize: 24, fontWeight: '700', fontFamily: 'Futura-Medium', color: '#333' }} >
                    {steps[activeStep].label}
                </Typography>
            </Paper>
            <Box sx={{ height: '100%', width: '100%', p: 2 }}>
                {steps[activeStep].description}
            </Box>
            <MobileStepper
                variant="progress"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                    >
                        {isLoading ? "saving..." : "Next"}
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}