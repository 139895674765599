import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './styles.css'

import phone from '../../../assets/images/phone.svg'
import chat from '../../../assets/images/chat-dots.svg'
import mapIcon from '../../../assets/images/map.svg'
import work from '../../../assets/images/work-alt.svg'
import medal from '../../../assets/images/warmedal.svg'
import star from '../../../assets/images/star.svg'
import trace from '../../../assets/images/Tracé.svg'
import JButton from '../Button';
import { DEMANDES, SETTINGS } from '../../../router';
import { useAddRoomWithUserMutation } from '../../../app/services/chatApi';
import { useDemandServiceByUserMutation } from '../../../app/services/demandServiceApi';
import { deleteItem, getItem, setStorage, toJson } from '../../../utilities';

const JobberItem = (props) => {
    const { jobber } = props
    const demandServices = toJson(getItem("dmdServices"))
    const [demandServiceByUser, {isLoading:isLoadingAdd }] = useDemandServiceByUserMutation()
    const [addRoomWithUser, {isLoading} ] = useAddRoomWithUserMutation()
    const writeWithUser = () => {
        addRoomWithUser(jobber?.user_id).unwrap().then(()=> {
            setStorage('dsbcheck', 2)
            window.location.href = `${SETTINGS}/chat`
        })
    }
    const cofirmDemande = () =>{
        demandServiceByUser(demandServices).unwrap().then(()=> {
            setStorage('dsbcheck', 1)
            deleteItem("dmdServices")
            deleteItem("breadcrumbServices")
            deleteItem("serviceDemand")
            window.location.href = `${SETTINGS}${DEMANDES}`
        })
    }
    return (
        <Box className='wrapper-box' >
            <Stack direction={"column"} width={`100%`} spacing={1.2} >
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} >
                        <Avatar src={jobber?.user_url} sx={{ width: 56, height: 56 }} alt='user avatar' />
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} >
                            <Typography sx={{ fontSize: 14, fontWeight: '700', color: '#141313' }} >
                            {jobber?.name}
                            </Typography>
                            <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"} >
                                <img src={mapIcon} />
                                <Typography color={"primary"} sx={{ fontSize: 12, fontWeight: '400', }} >
                                {jobber?.address_work}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box sx={{ flex: 1, }} >
                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-start"} >
                        <img src={star} />
                        <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#000000' }} >
                            5.0
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: '500', color: '#B4B4B4' }} >
                            ({jobber?.price} xaf)
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-start"} >
                        <img src={trace} />
                        <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#000000' }} >
                           {jobber?.service_title}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-start"} >
                        <img src={medal} />
                        <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#000000' }} >
                            + 10 experiences
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-start"} >
                        <img src={work} />
                        <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#B4B4B4' }} >
                            Possède outils
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-start"} >
                        <div style={{ width: 18 }} ></div>
                        <Typography sx={{ fontSize: 12, fontWeight: '700', color: '#000' }} >
                            {jobber?.outils}
                        </Typography>
                    </Stack>
                    <Stack mb={1.3} direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"} >
                        {/* <div className='row-flex item-btn' >

                        </div> */}
                        <JButton 
                            style={{ width: '40%', borderRadius: 30, backgroundColor: '#0f4d61', color: 'white', height: 44, fontSize: 12 }} 
                            icon={<img src={phone} 
                            style={{ margin: `0px 7px` }} />} 
                            title={"Contacter"}
                            loading={isLoadingAdd}
                            onClick={cofirmDemande}
                        />
                        <JButton 
                            style={{ width: '40%', borderRadius: 30, backgroundColor: '#a6cc1f', color: 'white', height: 44, fontSize: 12 }} 
                            icon={<img src={chat} style={{ margin: `0px 7px` }} />} 
                            title={"Ecrire"}
                            onClick={writeWithUser}
                            loading={isLoading}
                        />
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"} >
                        <CheckCircleIcon fontSize='inherit' sx={{ color: '#29A056', fontSize: 18 }} />
                        <Typography sx={{ fontSize: 12, fontWeight: '700', color: '#29A056' }} >
                        {jobber?.disponibilities?.monday && "Lundi, "}
                        {jobber?.disponibilities?.tuesday && "Mardi, "}
                        {jobber?.disponibilities?.wednesday && "Mercredi, "}
                        {jobber?.disponibilities?.thursday && "Jeudi, "}
                        {jobber?.disponibilities?.friday && "Vendredi, "}
                        {jobber?.disponibilities?.saturday && "Samedi, "}
                        {jobber?.disponibilities?.sunday && "Dimanche"}
                        {/* ( 8h - 17H ) */}
                        </Typography>
                    </Stack>
                </Box>
            </Stack >
        </Box >
    )
}

export default JobberItem