import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import JButton from '../common/Button';

const steps = [
  {
    label: 'Invitez vos amis',
    description: `J’invite mes amis à rejoindre JeJobTout grâce à mon lien`,
  },
  {
    label: 'Votre ami s’inscrit depuis votre lien',
    description:
      'Il reçoit 3 € à utiliser sur son premier service',
  },
  {
    label: 'Recevez de l’argent à vie',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];

export default function StepperInvite() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
            //   optional={
            //     index === 2 ? (
            //       <Typography variant="caption">Last step</Typography>
            //     ) : null
            //   }
            >
                <Typography color={'#000'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '700', fontSize: 28, lineHeight: '25px'}} >
              {step.label}
                </Typography>
            </StepLabel>
            <StepContent>
              <Typography color={'#333'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '500', fontSize: 18, lineHeight: '25px', my: 2 }} >{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3, bgcolor: 'transparent' }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <JButton onClick={handleReset} title={'Je parraine mes amis'} />
        </Paper>
      )}
    </Box>
  );
}