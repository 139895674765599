import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from ".";
import { baseQueryInterpolatorPublic } from "./interceptor";

export const countryApi = createApi({
  reducerPath: 'countryApi',
  baseQuery: baseQueryInterpolatorPublic,
  tagTypes: ["countries"],
  endpoints: (builder) => ({
    findAllCountries: builder.query({
      query() {
        return {
          url: `${ENDPOINTS?.COUNTRIES}`,
        }
      },
      providesTags: ["countries"],
      keepUnusedDataFor: 2048,
    }),

  })
})

export const { useFindAllCountriesQuery } = countryApi