import { createSlice } from "@reduxjs/toolkit";
import { setAuthUser, isLogged, clearStorage, authorization, getUser, getJobberId, isRealValue, saveItem, setSetting, setStorage } from '../../utilities';

const initialState = {
    authorization: authorization(),
    isLogged: isLogged(),
    user: getUser(),
    jobberId: getJobberId(),
    weedays: [
        { title: "monday", value: false },
        { title: "tuesday", value: false },
        { title: "wednesday", value: false },
        { title: "thursday", value: false },
        { title: "friday", value: false },
        { title: "saturday", value: false },
        { title: "sunday", value: false }
    ],
    disponibility: {},
    AllDisponibilities: false,
    zoneIntervention: {},
    mainServices: [],
    allServices: [],
    filterServices: [],
    selected: null,
    indexComp: null,
    profile: {},
    serviceDemand: {},
    isMainService: true,
    breadcrumbServices: [],
    demandService: {},
    outils: "",
    myDemands: [],
    searchService: "",

    openMenu: false,
    openDialog: false,
    openModal: false,

    dsbSelected: 0,
    sousMenu: [],
    jobbers: [],
    jobbersFilter: [],

    demandToPay: {},

}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        handleDemandToPay(state, action) {
            state.demandToPay = action.payload
        },
        handleHoursBeforepay(state, action) {
            var numberHours = state.demandToPay.numberHours
            const sign = action.payload
            if (sign === "dec") { numberHours === 30 ? numberHours = numberHours : numberHours -= 30 }
            else numberHours += 30
            state.demandToPay = { ...state.demandToPay, numberHours }
        },
        setAuthorization(state, action) {
            // updateAuth(action.payload)
        },
        handleDemandService(state, action) {
            state.breadcrumbServices = [action.payload]
            state.serviceDemand = action.payload
            state.filterServices = state.allServices?.filter(service => service.parent_id === state.serviceDemand?.id)
            state.isMainService = false
        },
        handleBreadCrumbServices(state, action) {
            let breadcrumbService = [].concat(state.breadcrumbServices, action.payload)
            state.breadcrumbServices = breadcrumbService
            saveItem("serviceDemand", JSON.stringify(state.serviceDemand))
            saveItem("breadcrumbServices", JSON.stringify(state.breadcrumbServices))
        },
        handleGetServiceLocal(state, action) {
            const { serviceDemand, breadcrumbServices } = action.payload
            state.breadcrumbServices = breadcrumbServices
            state.serviceDemand = serviceDemand
        },
        handleAddressLgLt(state, action) {
            const { address, longitude, latitude } = action.payload
            state.demandService.address = address
            state.demandService.longitude = longitude
            state.demandService.latitude = latitude
        },
        handleDateAndTime(state, action) {
            const { date, time } = action.payload
            state.demandService.date = date
            state.demandService.time = time
        },
        handleDate(state, action) {
            state.demandService.date = action.payload
        },
        handleTime(state, action) {
            state.demandService.time = action.payload
        },
        handleNumberHours(state, action) {
            state.demandService.numberHours = action.payload
        },
        handleGoMainService(state) {
            state.isMainService = true
            state.breadcrumbServices = []
        },
        handlePersonalProfile(state, action) {
            state.profile = action.payload
        },
        handleOutils(state, action) {
            state.outils = action.payload
        },
        setAuth(state, action) {
            setAuthUser(action.payload)
            state.isLogged = true
        },
        loggedOut(state) {
            clearStorage()
            state.isLogged = false
            state.authorization = null
        },
        handleWeekdays(state, action) {
            const { index, checked } = action.payload
            var week = state.weedays[index]
            week.value = checked
            state.weedays[index] = week

            const isAllCheck = (week) => week.value === true;
            const check = state.weedays.every(isAllCheck)
            state.AllDisponibilities = check
        },
        handleDataWeekdays(state) {
            const transform = {
                monday: state.weedays[0].value,
                tuesday: state.weedays[1].value,
                wednesday: state.weedays[2].value,
                thursday: state.weedays[3].value,
                friday: state.weedays[4].value,
                saturday: state.weedays[5].value,
                sunday: state.weedays[6].value,
            }
            state.disponibility = transform
        },
        handleZoneIntervention(state, action) {
            const { lat, lng, address } = action.payload
            state.zoneIntervention.latitude = lat
            state.zoneIntervention.longitude = lng
            state.zoneIntervention.address = address
        },
        handleZoneInterventionRayon(state, action) {
            state.zoneIntervention.rayon = parseFloat(action.payload)
            state.zoneIntervention.jobber_id = getJobberId()
        },
        handleServices(state, action) {
            state.allServices = action.payload
            state.mainServices = state.allServices?.filter(service => service?.parent_id === null)
            state.filterServices = state.allServices
            state.sousMenu = action.payload?.filter(s => s.parent_id === state.mainServices[0].id)
        },
        handleFilterService(state, action) {
            const search = action.payload
            state.searchService = search
            state.filterServices = state.allServices?.filter(service => service?.title?.toLowerCase().includes(search.toString().toLowerCase()))
        },

        handleChangeSousMenu(state, action) {
            const index = action.payload
            const service = state.mainServices[index]
            state.sousMenu = state.allServices.filter(s => s.parent_id === service.id)
        },

        handleAllDisponibilities(state) {
            const check = !state.AllDisponibilities
            state.AllDisponibilities = check
            const weeks = state.weedays?.map((weekday) => {
                weekday.value = check
                return weekday
            })
            state.weedays = weeks
            const transform = {
                monday: state.weedays[0].value,
                tuesday: state.weedays[1].value,
                wednesday: state.weedays[2].value,
                thursday: state.weedays[3].value,
                friday: state.weedays[4].value,
                saturday: state.weedays[5].value,
                sunday: state.weedays[6].value,
            }
            state.disponibility = transform
        },
        handleSelectedServices(state, action) {
            // const id = action.payload
            // const selectedIndex = state.selected?.indexOf(id)
            // let newSelected = []
            // if (selectedIndex === -1) {
            //     newSelected = newSelected?.concat(state.selected, id)
            // } else if (selectedIndex === 0) {
            //     newSelected = newSelected?.concat(state.selected.slice(1))
            // } else if (selectedIndex === state.selected.length - 1) {
            //     newSelected = newSelected.concat(state.selected.slice(0, -1))
            // } else if (selectedIndex > 0) {
            //     newSelected = newSelected.concat(
            //         state.selected.slice(0, selectedIndex),
            //         state.selected.slice(selectedIndex + 1),
            //     )
            // }
            // state.selected = newSelected

            const { service, index } = action.payload
            state.indexComp = parseInt(index)
            state.selected = service?.id
        },
        handleMyDemands(state, action) {
            state.myDemands = action.payload
        },
        handleSettingsJobber(state, action) {
            setSetting(action.payload)
            state.user = getUser()
        },
        handleOpenMenuDialogLayout(state, action) {
            state.openMenu = action.payload
        },
        handleOpenDialogLayout(state, action) {
            state.openMenu = action.payload
        },
        handleOpenMoadalLayout(state, action) {
            state.openModal = action.payload
        },
        handleSelected(state, action) {
            state.dsbSelected = parseInt(action.payload)
            setStorage('dsbcheck', action.payload)
        },
        handleJobbers(state, action) {
            state.jobbers = action.payload
            state.jobbersFilter = state.jobbers
        },
        handleServiceDemand(state, action) {
            state.demandService = action.payload
        },
        hanleLatLonDemand(state, action) {
            const { lat, lng } = action.payload
            let demand = state.demandService
            demand = { ...demand, ...{ latitude: lat, longitude: lng } }
            state.demandService = demand
        },
        handleJobberbyPriceInterval(state, action) {
            const { rPrice, lPrice } = action.payload
            let jobbers = state.jobbers?.filter(jobber => jobber.price >= lPrice && jobber.price <= rPrice)
            state.jobbersFilter = jobbers
        },
    },
})

export const {
    setAuth,
    loggedOut,
    setAuthorization,
    handleWeekdays,
    handleDataWeekdays,
    handleZoneIntervention,
    handleZoneInterventionRayon,
    handleServices,
    handleFilterService,
    handleSelectedServices,
    handlePersonalProfile,
    handleDemandService,
    handleGoMainService,
    handleAddressLgLt,
    handleDateAndTime,
    handleDate,
    handleTime,
    handleNumberHours,
    handleBreadCrumbServices,
    handleGetServiceLocal,
    handleOutils,
    handleMyDemands,
    handleSettingsJobber,
    handleOpenMenuDialogLayout,
    handleOpenDialogLayout,
    handleAllDisponibilities,
    handleSelected,
    handleChangeSousMenu,
    handleJobbers,
    handleServiceDemand,
    handleJobberbyPriceInterval,
    hanleLatLonDemand,
    handleOpenMoadalLayout,
    handleDemandToPay,
    handleHoursBeforepay,


} = authSlice.actions

export default authSlice