import { Box, CircularProgress, Container, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import OffreItem from '../../components/common/OffreItem';
import './styles.css'
import SearchBarAdvanced from '../../components/common/SearchBarAdvanced';
import SideFilter from '../../components/common/SideFilter';

import { useOffersMatchForJobberServicesQuery, useOffersPlatformQuery } from '../../app/services/offreApi';
import WidgetsIcon from '@mui/icons-material/Widgets';
import TransitionsModal from '../../components/Modal';
import SideFilterOffer from './SideFilterOffer';
import { useSelector } from 'react-redux'

const ParcourirOffres = () => {
    const { serviceId, offersFiltered } = useSelector(state => state.offers)
    const { jobberId } = useSelector(state => state?.auth)

    const { isLoading } = useOffersMatchForJobberServicesQuery(undefined, { skip: !(serviceId === null && jobberId !== null), refetchOnMountOrArgChange: true, refetchOnReconnect: true })
    useOffersPlatformQuery(serviceId, { skip: !(serviceId !== null), refetchOnMountOrArgChange: true , refetchOnReconnect: true })

    const [openDialog, setOpenDialog] = React.useState(false);
    const matches = useMediaQuery('(max-width:900px)');


    return (
        <Box className='container' >
            <Container maxWidth='xl' >
                {jobberId === null ?
                    <Grid container mt={2} spacing={2} >
                        <Typography>Acces refuse: Vous n'avez pas l'autorisation requise </Typography>
                    </Grid> :
                    <>
                        {matches ? <Stack my={2} direction={'row'} justifyContent={'flex-end'} >
                            <IconButton onClick={() => setOpenDialog(true)} >
                                <WidgetsIcon direction={'row'} justifyContent={'flex-end'} sIcon color='inherit' fontSize='inherit' sx={{ fontSize: 45, color: '#00000020' }} />
                            </IconButton>
                        </Stack> : null}

                        <SearchBarAdvanced />
                        <Grid container mt={2} spacing={2} >
                            <Grid item container xs={3} >
                                <SideFilterOffer />
                            </Grid>
                            <Grid item xs={9} p={2} className='border-grid' >
                                {isLoading && <CircularProgress color='primary' />}
                                {
                                    offersFiltered?.lenght <= 0 ? <Box my={1.2} style={{ backgroundColor: "red" }}>No Found</Box> :
                                        offersFiltered?.map((offer, i) => (
                                            <OffreItem offer={offer} key={i + "offer"} />
                                        ))
                                }
                            </Grid>
                        </Grid>
                    </>
                }

            </Container>
            <TransitionsModal open={openDialog} setOpen={setOpenDialog} >
                <SideFilter />
            </TransitionsModal>
        </Box>
    )
}

export default ParcourirOffres;