import { Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import './styles.css'

const ServiceMainItem = ({ title, icon, onClick }) => {
    return (
        <motion.div onClick={onClick} whileTap={{ scale: 0.95 }} className='wrapper-box-2' style={{ backgroundImage: `url(${icon})` }}>
            <Stack my={1} direction={"column"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} width='100%' height={"100%"} >
                <Typography sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', color: '#080808', my: 2 }} >
                    {title}
                </Typography>
            </Stack>
        </motion.div>
    )
}

export default ServiceMainItem