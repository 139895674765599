import { Autocomplete, Box } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handlePriceOffer } from '../../app/features/offerSlice'
import TipsInfo from '../../components/common/TipsInfo'
import Input from '../../components/Input'

const OffreAmount = () => {
    const dispatch = useDispatch()
    const { emitOffer } = useSelector(state => state.offers)
    const handleAmount = (e) => dispatch(handlePriceOffer(e.target.value))

    return (
        <Box>
            <Input style={{ width: '100%' }} defaultValue={emitOffer?.amount} onChange={handleAmount} value={emitOffer?.amount} placeholder={"Entrer votre budget"} type={'number'} />
            <TipsInfo text={"Ces information seront transmises uniquement aux prestataires que vous réservez"} />
        </Box>
    )
}

export default OffreAmount