import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import AddressPrestation from '../pages/AddressPrestation';
import JourPrestations from '../pages/JourPrestations';
import HeurePrestations from '../pages/HeurePrestations';
import { useSelector } from 'react-redux';
import { getId, getItem, toJson, saveItem, toString } from '../utilities';
import { useDemandServiceByUserMutation } from '../app/services/demandServiceApi';
import { COMPLETE_BOOKING, LOGIN_PAGE } from '../router';
import { useState } from 'react';
import { handleGetServiceLocal } from '../app/features/authSlice';
import { dispatch } from '../app';


export default function StepperPages() {
    const steps = [
        {
            label: "Quelle est l'addresse de la prestation ?",
            description: <AddressPrestation />,
        },
        {
            label: " Quelle heure vous convient le mieux ? ",
            description: <JourPrestations />
        },
        {
            label: "Entrer le nombres d'heure de prestation ?",
            description: <HeurePrestations />
        },
    ];
    const { serviceDemand, demandService, breadcrumbServices, isLogged } = useSelector(state => state.auth)
    

    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = steps?.length;
    const [demandServiceByUser, { isLoading }] = useDemandServiceByUserMutation()

    const handleNext = () => {
        const serviceDemands = toJson(getItem("serviceDemand"))
        const breadcrumbServicess = toJson(getItem("breadcrumbServices"))
    
        if(serviceDemands !==null && breadcrumbServices !==null) {
            dispatch(handleGetServiceLocal({ serviceDemand:serviceDemands, breadcrumbServices:breadcrumbServicess }))
        }
        
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === maxSteps - 1) {
            const data = {
                ...demandService, user_id: getId(), service_id: breadcrumbServices?.at(-1)?.id,
                date: new Date(demandService?.date).toJSON(),
            }
            saveItem("dmdServices", toString(data))

            if(!isLogged) window.location.href = `${LOGIN_PAGE}`
            else window.location.href = `${COMPLETE_BOOKING}`
            // demandServiceByUser(data).unwrap().then(() => {
            //     window.location.href = `${COMPLETE_BOOKING}`
            // })
        }
    };

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

    return (
        <Box sx={{ width: '100%', flexGrow: 1 }}>
            <Paper
                elevation={0}
                sx={{
                    pl: 2,
                    bgcolor: 'background.default',
                }}
            >
                <Typography sx={{ fontSize: 18, fontWeight: '700', fontFamily: 'Futura-Medium', color: '#333' }} >
                    {serviceDemand?.title}
                </Typography>
                <br />
                <Typography sx={{ fontSize: 24, fontWeight: '700', fontFamily: 'Futura-Medium', color: '#333' }} >
                    {steps[activeStep]?.label}
                </Typography>
            </Paper>
            <Box sx={{ height: '100%', width: '100%', p: 2 }}>
                {steps[activeStep]?.description}
            </Box>
            <MobileStepper
                variant="progress"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={()=>handleNext()}
                    >
                        {isLoading ? "saving..." : "Next"}
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={()=> handleBack()} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}