import { CircularProgress, colors, Container, Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import JButton from '../../components/common/Button'
import SearchButton from '../../components/common/SearchButton'
import InfoBar from '../../components/IntoBar'
import TransitionsModal from '../../components/Modal'
import ServiceMainList from '../../components/ServiceMainList'
import SignUpForm from '../../components/SignUpForm'
import TipsList from '../../components/TipsList'
import './styles.css'

import 'swiper/css';

import HomeServiceList from '../../components/HomeServiceList'
import DialogLayout from '../../components/layout/DialogLayout'
import { useNavigate } from 'react-router'
import DemandesItem from '../../components/common/DemandesItem'
import { useDemandServicesQuery } from '../../app/services/demandServiceApi'
import { useSelector } from 'react-redux'
import { DEMANDES } from '../../router'

const Home = () => {
    const { myDemands, isLogged } = useSelector(state => state.auth)
    const { isLoading } = useDemandServicesQuery(undefined, {skip: !isLogged, refetchOnMountOrArgChange: true, refetchOnFocus: true, refetchOnReconnect: true});
    const [openDialog, setOpenDialog] = useState(false)
    const navigate = useNavigate()
    const openService = () => setOpenDialog(true)

    return (
        <Box style={{ backgroundColor: '#fff' }} >
            <Box className='home-hero'>
                <Container maxWidth='xl' >
                    <Grid container display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} spacing={1.2} >
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <h3 className='bold-header' >
                                Trouver le prestataire idéal pour tous <br /> les services au quotidien
                            </h3>
                            <SearchButton openService={openService} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                            <div className='side-hero' />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box>
                <InfoBar />
            </Box>
            <Box bgcolor={'#F0F1F596'} my={2} >
                <Container maxWidth={'lg'} sx={{ paddingTop: 3 }} >
                    <Typography variant='h1' className='partial-line' color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 28, mt: 2, }} >
                        De quel service avez-vous besoin ?
                    </Typography>
                    <ServiceMainList openService={openService} />
                </Container>
            </Box>
            {isLogged && <Box bgcolor={'#F0F1F596'} my={2} >
                <Container maxWidth={'lg'} sx={{ paddingTop: 3 }} >
                    <Typography variant='h1' className='partial-line' color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 28, mt: 2, }} >
                        Consulter vos demandes ?
                    </Typography>
                    <Typography onClick={() => window.location.href = `${DEMANDES}`} className='link' color={'primary'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 16, my: 2, width: '100%', textAlign: 'right' }}  >
                        Voir tout
                    </Typography>
                </Container>
                <Grid px={2} container spacing={2} justifyContent={"center"} flexDirection="row" alignItems={"center"} my={3} flexWrap="wrap" >
                    {isLoading ?
                        <Grid item xs={12} >
                            <CircularProgress color="primary" /> 
                        </Grid>
                        :
                        <>
                            {myDemands.length <= 0 ? <Grid item xs={12} >
                                <Typography sx={{ color: 'grey', fontSize: 18, fontWeight: 'bold', padding: 0 }}>
                                    Vous n'avez aucune demande enregistree.
                                </Typography>
                            </Grid> :
                                myDemands?.map((demand, index) => (
                                    <Grid item xs={12} sm={12} md={6} lg={4} key={index} >
                                        <DemandesItem demand={demand} />
                                    </Grid>
                                ))
                            }
                        </>
                    }
                </Grid>
            </Box>}
            <Box bgcolor={'#0f4d61'} >
                <Container maxWidth={'lg'} sx={{ paddingTop: 3 }} >
                    <Typography className='text-marche' textAlign={"center"} color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 28, mt: 2, color: 'white' }} >
                        Comment ça marche
                    </Typography>
                    <TipsList />
                </Container>
            </Box>
            <Box bgcolor={colors.common.white} >
                <Container maxWidth={'lg'} sx={{ paddingTop: 3, paddingBottom: 30, borderBottomWidth: 1, borderBottomColor: colors.grey[300] }} >
                    <Grid container display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} spacing={1.2} >
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} justifyContent={"center"} sx={{ flex: 1 }} >
                                <Typography className='partial-line' color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 28, my: 2, }} >
                                    Vous avez le sens du service ?
                                </Typography>
                                <Typography color={'#5D636A'} sx={{ fontFamily: 'Futura-Medium', fontWeight: '600', fontSize: 18, lineHeight: '25px', my: 2 }} >
                                    Rejoignez la communauté de jobbers, développez votre activité et augmenter vos revenus en rendant service pres de chez vous:
                                </Typography>
                                <JButton title={"Devenir prestataires"} onClick={() => navigate('/jobber')} style={{ backgroundColor: '#a6cc1f' }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} height={"100%"} >
                            <div className='side-img1' />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Divider color='#7070703E' />
            <Box bgcolor={"#FFFFFF"} sx={{ padding: `20px 0px`, my: 2 }} >
                <Container maxWidth={"lg"} >
                    <Typography color={"primary"} sx={{ fontFamily: 'Fredoka One', fontSize: 28, my: 2, }} >
                        Le service à domicile en toute sécurité
                    </Typography>
                    <HomeServiceList />
                </Container>
            </Box>
            <Divider color='#7070703E' />
            <TransitionsModal>
                <SignUpForm />
            </TransitionsModal>
            <DialogLayout open={openDialog} setOpen={setOpenDialog} />
        </Box>
    )
}

export default Home