import { Typography } from '@mui/material'
import React from 'react'
import './styles.css'

const TimeItem = ({ time, index, activeIndex, onClick }) => {
    return (
        <div onClick={onClick} className={"row-flex time-item " + `${index === activeIndex ? "active" : ""}`} >
            <Typography sx={{ fontSize: 16, fontWeight: '600', color: index === activeIndex ? "#0f4d61" : '#333', fontFamily: 'Futura-Medium' }} >
                {time}
            </Typography>
        </div >
    )
}

export default TimeItem