import { Box, Typography } from '@mui/material'
import React from 'react'
import './styles.css'
import Checkbox from '@mui/material/Checkbox';
import { dispatch } from '../../../app';
import { handleDataWeekdays, handleWeekdays } from '../../../app/features/authSlice';


const CheckListItem = ({ checked, title, index }) => {
    const handleSelected = (e, index) => {
        const checked = e.target.checked
        dispatch(handleWeekdays({ index, checked }))
        dispatch(handleDataWeekdays())
      } 
    return (
        <div >
            <Box className='row-flex wrapperL-box' >
                <Checkbox onChange={(e) => handleSelected(e, index)} checked={checked} />
                <Box className='row-flex-space flexL-box'>
                    <Typography sx={{ fontFamily: 'catamaran', fontWeight: '500', color: '#25282c', }} >
                        {title}
                    </Typography>
                    {/* <ArrowForwardIosIcon fontSize='small' color={colors.grey[500]} /> */}
                </Box>
            </Box>
        </div>
    )
}

export default CheckListItem