import { Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import './styles.css'
import { motion } from 'framer-motion';

const SwitchItem = ({ title }) => {
    const [Checked, setChecked] = useState(false)
    return (
        <motion.div whileTap={{ scale: 0.975 }} onClick={() => setChecked(!Checked)} className='switch-wrapper' style={{ backgroundColor: Checked ? '#225EA8' : 'transparent' }} >
            <div>
                {Checked ? <CheckCircleOutlinedIcon fontSize='small' color='inherit' sx={{ color: '#FFFFFF', margin: '0px' }} /> : <CircleOutlinedIcon fontSize='small' color='inherit' sx={{ color: '#70707036' }} />}
            </div>
            <Typography sx={{ fontSize: 15, fontFamily: 'Futura-Medium', color: Checked ? "white" : "#000", mx: 1.3, fontWeight: Checked ? "600" : "500" }} >
                {title}
            </Typography>
        </motion.div>
    )
}

export default SwitchItem