import React from 'react';
import { usePagination } from './usePagination';
import { Box, Button } from '@mui/material';

const Pagination = (props) => {
    const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props
    const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize })
    const onNext = () => onPageChange(currentPage + 1)
    const onPrevious = () => onPageChange(currentPage - 1)
    let lastPage = paginationRange[paginationRange.length - 1]

    if (currentPage === 0 || paginationRange.length < 2) return null
    return (
        <Box sx={ {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: "auto",
            marginBottom: 20,
            marginTop: 10,
            height: '20%',
        }}>
            <Button sx={{
                borderRadius: 2,
                padding: 1,
                margin: 1,
                borderColor: `gray`, textAlign: 'center',
                color: "black",
            }}
                key={"left"}
                disabled={currentPage === 1} onClick={onPrevious}
                variant="outlined"
                size='small'
            > {`<`} </Button>
            {
                paginationRange?.map(pageNumber => {
                    return (
                        <Button sx={{
                            borderRadius: 2,
                            padding: 1,
                            margin: 1,
                            borderColor: `gray`, textAlign: 'center',
                            color: "black",
                            backgroundColor: pageNumber === currentPage ? "blue" : 'white'
                        }}
                            key={pageNumber + "page"}
                            onClick={() => onPageChange(pageNumber)}

                            variant="outlined"
                            size='small'
                        > {`${pageNumber}`} </Button>
                    )
                })
            }
           
             <Button sx={{
                borderRadius: 2,
                padding: 1,
                margin: 1,
                borderColor: `gray`, textAlign: 'center',
                color: "black",
            }}
                key={"right"}
                disabled={currentPage === lastPage} onClick={onNext}
                variant="outlined"
                size='small'
            > {`>`} </Button>
        </Box>
    )
}

export default Pagination